import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const HowToUseFreeWeightsSafely = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Free Weights Safely</h1>
                    <h2>Unlock the benefits of free weights and enhance your fitness journey with our expert guidance on safe and effective workouts at Ramp Physio and Fitness.</h2>
                    <p>Free weights are a staple in strength training, offering versatility and a range of benefits for individuals looking to build muscle, increase strength, and improve overall fitness. However, the key to reaping these benefits lies in using free weights safely. At Ramp Physio and Fitness, we are dedicated to providing you with the knowledge and tips you need to use free weights effectively while minimizing the risk of injury. In this comprehensive guide, we'll explore the fundamentals of free weight training and share strategies to ensure your workouts are both safe and successful.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Free Weights" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Starting with Free Weights</h1>
                        <p>Beginning your journey with free weights can be both exciting and intimidating. To set you on the right path, we'll cover the basics of choosing the appropriate weights, understanding different types of exercises, and establishing a solid foundation for your training routine. We'll also discuss the importance of form over weight, ensuring that you start with manageable loads and focus on executing movements with precision.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Focusing on Form</h1>
                    <p>Maintaining proper form is crucial when working with free weights. We'll delve into the techniques that ensure your body is aligned correctly, reducing the likelihood of injury and maximizing the effectiveness of each exercise. From the alignment of your spine to the engagement of your core, we'll provide you with the guidance needed to perform each movement with confidence and control.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Progressing Safely</h1>
                    <p>Advancing your free weight training should be done with careful consideration. We'll discuss how to recognize when you're ready to increase the weight and the importance of incremental progression. You'll learn the signs your body gives when it's time to challenge yourself further, and the risks associated with advancing too quickly. We'll also touch on the role of rest and recovery in your progression plan.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Spotting and Safety</h1>
                    <p>Spotting is an essential aspect of free weight training, particularly when lifting heavier weights. We'll explain the importance of having a spotter, how to spot someone correctly, and the techniques that ensure both the lifter and spotter remain safe. Whether you're training alone or with a partner, understanding spotting techniques is a valuable skill that can prevent accidents and injuries.</p>
                    <br></br>
                    <p>
                        By the end of this guide, you'll be equipped with the knowledge to use free weights safely and effectively. At Ramp Physio and Fitness, we are committed to supporting your fitness journey every step of the way. From personalized training sessions to expert advice, we are here to help you achieve your health and fitness goals with confidence. Join us today and take the first step towards mastering the art of free weight training.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default HowToUseFreeWeightsSafely;