import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForWristPain = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Effective Physiotherapy for Wrist Pain</h1>
                    <h2>Discover tailored physiotherapy treatment and exercises to alleviate wrist pain and enhance your mobility at Ramp Physio and Fitness.</h2>
                    <p>Wrist pain can significantly impact your daily activities and overall quality of life. At Ramp Physio and Fitness, we provide customized physiotherapy treatments and exercises designed to target wrist pain, promote healing, and prevent future injuries. Our team of experienced physiotherapists is dedicated to helping you achieve freedom from discomfort and regain optimal wrist function. Here, we explore the benefits of physiotherapy for wrist pain and the specific strategies we employ to support your recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Wrist Pain Causes</h1>
                        <p>The wrist is a complex joint with numerous bones, ligaments, tendons, and nerves. Pain can arise from various conditions such as strains, sprains, tendonitis, arthritis, and more. Identifying the root cause of wrist pain is crucial for an effective treatment plan.</p>
                        <p>Our physiotherapists conduct a thorough assessment to determine the source of your pain, taking into account factors such as your activity level, occupational demands, and medical history. This holistic approach ensures that our treatment addresses not only the symptoms but also the underlying issues contributing to your discomfort.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Treatment Techniques</h1>
                        <p>Our physiotherapy treatments for wrist pain are tailored to your individual needs. We employ a range of techniques including manual therapy, mobilizations, and ultrasound to reduce pain and inflammation.</p>
                        <p>Specific exercises are also prescribed to strengthen the wrist and improve flexibility. These exercises are carefully selected based on your condition and are designed to be performed safely, either at our clinic or at home as part of your recovery routine.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Physio Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Protocols for Recovery</h1>
                    <p>Exercise is a cornerstone of wrist pain rehabilitation. We guide you through exercises that focus on range of motion, strengthening, and proprioceptive training to ensure a comprehensive recovery.</p>
                    <p>Examples of exercises include wrist flexion and extension stretches, grip strengthening exercises, and stability exercises using equipment like therapy balls. These exercises are designed to restore function and prevent future injuries.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Preventive Strategies and Education</h1>
                        <p>Preventing wrist pain is just as important as treating it. We provide education on ergonomics, posture, and movement strategies to help you avoid the recurrence of wrist pain.</p>
                        <p>Our team also advises on wrist supports and braces if necessary, and we offer guidance on modifications to your daily activities to protect your wrists during the healing process and beyond.</p>
                        <br></br>
                        <p>In conclusion, Ramp Physio and Fitness offers a comprehensive approach to managing wrist pain through expert physiotherapy treatment and personalized exercises. Our goal is to not only alleviate your pain but also to empower you with the knowledge and tools to maintain a healthy and active lifestyle. Book an appointment with us today and take the first step towards wrist pain relief.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForWristPain;