import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const CoreStrengtheningExercisesForLowerBackSupport = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Core Strength for Back Support</h1>
                    <h2>Unlock the Power of Core Strengthening Exercises for Lower Back Support</h2>
                    <p>At Ramp Physio and Fitness, we understand the critical role that core strength plays in supporting your lower back and overall well-being. A strong core can prevent lower back pain, improve your posture, and enhance your performance in daily activities and sports. In this comprehensive guide, we'll explore the best core strengthening exercises specifically designed to bolster lower back support.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Core Strengthening" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Core Foundations</h1>
                        <p>Core strengthening exercises are the foundation of a healthy and functional body. The core is more than just your abs; it includes the muscles in your pelvis, lower back, hips, and abdomen. These muscles work in harmony to support your spine and body movements. Strengthening these muscles is essential for stability, balance, and power transfer between the upper and lower body.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Core and Lower Back</h1>
                    <p>Your lower back health is directly influenced by the strength and stability of your core muscles. Weak core muscles can lead to poor posture, strain on the lower back, and an increased risk of injuries. By engaging in core strengthening exercises, you can build a robust support system for your lower back, reducing discomfort and improving your functional capabilities.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Expert-Recommended Exercises</h1>
                    <p>Our team of physiotherapists at Ramp Physio and Fitness has curated a list of core strengthening exercises that are both effective and safe for individuals of all fitness levels. These exercises are designed to target the muscles that support your lower back, enhancing your core stability and strength.</p>
                    <ul>
                        <li>Plank Variations</li>
                        <li>Bridge Exercises</li>
                        <li>Bird-Dog</li>
                        <li>Dead Bug Exercise</li>
                        <li>Supine Pelvic Tilts</li>
                        <li>Seated Leg Lifts</li>
                        <li>Stability Ball Workouts</li>
                        <li>Rotational Movements</li>
                        <li>Abdominal Crunches</li>
                        <li>Superman Back Extensions</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Core Training</h1>
                    <p>Every individual's body is unique, and so are their fitness needs. At Ramp Physio and Fitness, we offer personalized core training programs tailored to meet your specific goals. Whether you are recovering from an injury, seeking to prevent future pain, or looking to enhance your athletic performance, our experts will work with you to create a customized core strengthening plan.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default CoreStrengtheningExercisesForLowerBackSupport;