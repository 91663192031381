import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyAssistedStretchingToRelieveStiffnessInJoints = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy and Assisted Stretching | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert NDIS physiotherapy and assisted stretching services at Ramp Physio and Fitness to alleviate joint stiffness and improve mobility. Book your session now." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Enhancing Mobility with NDIS Physiotherapy and Assisted Stretching</h1>
                    <h2>Discover how Ramp Physio and Fitness provides specialized NDIS physiotherapy and assisted stretching to help relieve stiffness in joints and enhance overall mobility.</h2>
                    <p>Ramp Physio and Fitness is dedicated to delivering personalized NDIS physiotherapy services and assisted stretching programs. Our goal is to help individuals experiencing joint stiffness and mobility limitations to achieve greater freedom of movement and improve their quality of life. This article delves into the benefits of our targeted physiotherapy approaches and how they can assist in managing and alleviating joint discomfort.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding NDIS Physiotherapy</h1>
                        <p>NDIS physiotherapy is a specialized service designed to support individuals with disabilities in achieving their physical health goals. Our physiotherapists work closely with NDIS participants to create customized treatment plans that cater to their specific needs, focusing on enhancing function, increasing independence, and improving overall well-being.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Assisted Stretching for Joint Stiffness</h1>
                    <p>Assisted stretching is a hands-on technique where a physiotherapist helps to gently stretch and mobilize the joints, which can provide relief from stiffness and enhance mobility. This approach can be particularly beneficial for those with limited movement due to disability, injury, or chronic conditions.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Joint Mobilization Techniques</h1>
                    <p>Our physiotherapists are trained in joint mobilization and manipulation techniques to help restore normal movement to stiff joints. These techniques can range from gentle mobilizations to more forceful manipulations, depending on the individual's condition and tolerance.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Treatment Plans</h1>
                        <p>Every NDIS participant receives a tailored treatment plan that addresses their unique needs. Our plans are based on thorough assessments and include a combination of manual therapy, exercise prescription, and education to promote long-term health benefits.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyAssistedStretchingToRelieveStiffnessInJoints;