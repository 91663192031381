import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforguillainbarrsyndromerecovery = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Guiding Recovery for Guillain-Barré Syndrome</h1>
                    <h2>Embark on a personalized physiotherapy journey at Ramp Physio and Fitness, tailored to support Guillain-Barré Syndrome recovery and regain strength and mobility.</h2>
                    <p>Guillain-Barré Syndrome (GBS) can be a challenging condition, but with the right physiotherapy approach, recovery is within reach. At Ramp Physio and Fitness, we specialize in providing comprehensive care to help individuals affected by GBS regain their independence and enhance their quality of life. In this detailed guide, we'll explore the role of physiotherapy in GBS recovery and how our expert team can support you every step of the way.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Guillain-Barré Syndrome</h1>
                        <p>Guillain-Barré Syndrome is a rare neurological disorder where the body's immune system mistakenly attacks part of its peripheral nervous system. This can lead to muscle weakness, paralysis, and a host of other challenges. Recovery from GBS is a gradual process and physiotherapy plays a pivotal role in helping patients restore function and mobility.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Programs</h1>
                    <p>Every individual's experience with GBS is unique, requiring a personalized approach to physiotherapy. Our programs are designed to meet each patient's specific needs, focusing on exercises that improve muscle strength, coordination, and balance. We monitor progress closely and adjust treatments to ensure the best possible outcomes.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Stages of GBS Recovery</h1>
                    <p>The recovery from Guillain-Barré Syndrome typically occurs in stages, from the initial onset of symptoms to the plateau phase and, finally, the recovery phase. Physiotherapy is essential throughout these stages, providing support and rehabilitation that evolves with the patient's condition.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercise and Mobility Training</h1>
                    <p>Key to GBS recovery is the restoration of mobility and strength through targeted exercises. Our physiotherapists guide patients through a series of movements and activities designed to rebuild muscle function and enhance mobility, taking care to prevent overexertion and fatigue.</p>
                    <br></br>
                    <p>Examples of exercises and activities include:</p>
                    <ul>
                        <li>Passive range-of-motion exercises</li>
                        <li>Gentle strengthening exercises</li>
                        <li>Balancing and coordination training</li>
                        <li>Functional mobility drills</li>
                        <li>Walking and gait reeducation</li>
                        <li>Hydrotherapy for low-impact exercise</li>
                        <li>Stretching to maintain flexibility</li>
                        <li>Neuromuscular electrical stimulation</li>
                        <li>Breathing and endurance exercises</li>
                        <li>Pain management techniques</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforguillainbarrsyndromerecovery;