import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForShoulderImpingementSyndrome = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Shoulder Impingement Syndrome</h1>
                    <h2>Find relief and restore mobility with tailored physiotherapy for Shoulder Impingement Syndrome at Ramp Physio and Fitness.</h2>
                    <p>Shoulder impingement syndrome can be a major obstacle in your daily activities and fitness routines. At Ramp Physio and Fitness, our expert physiotherapists specialize in diagnosing and treating shoulder impingement, employing a comprehensive approach to alleviate pain and enhance shoulder function. In this detailed guide, we will explore the causes of shoulder impingement, the benefits of physiotherapy, and how our personalized treatment plans can help you regain your active lifestyle.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Shoulder Impingement</h1>
                        <p>Shoulder impingement syndrome occurs when the rotator cuff tendons are compressed during shoulder movements, causing pain and restricting mobility. This condition can result from repetitive overhead activity, poor posture, or injury. Recognizing the symptoms early is key to preventing further damage and starting effective treatment. Our physiotherapy sessions are designed to address the root cause of impingement and promote a healthy range of motion.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Each case of shoulder impingement is unique, and so is our approach to treatment. We begin with a thorough assessment to understand your specific condition and tailor a treatment plan that may include manual therapy, exercise prescription, and education on activity modification. Our goal is to not only relieve your symptoms but also to prevent recurrence by strengthening the shoulder and improving posture.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Therapy and Rehabilitation</h1>
                    <p>Exercise therapy plays a crucial role in recovering from shoulder impingement. Our physiotherapists guide you through targeted exercises to strengthen the muscles around the shoulder, enhance flexibility, and improve range of motion. Rehabilitation is a journey, and we are committed to providing support and expert care every step of the way.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Educational Resources and Support</h1>
                    <p>Education is a powerful tool in managing shoulder impingement. We provide resources and support to help you understand your condition, make informed decisions about your treatment, and implement strategies to prevent future issues. Our team empowers you with the knowledge to maintain shoulder health long after your sessions have ended.</p>
                    <br></br>
                    <p>
                        Concluding, physiotherapy for shoulder impingement syndrome is a proven pathway to pain relief and functional improvement. At Ramp Physio and Fitness, we're dedicated to delivering the highest standard of care to help you overcome shoulder challenges and enjoy an active, fulfilling life. Embrace the journey to recovery with our expert team by your side.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForShoulderImpingementSyndrome;