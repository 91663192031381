import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const AnkleSprainTreatmentAndPrevention = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Effective Ankle Sprain Care</h1>
                    <h2>Unlock the secrets to effective ankle sprain treatment and prevention and get back on your feet with the knowledgeable team at Ramp Physio and Fitness.</h2>
                    <p>Ankle sprains are a common but debilitating injury that can sideline athletes and active individuals alike. At Ramp Physio and Fitness, we are committed to not only treating your ankle sprains but also educating you on how to prevent them. This comprehensive guide will cover everything from the anatomy of an ankle sprain to the latest rehabilitation techniques and preventative strategies.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Ankle Sprain" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Ankle Sprains</h1>
                        <p>Ankle sprains occur when the ligaments that support the ankle stretch beyond their limits and tear. These injuries are graded based on severity, from mild stretching (Grade 1) to complete tears (Grade 3). Symptoms typically include pain, swelling, and restricted range of motion. Understanding the mechanics behind ankle sprains is the first step to effective treatment and prevention.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Immediate Care Strategies</h1>
                    <p>Immediate response to an ankle sprain is crucial for a speedy recovery. The R.I.C.E. method (Rest, Ice, Compression, Elevation) should be initiated as soon as possible. This approach helps to manage swelling and pain in the acute phase of the injury. Early intervention is key to preventing further damage and starting the healing process on the right foot.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Advanced Rehabilitation Techniques</h1>
                    <p>After the initial phase of treatment, rehabilitation exercises become the cornerstone of recovery. Our physiotherapists at Ramp Physio and Fitness employ a range of techniques, including proprioceptive training, strength exercises, and mobility work to restore function and prevent chronic instability. A personalized rehab program is essential for a full and efficient recovery.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Preventing Future Sprains</h1>
                    <p>Prevention is better than cure, and at Ramp Physio and Fitness, we firmly believe in equipping our clients with the knowledge and skills to prevent future ankle sprains. Balance exercises, proper footwear, and sports-specific drills are just a few examples of how we can help you strengthen your ankles and reduce the risk of re-injury.</p>
                    <br></br>
                    <ul>
                        <li>Wear supportive footwear</li>
                        <li>Engage in balance training</li>
                        <li>Strengthen lower extremity muscles</li>
                        <li>Improve proprioception with targeted exercises</li>
                        <li>Warm-up thoroughly before physical activity</li>
                        <li>Gradually increase the intensity of workouts</li>
                        <li>Use ankle braces or tape as recommended</li>
                        <li>Practice agility drills</li>
                        <li>Avoid uneven surfaces when training</li>
                        <li>Listen to your body and rest when needed</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default AnkleSprainTreatmentAndPrevention;