import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingTensTherapyInPhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Understanding TENS Therapy in Physiotherapy</h1>
                    <h2>Uncover the power of TENS therapy at Ramp Physio and Fitness and take control of your pain management and rehabilitation journey.</h2>
                    <p>Transcutaneous Electrical Nerve Stimulation (TENS) therapy is a non-invasive, drug-free method for controlling pain. At Ramp Physio and Fitness, we use TENS therapy as part of our comprehensive approach to physiotherapy and rehabilitation. This article will guide you through the benefits, uses, and scientific understanding of TENS therapy, ensuring that you are well-informed about this effective treatment option.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science Behind TENS Therapy</h1>
                        <p>TENS therapy works by sending low-voltage electrical currents through the skin to stimulate the nerves for therapeutic purposes. By adjusting the frequency and intensity of these currents, TENS can help relieve pain, improve circulation, and promote muscle relaxation. The therapy is commonly used for conditions such as osteoarthritis, fibromyalgia, tendinitis, bursitis, and chronic back pain, among others.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Benefits of TENS Therapy</h1>
                    <ul>
                        <li>Reduction of acute and chronic pain</li>
                        <li>Enhancement of tissue healing</li>
                        <li>Decreased use of pain medications</li>
                        <li>Improved sleep due to pain relief</li>
                        <li>Increased motion and function</li>
                        <li>Convenient and portable pain management</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>How TENS Therapy Works</h1>
                    <p>TENS therapy can be understood through the gate control theory of pain. It proposes that the electrical stimulation can "close the gate" to pain signals in the spinal cord, preventing them from reaching the brain. Additionally, TENS may trigger the release of endorphins, which are natural painkillers produced by the body. This dual mechanism makes TENS an effective tool for pain relief.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customizing Your TENS Treatment</h1>
                    <p>At Ramp Physio and Fitness, we understand that every individual is unique, and so are their pain management needs. Our skilled physiotherapists will work with you to customize your TENS treatment, adjusting the settings to provide optimal relief for your specific condition. Here are ten examples of how TENS therapy can be tailored to different situations:</p>
                    <ul>
                        <li>Acute sports injury pain relief</li>
                        <li>Chronic back pain management</li>
                        <li>Post-operative pain reduction</li>
                        <li>Arthritis joint pain alleviation</li>
                        <li>Menstrual cramp relief</li>
                        <li>Neuropathic pain management</li>
                        <li>Muscular spasm reduction</li>
                        <li>Rehabilitation after stroke</li>
                        <li>Enhancement of blood circulation</li>
                        <li>Supplement to manual therapy techniques</li>
                    </ul>
                    <br></br>
                    <p>
                        By incorporating TENS therapy into your treatment plan, you can experience a non-invasive and personalized approach to pain management. Let us help you navigate your journey to recovery with the support of TENS therapy at Ramp Physio and Fitness.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingTensTherapyInPhysiotherapy;