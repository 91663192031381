import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Trainingwithsuspensiontrainersegtrx = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Fitness with Suspension Trainers</h1>
                    <h2>Unlock the potential of TRX Suspension Training at Ramp Physio and Fitness for a full-body workout that improves strength, balance, and flexibility.</h2>
                    <p>Suspension training, particularly with TRX systems, has taken the fitness world by storm, providing an effective way to utilize your body weight for a full-body workout. At Ramp Physio and Fitness, we're dedicated to helping you achieve your health and fitness goals through innovative training methods. In this article, we'll explore the benefits of training with suspension trainers, how they can transform your workouts, and why they're a fantastic addition to any fitness regimen.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Strength and Stability Training</h1>
                        <p>With TRX suspension trainers, you engage multiple muscle groups simultaneously, enhancing core stability and overall strength. These versatile tools allow for a vast range of exercises, each promoting better muscle coordination and functional strength. Whether you're looking to build muscle, lose weight, or improve athletic performance, suspension training offers a scalable solution for all fitness levels.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" /> {/* Keeping the original image as per instructions */}
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" /> {/* Keeping the original image as per instructions */}
                </div>
                <div className="service-intro-content2">
                    <h1>Dynamic Flexibility and Mobility</h1>
                    <p>Suspension trainers like TRX are not only about strength; they also offer an excellent way to improve flexibility and mobility. By leveraging gravity and your body weight, you can perform stretches and movements that enhance your range of motion, reduce the risk of injury, and contribute to overall mobility, which is essential for day-to-day activities and athletic endeavors alike.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" /> {/* Keeping the original image as per instructions */}
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Accessible and Portable Fitness</h1>
                    <p>One of the best features of suspension trainers is their portability. TRX systems can be set up virtually anywhere, making them a convenient option for those who prefer to train at home or on the go. This accessibility ensures that you can maintain your fitness routine no matter where life takes you, without the need for heavy and bulky equipment.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" /> {/* Keeping the original image as per instructions */}
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" /> {/* Keeping the original image as per instructions */}
                    </div>
                    <div className="service-intro-content2">
                        <h1>Customizable Workout Intensity</h1>
                        <p>Whether you're a beginner or an experienced athlete, suspension trainers like TRX offer adjustable resistance and difficulty levels. By simply changing your body position, you can increase or decrease the intensity of each exercise. This customization makes it possible to create tailored workouts that align with your fitness level and goals.</p>
                        <br></br>
                        <p>
                            Here are 10 exercises to try with suspension trainers:
                            <ul>
                                <li>TRX Push-ups for chest and core strength</li>
                                <li>TRX Rows for back and biceps</li>
                                <li>TRX Squats for legs and glutes</li>
                                <li>TRX Lunges for lower body and stability</li>
                                <li>TRX Planks for a strong core</li>
                                <li>TRX Hamstring Curls for posterior chain development</li>
                                <li>TRX Bicep Curls for arm toning</li>
                                <li>TRX Tricep Extensions for upper arm strength</li>
                                <li>TRX Chest Fly for pectoral muscles</li>
                                <li>TRX Pike for advanced core engagement</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Trainingwithsuspensiontrainersegtrx;