import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ExercisesToAlleviateNeckAndShoulderTension = () => {
    return (
        <div>
            <Helmet>
                <title>Exercises to Alleviate Neck and Shoulder Tension | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover effective exercises to alleviate neck and shoulder tension with Ramp Physio and Fitness. Achieve comfort and mobility with our expert guidance." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviate Neck and Shoulder Tension</h1>
                    <h2>Unlock the secrets to relieving neck and shoulder tension with our expertly crafted exercises designed to restore comfort and enhance mobility.</h2>
                    <p>Neck and shoulder tension can be debilitating, affecting your daily activities and overall quality of life. Ramp Physio and Fitness is dedicated to helping you combat this discomfort through targeted exercises that promote relaxation, strength, and flexibility. In this comprehensive guide, we'll walk you through the most effective movements to alleviate tension and improve your neck and shoulder health.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Neck and Shoulder Pain</h1>
                        <p>Before diving into the exercises, it's essential to understand the common causes and symptoms of neck and shoulder pain. Factors such as poor posture, prolonged sitting, and repetitive strain can contribute to tension and discomfort. Recognizing these triggers is the first step in implementing preventive measures and selecting the right exercises for relief.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Top Exercises for Tension Relief</h1>
                    <ul>
                        <li>Neck Tilts and Turns</li>
                        <li>Shoulder Rolls and Shrugs</li>
                        <li>Doorway Pectoral Stretch</li>
                        <li>Scapular Retraction</li>
                        <li>Upper Trapezius Stretch</li>
                        <li>Levator Scapulae Stretch</li>
                        <li>Corner Stretch</li>
                        <li>Child's Pose Variation</li>
                        <li>Wall Angels</li>
                        <li>Supine Thoracic Extension</li>
                    </ul>
                    <p>These exercises are designed to target the muscles that commonly hold tension in the neck and shoulders. By incorporating these movements into your routine, you can work towards alleviating pain and preventing future discomfort.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating Exercises into Daily Life</h1>
                    <p>Consistency is key when it comes to neck and shoulder exercises. We'll provide strategies for integrating these exercises into your daily routine, ensuring that you can maintain tension relief throughout your day. Whether you're at work, home, or on the go, we'll help you find opportunities to practice these movements and prioritize your musculoskeletal health.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Care at Ramp Physio</h1>
                    <p>At Ramp Physio and Fitness, we understand that each individual's needs are unique. That's why we offer personalized care plans to address your specific concerns. Our team of physiotherapists will work with you to develop a customized exercise program that fits your lifestyle and helps you achieve lasting relief from neck and shoulder tension.</p>
                    <br></br>
                    <p>
                        Don't let neck and shoulder tension hold you back any longer. Visit Ramp Physio and Fitness to start your journey towards a pain-free and active lifestyle. Our expert team is ready to guide you through each step, providing the support and resources you need to overcome discomfort and reclaim your well-being.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ExercisesToAlleviateNeckAndShoulderTension;