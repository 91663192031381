import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForSpinalCordInjuries = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Spinal Cord Injuries | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert NDIS physiotherapy for spinal cord injuries at Ramp Physio and Fitness in Homebush, NSW. Achieve your rehabilitation goals with our dedicated team." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert NDIS Physiotherapy for Spinal Cord Injuries</h1>
                    <h2>Unlock Your Potential with Specialized Care at Ramp Physio and Fitness</h2>
                    <p>Spinal cord injuries (SCIs) can transform lives, demanding expert care and a dedicated rehabilitation journey. At Ramp Physio and Fitness, we specialize in NDIS physiotherapy tailored to the unique needs of individuals with SCIs. Our compassionate approach combines cutting-edge techniques and personalized strategies to empower our clients towards achieving their health and fitness goals. Join us as we explore the vital role of physiotherapy in SCI rehabilitation and how our services can support your journey to recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Spinal Cord Injury Rehabilitation</h1>
                        <p>Spinal cord injury rehabilitation is a multifaceted process, requiring a blend of physiotherapy techniques to maximize recovery. Our NDIS physiotherapy services are designed to address the physical, emotional, and social aspects of SCI, providing a holistic and integrated approach to care.</p>

                        <p>With a focus on restoring function and enhancing quality of life, our physiotherapists work closely with clients to develop customized treatment plans. These plans may include strength training, mobility exercises, and functional retraining, all aimed at fostering independence and supporting clients in their daily activities.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized NDIS Physiotherapy Plans</h1>
                        <p>Every SCI is unique, and so is our approach to physiotherapy. We begin by thoroughly assessing each client's condition, taking into account their specific injury, mobility, and personal objectives. From there, we craft an NDIS-funded physiotherapy plan that is as individual as you are.</p>

                        <p>Our physiotherapists are trained in the latest SCI rehabilitation techniques, ensuring that you receive the most effective treatment possible. We incorporate a variety of modalities, including manual therapy, exercise prescription, and technology-assisted interventions, to stimulate neuroplasticity and promote recovery.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer top="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Empowering Independence through Mobility</h1>
                    <p>Regaining mobility is a cornerstone of SCI rehabilitation. Our physiotherapists employ a range of techniques to improve motor function, from gait training and balance exercises to aquatic therapy and adaptive equipment training.</p>

                    <p>We understand the importance of autonomy and work diligently to enhance the mobility of our clients, providing them with the skills and confidence necessary to navigate their environment and perform daily tasks with greater ease.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>NDIS Support for Lifelong Well-being</h1>
                        <p>Our commitment to your health extends beyond the walls of our facility. We strive to provide ongoing support and education, helping you to integrate healthy practices into your lifestyle for sustainable well-being.</p>

                        <p>Through the NDIS, we can offer continued access to physiotherapy services, ensuring that you have the resources and support needed to maintain your gains and prevent secondary complications associated with SCI.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForSpinalCordInjuries;