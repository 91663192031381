import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Highintensityfunctionaltraininghiftexplained = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>High-Intensity Functional Training (HIFT) Explained</h1>
                    <h2>Unlock the power of High-Intensity Functional Training at Ramp Physio and Fitness, and propel your fitness journey with our expertly designed HIFT programs tailored to maximize health and performance in Homebush, NSW.</h2>
                    <p>High-Intensity Functional Training (HIFT) is a training methodology that combines the intensity of high-intensity interval training (HIIT) with functional movements that are essential for daily life and athletic performance. At Ramp Physio and Fitness, our HIFT programs are designed to challenge your body in a safe, effective, and functional way, helping you to achieve your health and fitness goals faster. In this comprehensive guide, we'll explore what HIFT is, how it differs from traditional workouts, and the myriad of benefits it offers.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Essence of HIFT</h1>
                        <p>HIFT is a versatile training approach that emphasizes functional, multi-joint movements at high intensities. These workouts are scalable to all fitness levels and are designed to improve strength, endurance, and agility. They typically involve a combination of weightlifting, bodyweight exercises, and aerobic activities, performed in a circuit or interval format. The goal of HIFT is not only to enhance athletic performance but also to promote overall health and well-being.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Benefits of HIFT</h1>
                    <ul>
                        <li>Enhanced cardiovascular health</li>
                        <li>Increased metabolic rate for fat loss</li>
                        <li>Improved muscular strength and endurance</li>
                        <li>Greater functional capacity for everyday activities</li>
                        <li>Better body composition and muscle tone</li>
                        <li>Boosted mental toughness and stress resilience</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>HIFT vs. Traditional Training</h1>
                    <p>Unlike traditional training programs that might focus on isolated muscle groups or steady-state cardio, HIFT encompasses a broader range of physical attributes. It's a more dynamic and efficient way to train, often resulting in shorter, yet more intense workouts that yield quicker and more comprehensive results. By incorporating functional movements, HIFT prepares your body for real-world activities and sports, enhancing your overall performance and reducing the risk of injury.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating HIFT into Your Routine</h1>
                    <p>At Ramp Physio and Fitness, we support you in seamlessly integrating HIFT into your existing fitness routine or helping you start a new one. Our expert trainers will guide you through each session, ensuring you perform movements with proper form and intensity. Whether you're a seasoned athlete or just beginning your fitness journey, our HIFT programs are customizable to your individual needs and goals, providing a challenging yet rewarding experience.</p>
                    <br></br>
                    <p>
                        In conclusion, High-Intensity Functional Training is a powerful workout methodology that combines the best of high-intensity and functional training. At Ramp Physio and Fitness, we are dedicated to offering top-notch HIFT programs that cater to individuals of all fitness levels. By joining our HIFT sessions, you're not just working out; you're investing in a healthier, stronger, and more capable version of yourself. Book your session today and take the first step towards transforming your fitness routine with HIFT.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Highintensityfunctionaltraininghiftexplained;