import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const HowToCreateAPersonalizedWorkoutPlan = () => {
    return (
        <div>
            <Helmet>
                <title>Create a Personalized Workout Plan | Ramp Physio and Fitness</title>
                <meta name="description" content="Learn how to create a personalized workout plan with expert guidance from Ramp Physio and Fitness in Homebush, NSW." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Your Guide to a Customized Fitness Routine</h1>
                    <h2>Tailor your exercise plan with precision at Ramp Physio and Fitness, and embark on a journey tailored to your unique health and fitness goals.</h2>
                    <p>Creating a personalized workout plan is a critical step towards achieving your fitness goals. At Ramp Physio and Fitness, we understand that every individual is unique, with different needs, goals, and preferences. This guide is designed to help you understand the fundamentals of crafting a workout routine that aligns with your objectives, enhances your strengths, and addresses your weaknesses. Let's dive into the process of creating a workout plan that's as unique as you are.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Setting Realistic Fitness Goals</h1>
                        <p>Before you start pumping iron or hitting the treadmill, it's crucial to set clear, achievable goals. Whether you're aiming to lose weight, build muscle, or improve endurance, your goals will dictate the structure of your workout plan. Here are 10 examples of specific fitness goals you might set for yourself:</p>
                        <ul>
                            <li>Lose 10 pounds in 3 months</li>
                            <li>Increase bench press weight by 20%</li>
                            <li>Run a half-marathon in under 2 hours</li>
                            <li>Complete a 12 week challenge</li>
                            <li>Swim 1 kilometer without stopping</li>
                            <li>Master 5 new bodyweight exercises</li>
                            <li>Improve posture and flexibility</li>
                            <li>Reduce body fat percentage by 5%</li>
                            <li>Complete a race over a certain distance or time</li>
                            <li>Do 50 consecutive push-ups</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Assessing Your Current Fitness Level</h1>
                    <p>Understanding where you're starting from is essential to creating a plan that will work for you. Assessing your current fitness level can help you set realistic goals and track progress. At Ramp Physio and Fitness, our team can conduct a comprehensive fitness assessment, including body composition analysis, cardiovascular testing, strength evaluation, and flexibility measurements.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Understanding Workout Components</h1>
                    <p>Every effective workout plan includes various components that target different aspects of fitness. Here are the key elements you should incorporate into your routine:</p>
                    <ul>
                        <li>Warm-up exercises to prepare your body</li>
                        <li>Cardiovascular training for heart health</li>
                        <li>Strength training to build muscle</li>
                        <li>Flexibility exercises for a full range of motion</li>
                        <li>Balance training to prevent falls</li>
                        <li>Cooldown and recovery to reduce soreness</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Designing Your Workout Structure</h1>
                    <p>Once you've set your goals and assessed your fitness level, it's time to design your workout structure. This includes determining the frequency, intensity, time, and type (FITT principle) of your workouts. Here's how you might structure your plan:</p>
                    <ul>
                        <li>Frequency: 3-5 days per week</li>
                        <li>Intensity: Moderate to high, depending on goals</li>
                        <li>Time: 30-60 minutes per session</li>
                        <li>Type: A mix of cardio, strength, and flexibility</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personalizing Your Exercise Selection</h1>
                    <p>Choosing the right exercises is crucial for a personalized workout plan. Consider your interests, equipment availability, and any physical limitations. Here are 10 exercises to consider for various fitness components:</p>
                    <ul>
                        <li>Treadmill running for cardio</li>
                        <li>Squats for lower body strength</li>
                        <li>Push-ups for upper body strength</li>
                        <li>Planks for core stability</li>
                        <li>Dumbbell rows for back strength</li>
                        <li>Box jumps for explosive power</li>
                        <li>Appropriate weigth training protocols for hypertrophy (building muscle mass)</li>
                        <li>RAMP individually tailored and physiotherapy designed and monitored flexibility and core stability programs</li>
                        <li>Bosu ball exercises for balance</li>
                        <li>Stretching for cooldown</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <h1>Monitoring Progress and Adjustments</h1>
                    <p>Tracking your progress is key to staying motivated and making necessary adjustments to your plan. Keep a workout log, take regular fitness assessments, and celebrate your achievements, no matter how small. Remember, your workout plan is not set in stone—it should evolve with your fitness level and goals.</p>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Staying Consistent and Motivated</h1>
                    <p>Maintaining consistency is one of the biggest challenges in any fitness journey. Find ways to stay motivated, whether it's through a workout buddy, setting micro-goals, or mixing up your routine to keep things interesting. At Ramp Physio and Fitness, we're here to support you every step of the way.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <h1>Embracing the Journey</h1>
                    <p>Creating a personalized workout plan is the beginning of a rewarding journey towards better health and fitness. Embrace the process, be patient with yourself, and remember that every small step counts. With Ramp Physio and Fitness by your side, you have a partner in your pursuit of a healthier, fitter you.</p>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default HowToCreateAPersonalizedWorkoutPlan;