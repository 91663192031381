import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
import { Link } from 'react-router-dom'; // Import Link for routing

const NdisPhysiotherapyForDownSyndrome = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Down Syndrome | Ramp Physio and Fitness</title>
                <meta name="description" content="Ramp Physio and Fitness offers specialized NDIS physiotherapy services for individuals with Down Syndrome in Homebush, NSW. Learn how our tailored programs can support developmental milestones and enhance quality of life." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives with NDIS Physiotherapy for Down Syndrome</h1>
                    <h2>Discover how Ramp Physio and Fitness provides specialized care for individuals with Down Syndrome through NDIS-funded physiotherapy services.</h2>
                    <p>Down Syndrome, a genetic disorder characterized by developmental and intellectual challenges, can significantly impact an individual's quality of life. At Ramp Physio and Fitness, we are dedicated to offering compassionate and effective NDIS physiotherapy services tailored to meet the unique needs of those with Down Syndrome. Our goal is to empower our clients, helping them achieve their developmental milestones and improve their overall health and fitness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Down Syndrome and NDIS</h1>
                        <p>Down Syndrome is a condition where an individual has an extra copy of chromosome 21, leading to a range of physical and cognitive impairments. The National Disability Insurance Scheme (NDIS) recognizes the specific needs of those with Down Syndrome, providing support and funding for services like physiotherapy that can enhance their abilities and participation in daily activities.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Approaches</h1>
                    <p>Our physiotherapists at Ramp Physio and Fitness use a client-centered approach, creating personalized physiotherapy plans that focus on enhancing motor skills, strength, balance, and coordination. By setting achievable goals, we work closely with individuals with Down Syndrome to ensure that they can reach their full potential.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Developmental Milestones</h1>
                    <p>For children and adults with Down Syndrome, reaching developmental milestones can be challenging. Our NDIS physiotherapy programs are designed to support these milestones, such as walking, speaking, and social interaction, through targeted exercises and activities that promote neurological and muscular development.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Supporting Independence and Mobility</h1>
                    <p>Our physiotherapy services not only aim to improve physical abilities but also to foster independence and mobility. Through exercises and training, individuals with Down Syndrome can gain the confidence and skills needed to navigate their environments more effectively and with greater autonomy.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForDownSyndrome;