import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ExercisesToEnhanceLungCapacity = () => {
    return (
        <div>
            <Helmet>
                <title>Exercises to Enhance Lung Capacity | Ramp Physio and Fitness</title>
                <meta name="description" content="Boost your lung capacity with targeted exercises at Ramp Physio and Fitness in Homebush, NSW." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Boost Your Breath: Exercises for Lung Capacity</h1>
                    <h2>Explore effective exercises to enhance lung capacity and improve respiratory health at Ramp Physio and Fitness.</h2>
                    <p>Breathing is the essence of life, and your lung capacity plays a vital role in your overall health and fitness. At Ramp Physio and Fitness, we understand the importance of robust lung function and are here to guide you through exercises that can help enhance your lung capacity. Whether you're an athlete, recovering from respiratory illness, or simply aiming to improve your breathing, these exercises are designed to strengthen your respiratory muscles and increase your lung volume.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Deep Breathing Techniques</h1>
                        <p>Deep breathing is the cornerstone of increasing lung capacity. It involves taking slow, deep breaths to fill your lungs completely and increase oxygen uptake. Here are some techniques we recommend:</p>
                        <ul>
                            <li>Diaphragmatic breathing</li>
                            <li>Pursed-lips breathing</li>
                            <li>4-7-8 breathing technique</li>
                            <li>Alternate nostril breathing</li>
                            <li>Buteyko breathing method</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Aerobic Exercises for Lungs</h1>
                    <p>Aerobic exercises not only improve cardiovascular health but also enhance lung capacity. Engaging in activities that raise your heart rate will train your lungs to be more efficient. Examples include:</p>
                    <ul>
                        <li>Brisk walking or jogging</li>
                        <li>Swimming</li>
                        <li>Cycling</li>
                        <li>Rowing</li>
                        <li>Dancing</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Resistance Training for Respiratory Muscles</h1>
                    <p>Incorporating resistance training can significantly improve the strength of your respiratory muscles. Here are some resistance exercises to try:</p>
                    <ul>
                        <li>Using a spirometer for guided breathing</li>
                        <li>Blow balloons to increase resistance</li>
                        <li>Resistance band chest pulls</li>
                        <li>Dumbbell pullovers</li>
                        <li>Practicing with a breath exerciser</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Individually tailored and physiotherapy designed programs for lung function</h1>
                        <p>Physiotherapy programs are excellent for improving lung capacity and flexibility of the chest wall. Incorporate these into your routine for better lung health:</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Outdoor Activities for Healthy Lungs</h1>
                    <p>Engaging in outdoor activities not only provides fresh air but also challenges your lungs with varying intensities and terrains. Try these activities:</p>
                    <ul>
                        <li>Hiking on different terrains</li>
                        <li>Canoeing or kayaking</li>
                        <li>Outdoor cycling</li>
                        <li>Beach volleyball</li>
                        <li>Gardening or yard work</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <h1>Personalized Lung Health Programs</h1>
                    <p>At Ramp Physio and Fitness, we offer personalized programs to cater to your specific lung health needs. Our expert team will work with you to create a tailored plan that includes:</p>
                    <ul>
                        <li>Assessment of your current lung capacity</li>
                        <li>Customized exercise plans</li>
                        <li>Progress monitoring and adjustments</li>
                        <li>Educational resources on lung health</li>
                        <li>Support and motivation from our dedicated staff</li>
                    </ul>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ExercisesToEnhanceLungCapacity;