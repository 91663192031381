import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Easing Your Neck Pain: Expert Insights and Solutions</h1>
                    <h2>Discover comprehensive strategies for managing neck pain, integrating expert physiotherapy techniques, and lifestyle adjustments. Ramp Physio and Fitness offers personalized care to alleviate discomfort and enhance neck health.</h2>
                    <p>At Ramp Physio and Fitness, we understand that neck pain can significantly impact your daily life, affecting everything from your work performance to your ability to engage in physical activities. Our goal is to provide you with the knowledge and treatment options necessary to manage and overcome neck pain. In this article, we will delve into the causes, symptoms, and effective treatments for neck pain, drawing on reputable sources and our professional expertise.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>UUnderstanding Neck Pain Causes</h1>
                        <p>Neck pain is a common complaint that can arise from various factors, as detailed by HealthDirect, Healthline, and the Mayo Clinic. It may stem from muscle strains due to poor posture or overuse, wear and tear of the joints, nerve compression, injuries such as whiplash, or diseases like arthritis. Identifying the root cause of your neck pain is crucial to determining the most effective treatment plan.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Symptoms and Diagnosis</h1>
                    <p>Neck pain symptoms can range from a dull ache to sharp, shooting pain, and may be accompanied by stiffness, headaches, or tingling in the arms. According to Spine-Health and Versus Arthritis, diagnosing neck pain typically involves a physical examination, medical history review, and possibly imaging tests. At Ramp Physio and Fitness, our physiotherapists will conduct a thorough assessment to pinpoint the source of your discomfort.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Tailored Treatment Approaches</h1>
                    <p>Once the cause of your neck pain is established, a personalized treatment plan can be developed. This may include physiotherapy techniques such as manual therapy, exercise programs, and modalities like heat or cold therapy. Our team is also skilled in providing support for chronic pain management and can guide you through exercises that improve strength, flexibility, and posture, as recommended by HealthDirect and Healthline.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Lifestyle and Wellness Integration</h1>
                    <p>Managing neck pain isn't solely about physiotherapy; it's also about integrating wellness practices into your daily routine. This includes ergonomic adjustments, stress management techniques, and proper nutrition, which play a significant role in neck health and pain prevention. Group training classes at Ramp Physio and Fitness can also help you maintain a healthy, active lifestyle that supports neck health.</p>
                    <br></br>
                    <p>
                        In conclusion, neck pain is a multifaceted issue that requires a comprehensive approach for effective management. By understanding the causes, recognizing the symptoms, and engaging in a tailored treatment plan, you can alleviate your neck pain and improve your overall quality of life. At Ramp Physio and Fitness, we are dedicated to providing you with the support and expertise needed to tackle neck pain head-on, ensuring that you can return to your daily activities with ease and confidence.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
