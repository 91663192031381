import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import legPainLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForLegPain = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviating Leg Pain with Physiotherapy</h1>
                    <h2>Discover the power of physiotherapy treatment and exercises for leg pain relief at Ramp Physio and Fitness.</h2>
                    <p>Leg pain can significantly affect your daily activities and overall quality of life. At Ramp Physio and Fitness, our expert team is dedicated to diagnosing the underlying cause of your leg pain and providing targeted physiotherapy treatments and exercises. Whether your leg pain is a result of an injury, overuse, or a chronic condition, our physiotherapists are here to support your journey to recovery and help you return to your favorite activities pain-free.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Leg Pain Causes</h1>
                        <p>Leg pain can stem from various sources, including muscle strains, ligament sprains, joint issues, nerve compression, and circulatory problems. Proper identification of the cause is crucial for effective treatment. Our physiotherapists at Ramp Physio and Fitness use a comprehensive assessment to determine the source of your pain and create a personalized treatment plan.</p>

                        <p>Common conditions that lead to leg pain include sciatica, knee osteoarthritis, shin splints, and Achilles tendonitis. By understanding the specific condition, we can tailor our physiotherapy approach to address both the symptoms and the root cause of your leg pain.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Leg Pain Assessment" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Physiotherapy Treatment" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Treatment Options</h1>
                    <p>Our treatment options for leg pain include manual therapy, soft tissue release, joint mobilization, and electrotherapy. These techniques help to alleviate pain, reduce inflammation, and improve joint mobility. Additionally, we may use dry needling or taping methods to support the healing process.</p>

                    <p>For conditions like sciatica, our sciatica treatment program focuses on relieving nerve compression and strengthening the supporting muscles. In cases of knee pain, we incorporate specific exercises to stabilize and strengthen the knee joint.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={legPainLogo} alt="Leg Pain Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Effective Exercises for Leg Pain</h1>
                    <p>Exercise is a cornerstone of physiotherapy for leg pain. Our physiotherapists prescribe targeted exercises to improve strength, flexibility, and endurance. These exercises are designed to support your recovery and prevent future injuries. Here are 10 examples of exercises we might recommend:</p>
                    <p>
                        - Quad sets<br></br>
                        - Hamstring curls<br></br>
                        - Calf raises<br></br>
                        - Glute bridges<br></br>
                        - Straight leg raises<br></br>
                        - Ankle pumps<br></br>
                        - Knee extensions<br></br>
                        - Hip abductions<br></br>
                        - Mini squats<br></br>
                        - Walking lunges
                    </p>
                    <p>Each exercise is tailored to your specific needs and adjusted as your recovery progresses. Our physiotherapists ensure that you perform each movement correctly to maximize its effectiveness and minimize the risk of injury.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Leg Exercises" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Strength Training" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Recovery Programs</h1>
                    <p>At Ramp Physio and Fitness, we believe in a personalized approach to recovery. Your program will be specifically designed to meet your individual needs and goals. We continually monitor your progress and adjust your program as needed to ensure you are on the fastest path to recovery.</p>
                    <p>Whether you're dealing with an acute injury or chronic leg pain, our physiotherapists are here to provide expert care and support every step of the way. We are committed to helping you achieve lasting relief from leg pain and return to your active lifestyle.</p>
                    <br></br>
                    <p>
                        In summary, if you're experiencing leg pain, don't wait to seek help. Our team at Ramp Physio and Fitness is ready to assist you with state-of-the-art physiotherapy treatments and exercises. Book your appointment today and take the first step towards a pain-free life.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForLegPain;