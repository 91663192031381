import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const FacetSyndrome = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Physiotherapy for Facet Syndrome</h1>
                    <h2>Effective Treatment for Lasting Relief from Facet Joint Pain</h2>
                    <p>
                        At <strong>Ramp Physio and Fitness</strong>, we specialize in providing effective physiotherapy treatments for Facet Syndrome to help you alleviate pain and restore mobility. Facet Syndrome is a condition where the facet joints in the spine become inflamed or irritated, leading to back or neck pain. These facet joints play a crucial role in stabilizing the spine and allowing for smooth movement. When they become problematic, it can result from factors such as arthritis, injury, or degenerative changes in the spine. Our experienced physiotherapists are dedicated to identifying the root cause of your facet joint pain and developing a personalized treatment plan that not only addresses your current discomfort but also promotes long-term spinal health and prevents future episodes.
                    </p>

                    <p>
                        Experiencing pain from Facet Syndrome? Don't let it disrupt your life any longer. <a href="/contact">Contact us</a> today to schedule an appointment and take the first step toward a pain-free life.
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                         <h1>Understanding Facet Syndrome and Its Causes</h1>
                        <p>
                            Facet Syndrome occurs when the facet joints, which connect the vertebrae in your spine, become stressed or degenerated. This can result from aging, repetitive movements, poor posture, or injuries. Symptoms often include localized pain, stiffness, and reduced range of motion in the affected area. Understanding the underlying cause is essential for effective treatment and long-term relief.
                        </p>
                        <p>
                            Not sure if your pain is due to Facet Syndrome? Our experts can provide a thorough assessment to identify the source of your discomfort. <a href="/appointments">Schedule an appointment</a> today to begin your journey to recovery.
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Our Comprehensive Approach to Facet Syndrome Treatment</h1>
                    <p>
                        At Ramp Physio and Fitness, we adopt a holistic approach to treating Facet Syndrome. Our physiotherapists create individualized treatment plans that may include manual therapy, specific exercises, posture correction, and education on spinal mechanics. This comprehensive strategy aims to reduce pain, improve mobility, and strengthen supporting muscles to prevent future occurrences.
                    </p>
                    <p>
                        Ready to embark on a personalized treatment journey? <a href="/contact">Contact us</a> to develop a comprehensive plan tailored to your needs.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Success Stories from Our Clients</h1>
                    <p>
                        Many of our clients have experienced significant relief from Facet Syndrome through our specialized physiotherapy programs. From regaining mobility to returning to daily activities without pain, we are proud to have made a positive impact on their lives. Read their testimonials to learn how Ramp Physio and Fitness has helped them overcome Facet Syndrome.
                    </p>
                    <br />
                    <h1>Who Can Benefit from Our Facet Syndrome Treatment?</h1>
                    <p>
                        Our Facet Syndrome treatment is suitable for individuals experiencing back or neck pain due to facet joint issues. Whether your symptoms are mild or severe, our physiotherapists can provide effective interventions to alleviate discomfort and improve function. Early intervention can prevent the condition from worsening and promote faster recovery.
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                        <h1>What to Expect During Your Physiotherapy Sessions</h1>
                        <p>
                            Your comfort and recovery are our top priorities during your physiotherapy sessions. Our skilled physiotherapists will conduct a thorough assessment to understand your condition and develop a personalized treatment plan. During your sessions, you may receive manual therapy to improve joint mobility, guided exercises to strengthen supporting muscles, and advice on posture and ergonomics to support your recovery.
                        </p>
                        <p>
                            Have questions about the treatment process? Our team is here to guide you every step of the way. <a href="/contact">Contact us</a> to learn more.
                        </p>
                        <br />
                        <h1>Combining Therapies for Optimal Results</h1>
                        <p>
                            To maximize your recovery, we often combine various physiotherapy techniques tailored to your specific needs. This integrative approach addresses both immediate symptoms and underlying causes, promoting long-term relief and preventing future flare-ups.
                        </p>
                        <p>
                            Ready to enhance your recovery journey? <a href="/contact">Contact us</a> to develop a comprehensive treatment plan designed just for you.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default FacetSyndrome;
