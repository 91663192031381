import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ErgonomicsAndWorkplaceInjuryPrevention = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Ergonomics for Injury Prevention</h1>
                    <h2>Unlock the potential of workplace ergonomics and prevent injuries with Ramp Physio and Fitness, your partner in fostering a healthy, productive work environment.</h2>
                    <p>At Ramp Physio and Fitness, we understand that a significant portion of workplace injuries can be attributed to poor ergonomics. Our expert approach to ergonomics and injury prevention is designed to align with your body's natural mechanics, reducing the risk of musculoskeletal disorders and enhancing overall well-being. In this comprehensive guide, we'll explore the importance of ergonomics in the workplace and provide practical strategies for injury prevention.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Ergonomics at Workplace" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Workplace Ergonomics</h1>
                        <p>Ergonomics is the science of designing the workplace, keeping in mind the capabilities and limitations of the worker. Proper ergonomic design is necessary to prevent repetitive strain injuries, which can develop over time and can lead to long-term disability. The goal of ergonomics is to create a work environment that fits the individual worker, thereby reducing the risk of injury and illness.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Key Strategies for Injury Prevention</h1>
                    <p>Injury prevention in the workplace is a multi-faceted approach that involves education, ergonomic assessments, and proactive measures. By understanding the common causes of workplace injuries and implementing preventative strategies, organizations can significantly reduce the incidence of these injuries. Here are key strategies for injury prevention:</p>
                    <ul>
                        <li>Conduct regular ergonomic assessments</li>
                        <li>Provide ergonomic training to employees</li>
                        <li>Implement rest breaks and stretching programs</li>
                        <li>Design workstations to fit the user</li>
                        <li>Use ergonomic tools and equipment</li>
                        <li>Encourage early reporting of discomfort</li>
                        <li>Adapt tasks to minimize repetitive motion</li>
                        <li>Promote a culture of safety and health</li>
                        <li>Engage employees in health and wellness programs</li>
                        <li>Regularly review and update ergonomic practices</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>The Role of Physiotherapy</h1>
                    <p>Physiotherapy plays a crucial role in both the prevention and management of workplace injuries. Through individual assessments, physiotherapists can identify risk factors and provide interventions that are tailored to the needs of each worker. This may include exercises, education, and modifications to the workplace environment to ensure that employees are working in a manner that promotes health and safety.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Ergonomic Solutions for Every Workplace</h1>
                    <p>No two workplaces are the same, and that's why ergonomic solutions must be customized to meet the unique demands of each environment. From office settings to construction sites, our team at Ramp Physio and Fitness can help design ergonomic solutions that reduce the risk of injury and improve productivity. We work closely with employers and employees to implement strategies that are both effective and sustainable.</p>
                    <br></br>
                    <p>
                        In conclusion, workplace ergonomics and injury prevention are critical components of a healthy and productive work environment. By partnering with Ramp Physio and Fitness, you can ensure that your organization is taking proactive steps to safeguard the health and well-being of your workforce. Contact us today to learn how our expertise in ergonomics can benefit your business and help you create a safer workplace.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ErgonomicsAndWorkplaceInjuryPrevention;