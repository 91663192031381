import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Trainingwithkettlebellsexercisesandbenefits = () => {
    return (
        <div>
            <Helmet>
                <title>Training with Kettlebells: Exercises and Benefits - Ramp Physio and Fitness</title>
                <meta name="description" content="Master the art of kettlebell training with our comprehensive guide on exercises and benefits. Achieve your fitness goals with Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Master Kettlebell Training</h1>
                    <h2>Unleash the full potential of kettlebell exercises and discover their numerous benefits to elevate your fitness journey at Ramp Physio and Fitness.</h2>
                    <p>Kettlebell training is a dynamic way to improve strength, balance, and endurance. At Ramp Physio and Fitness, we integrate kettlebell exercises into our fitness programs to help you achieve optimal health and performance. This article will guide you through various kettlebell exercises and explain their benefits, ensuring you can safely and effectively incorporate them into your routine.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Kettlebell Training" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Benefits of Kettlebell Workouts</h1>
                        <ul>
                            <li>Builds functional strength</li>
                            <li>Enhances cardiovascular fitness</li>
                            <li>Improves flexibility and mobility</li>
                            <li>Promotes fat loss and muscle definition</li>
                            <li>Boosts core stability and balance</li>
                            <li>Offers a full-body workout in less time</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Core Kettlebell Exercises</h1>
                    <p>Explore ten core kettlebell exercises to enhance your training regimen:</p>
                    <ol>
                        <li>Kettlebell Swings</li>
                        <li>Goblet Squats</li>
                        <li>Turkish Get-Ups</li>
                        <li>Kettlebell Clean and Press</li>
                        <li>Single-Arm Kettlebell Snatch</li>
                        <li>Windmills</li>
                        <li>Farmer's Walk</li>
                        <li>Kettlebell Deadlifts</li>
                        <li>Russian Twists</li>
                        <li>Kettlebell Rows</li>
                    </ol>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Maximizing Workout Efficiency</h1>
                    <p>Understand why kettlebell training is renowned for its efficiency and how it maximizes your workout time:</p>
                    <ul>
                        <li>Simultaneous strength and cardio training</li>
                        <li>Engagement of multiple muscle groups</li>
                        <li>High-intensity intervals for fat burning</li>
                        <li>Shorter workouts with high-calorie expenditure</li>
                        <li>Adaptability for all fitness levels</li>
                        <li>Convenience for home and gym environments</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Safe Kettlebell Practices</h1>
                    <p>Learn the importance of safe kettlebell practices to prevent injuries and enhance training effectiveness:</p>
                    <ul>
                        <li>Proper form and technique</li>
                        <li>Gradual progression of weight</li>
                        <li>Attention to body alignment</li>
                        <li>Controlled movements</li>
                        <li>Rest and recovery periods</li>
                        <li>Guidance from qualified trainers</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Trainingwithkettlebellsexercisesandbenefits;