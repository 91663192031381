import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Understandingmacrosproteincarbsandfats = () => {
    return (
        <div>
            <Helmet>
                <title>Understanding Macros: Protein, Carbs, and Fats - Ramp Physio and Fitness</title>
                <meta name="description" content="Unlock the potential of macronutrients for your health and fitness goals with Ramp Physio and Fitness in Homebush, NSW." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Nutrition Fundamentals: Macros</h1>
                    <h2>Explore the roles of protein, carbohydrates, and fats in your diet and learn how to balance them for optimal health and performance at Ramp Physio and Fitness.</h2>
                    <p>Understanding the balance of macronutrients – proteins, carbohydrates, and fats – is essential for anyone looking to optimize their health and fitness. Each macronutrient plays a unique role in our bodies, influencing everything from muscle building and energy levels to overall metabolic health. In this comprehensive guide, we'll break down the basics of macros and provide actionable tips to help you make informed dietary choices that support your fitness journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Understanding Macros" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Power of Protein</h1>
                        <p>Protein is the building block of muscles and is vital for repair and growth. It's also crucial for producing enzymes and hormones. Incorporating a variety of protein sources, such as lean meats, dairy, legumes, and nuts, ensures a range of amino acids that support your body's functions.</p>
                        <ul>
                            <li>Chicken breast: A lean protein source</li>
                            <li>Cottage cheese: High in casein, a slow-digesting protein</li>
                            <li>Lentils: A plant-based protein with fiber</li>
                            <li>Almonds: A convenient snack with protein and healthy fats</li>
                            <li>Whey protein: A fast-absorbing protein ideal post-workout</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Carbs for Energy</h1>
                    <p>Carbohydrates are the primary energy source for our bodies, especially during high-intensity activities. They're stored as glycogen in muscles and the liver and are essential for brain function. Choosing the right type of carbs, such as whole grains, fruits, and vegetables, can provide sustained energy and nutrients.</p>
                    <ul>
                        <li>Sweet potatoes: Rich in fiber and vitamins</li>
                        <li>Quinoa: A complete protein and complex carb</li>
                        <li>Berries: Packed with antioxidants and fiber</li>
                        <li>Oats: A heart-healthy source of slow-releasing energy</li>
                        <li>Brown rice: A whole grain that pairs well with protein</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Fats for Function</h1>
                    <p>Fats are essential for hormone production, nutrient absorption, and providing a concentrated source of energy. They also play a role in protecting our organs and maintaining cell membranes. Opt for healthy fats found in foods like avocados, olive oil, fatty fish, and seeds.</p>
                    <ul>
                        <li>Avocado: A source of monounsaturated fats and fiber</li>
                        <li>Chia seeds: High in omega-3 fatty acids</li>
                        <li>Salmon: Provides essential omega-3s for heart health</li>
                        <li>Olive oil: A staple of the heart-healthy Mediterranean diet</li>
                        <li>Walnuts: A brain-boosting snack with healthy fats</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Macro Balancing Act</h1>
                    <p>Understanding how to balance your intake of protein, carbs, and fats can be transformative for your fitness goals. Our experts at Ramp Physio and Fitness can guide you in creating a personalized nutrition plan that aligns with your training regimen and lifestyle, ensuring you get the right mix of macros to fuel your body effectively.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Understandingmacrosproteincarbsandfats;