import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForImprovingBalanceAndCoordination = () => {
    return (
        <div>
            <Helmet>
                <title>Improve Balance and Coordination | Ramp Physio and Fitness</title>
                <meta name="description" content="Enhance your balance and coordination with targeted gym workouts. Join us at Ramp Physio and Fitness in Homebush, NSW for expert guidance." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Gym Workouts for Balance and Coordination</h1>
                    <h2>Master Stability and Control with Our Tailored Gym Workouts</h2>
                    <p>Discover the key to enhanced balance and coordination through our specialized gym workouts at Ramp Physio and Fitness. Whether you're an athlete seeking to refine your agility or someone looking to improve your daily functional movements, our tailored exercises are designed to bolster stability and control, offering a foundation for a healthier, more active lifestyle.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Balance and Coordination" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Strengthen Your Core, Enhance Your Life</h1>
                        <p>A strong core is the cornerstone of good balance and coordination. Our gym workouts include a variety of core-strengthening exercises that not only improve your posture and stability but also reduce the risk of falls and injuries. By integrating these exercises into your routine, you'll notice significant improvements in your overall physical performance.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Balance Training for All Ages</h1>
                    <p>At Ramp Physio and Fitness, we understand that balance is essential for individuals of all ages. Our balance training programs cater to everyone, from young adults to seniors, providing exercises that challenge and improve stability, such as single-leg stands, heel-to-toe walks, and stability ball workouts.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Dynamic Movements for Dynamic Lives</h1>
                    <p>Incorporating dynamic movements into your workouts can significantly improve your balance and coordination. Our trainers guide you through a series of functional exercises, such as lunges with twists and plyometric jumps, which mimic everyday activities and sports-specific movements.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>RAMP individually tailored and physiotherapy designed and monitored flexibility and core stability programs |
Achieve Strength and Stability with RAMP’s Tailored Programs</h1>
                    <p>Ramp Physio and Fitness brings you the ultimate in personalized fitness with our individually tailored flexibility and core stability programs, crafted and carefully monitored by experienced physiotherapists. Designed specifically to enhance your movement capabilities and core strength, each program is built around your unique body and goals, providing you with a fitness experience that’s as safe as it is effective. Our physiotherapy-guided approach means that every exercise, stretch, and movement is chosen with your optimal health and injury prevention in mind, promoting functional strength and stability that supports daily activities, sports performance, and long-term wellness.</p>
                    <br></br>
                    <p>
                        Whether you’re recovering from an injury, looking to prevent future ones, or simply aiming to improve your body’s functional movement, our expert-led programs target flexibility, balance, and core control at a level unmatched by traditional workouts. Our physiotherapists are there with you every step of the way, tracking progress and adjusting your program to ensure continued growth and results. Embrace a fitness approach that goes beyond standard training, addressing your body’s specific needs with the care and expertise that only Ramp Physio and Fitness can provide. Let us help you unlock new levels of strength, stability, and confidence through a program uniquely tailored to you. Join us at Ramp Physio and Fitness to unlock your body's full potential with our comprehensive balance and coordination workouts. Our expert trainers are dedicated to helping you achieve your health and fitness goals in a supportive and motivating environment. Book your session now and take the first step towards a more balanced future.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForImprovingBalanceAndCoordination;