import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Disc Bulge Treatment</h1>
                    <h2>Understanding Disc Bulges and How Physiotherapy Can Help</h2>
                    <p>At Ramp Physio and Fitness, we understand that a disc bulge can cause significant discomfort and limit your ability to perform daily activities. A disc bulge occurs when the cushioning discs between your vertebrae protrude beyond their normal boundary, potentially pressing on nearby nerves and causing pain. Our experienced physiotherapists are here to provide effective treatment options to alleviate your symptoms and promote healing.</p>
                    <p>Experiencing pain from a disc bulge? Don't let it hinder your life any longer. Contact us today to schedule an assessment and start your journey toward recovery with our expert physiotherapy services.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>What Is a Disc Bulge?</h1>
                        <p>A disc bulge, also known as a herniated or slipped disc, happens when the outer layer of a spinal disc weakens, allowing the inner gel-like substance to protrude outward. This can occur in any part of the spine but is most common in the lower back and neck regions. Symptoms may include localized pain, numbness, tingling, or weakness that can radiate to other parts of the body, such as the arms or legs.</p>
                        <p>Unsure if you're suffering from a disc bulge? Let our experts help diagnose your condition. Schedule an appointment today and take the first step toward relief.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Benefits of Physiotherapy for Disc Bulge Treatment</h1>
                    <p>Physiotherapy is a highly effective treatment option for managing disc bulge symptoms. Our personalized treatment plans focus on reducing pain, improving mobility, and strengthening the muscles that support your spine. Techniques may include manual therapy, targeted exercises, posture correction, and education on body mechanics to prevent future injuries.</p>
                    <p>Ready to find relief from your disc bulge symptoms? Contact us to create a customized physiotherapy plan that fits your needs and helps you achieve long-term recovery.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Common Causes and Risk Factors of Disc Bulges</h1>
                    <p>Disc bulges often result from gradual, age-related wear and tear known as disc degeneration. As we age, the spinal discs lose hydration and elasticity, making them more susceptible to injury. Other contributing factors include repetitive strain from certain activities, improper lifting techniques, sudden trauma, and prolonged poor posture. Occupations or hobbies that involve heavy lifting or long periods of sitting can increase the risk of developing a disc bulge.</p>  
                    <br></br>
                     <h1>Disc Bulges in Athletes</h1>
                    <p>Athletes are particularly susceptible to disc bulges due to the intense physical demands and repetitive movements involved in sports. High-impact activities, heavy lifting, and sudden twists can place excessive strain on the spinal discs. Sports like weightlifting, gymnastics, football, and golf often involve motions that increase the risk of disc injuries. For athletes, a disc bulge not only causes pain but can also hinder performance and lead to time away from training and competition. Early intervention with targeted physiotherapy can help manage symptoms, promote healing, and facilitate a safe and effective return to athletic activities.</p>  
                    <br></br>
                    <h1>Recognizing the Symptoms of a Disc Bulge</h1>
                    <p>Identifying the symptoms early can significantly improve the effectiveness of treatment. Common symptoms include localized pain in the back or neck, numbness or tingling in the arms or legs, muscle weakness, and decreased range of motion. In some cases, pain may radiate along the nerve pathways, such as sciatica, which affects the lower back and legs. If you're experiencing any of these symptoms, it's crucial to seek professional assessment promptly.</p>  

                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Our Comprehensive Physiotherapy Approach</h1>
                    <p>At Ramp Physio and Fitness, we adopt a holistic approach to treating disc bulges. Our physiotherapists design individualized programs that combine manual therapy, therapeutic exercises, and education on spinal health. We focus on relieving pain, reducing inflammation, and restoring normal function. Additionally, we teach you strategies to protect your spine during daily activities, helping to prevent future injuries.</p>
                    <br></br>
                    <h1>Preventing Future Disc Injuries</h1>
                    <p>
                       Prevention is a key aspect of our treatment philosophy. We provide guidance on ergonomics, posture correction, and lifestyle modifications to reduce strain on your spine. This may include advice on proper sitting and standing positions, safe lifting techniques, and incorporating regular physical activity into your routine. By implementing these preventive measures, you can maintain a healthy spine and reduce the likelihood of recurring disc issues.
                    </p>
                    <p>Ready to take control of your spinal health? Contact us today to learn more about how our expert physiotherapy services can help you overcome a disc bulge and prevent future injuries.</p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
