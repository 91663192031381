import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RehabilitationAfterShoulderReplacementSurgery = () => {
    return (
        <div>
            <Helmet>
                <title>Rehabilitation After Shoulder Replacement Surgery | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert guidance on rehabilitation after shoulder replacement surgery for optimal recovery and return to function." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Shoulder Replacement Rehab Expertise</h1>
                    <h2>Optimize your recovery with Ramp Physio and Fitness' specialized rehabilitation program after shoulder replacement surgery.</h2>
                    <p>Undergoing shoulder replacement surgery is a significant step towards regaining mobility and reducing pain. However, the journey to full recovery requires a carefully structured rehabilitation plan. At Ramp Physio and Fitness, our expert team is dedicated to guiding you through each phase of your post-surgical rehabilitation, ensuring a safe and effective return to your daily activities. This article outlines the critical aspects of rehabilitation after shoulder replacement surgery and how our services can help you achieve the best possible outcomes.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Shoulder Replacement Rehabilitation" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Initial Recovery Phase</h1>
                        <p>The initial phase of rehabilitation focuses on reducing pain and inflammation while protecting the integrity of your new shoulder joint. This period typically involves:</p>
                        <ul>
                            <li>Gentle passive range-of-motion exercises</li>
                            <li>Use of ice packs and medication for pain management</li>
                            <li>Guidance on post-surgical care and sling usage</li>
                            <li>Prevention of stiffness through careful mobilization</li>
                            <li>Education on do's and don'ts during early recovery</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Strength and Mobility Enhancement</h1>
                    <p>As your recovery progresses, the focus shifts to restoring strength and mobility. This stage of rehabilitation may include:</p>
                    <ul>
                        <li>Active range-of-motion exercises</li>
                        <li>Gradual introduction of resistance training</li>
                        <li>Specific exercises to improve rotator cuff and scapular strength</li>
                        <li>Therapeutic modalities to facilitate muscle function</li>
                        <li>Functional training to prepare for daily activities</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Advanced Rehabilitation Techniques</h1>
                    <p>For those aiming to return to high-level activities or sports, advanced rehabilitation techniques are essential. This may involve:</p>
                    <ul>
                        <li>Sport-specific drills and training</li>
                        <li>Advanced strengthening and conditioning protocols</li>
                        <li>Dynamic stability and proprioception exercises</li>
                        <li>Techniques to maximize range of motion and flexibility</li>
                        <li>Strategies to minimize the risk of future injuries</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Return to Fitness and Beyond</h1>
                    <p>Our ultimate goal is to not only return you to your pre-surgery level of function but to surpass it. To achieve this, we offer:</p>
                    <ul>
                        <li>Guidance on long-term fitness and wellness</li>
                        <li>Personal training services for continued progress</li>
                        <li>Education on joint protection and maintenance</li>
                        <li>Access to gym facilities for independent exercise</li>
                        <li>Support to help you achieve and maintain optimal health</li>
                    </ul>
                    <br></br>
                    <p>
                        Rehabilitation after shoulder replacement surgery is a journey, and Ramp Physio and Fitness is here to support you every step of the way. Our tailored programs, state-of-the-art facilities, and compassionate care ensure that your path to recovery is smooth and successful. Join us to experience a rehabilitation process that is not just about recovery, but about achieving a level of fitness and well-being you may not have thought possible.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RehabilitationAfterShoulderReplacementSurgery;