import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const CreatingABalancedGymWorkoutPlan = () => {
    return (
        <div>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>Creating a Balanced Gym Workout Plan | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the secrets to a balanced gym workout plan tailored to your fitness goals. Achieve optimal results with Ramp Physio and Fitness expertise." />
            </Helmet>

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Creating a Balanced Gym Workout Plan</h1>
                    <h2>Unlock your fitness potential with a perfectly structured gym workout plan designed by the experts at Ramp Physio and Fitness.</h2>
                    <p>Embarking on your fitness journey requires more than sheer determination; it necessitates a carefully crafted workout plan that balances various exercise components. At Ramp Physio and Fitness, we specialize in creating personalized gym workout plans that cater to your unique fitness goals, ensuring you work out smarter, not harder. In this detailed guide, we'll explore the key elements of a balanced workout routine, offering insights and examples to help you achieve harmony in your fitness regimen.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>

            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Assess Your Fitness Level</h1>
                        <p>Before diving into a workout plan, it's essential to evaluate your current fitness level. This assessment will guide the intensity and frequency of your exercises, ensuring a safe and effective start. At Ramp Physio and Fitness, we offer an initial fitness assessment that measures your strength, flexibility, and cardiovascular health, laying the foundation for a plan that's in tune with your body's capabilities.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Define Your Fitness Goals</h1>
                        <p>Understanding your objectives is crucial in crafting a workout plan that aligns with your aspirations. Whether you aim to build muscle, lose weight, or improve endurance, your goals will dictate the structure of your routine. Our experienced trainers work closely with you to set realistic, measurable goals and create a tailored plan that propels you towards success.</p>
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer top="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <div className="service-intro-content3">
                        <h1>Balance Your Workout Components</h1>
                        <ul>
                            <li>Cardiovascular training for heart health</li>
                            <li>Strength exercises to build muscle</li>
                            <li>Flexibility movements for a full range of motion</li>
                            <li>Balance exercises to prevent falls</li>
                            <li>Endurance activities to boost stamina</li>
                            <li>Rest and recovery for optimal performance</li>
                        </ul>
                        <p>A balanced workout plan incorporates various components to ensure comprehensive development. At Ramp Physio and Fitness, we emphasize the importance of including strength, cardio, flexibility, and balance exercises, along with adequate rest, to foster a well-rounded fitness experience.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container4">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Structure Your Weekly Routine</h1>
                    <p>Designing a weekly workout schedule is a strategic process that ensures each muscle group receives attention while allowing for recovery. Our fitness experts will help you spread out your workouts throughout the week, alternating between different exercise types and intensities to maximize results and minimize injury risk.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>

            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container6">
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content6">
                        <h1>Monitor and Adapt Your Plan</h1>
                        <p>Continuous monitoring of your progress is key to ensuring your workout plan remains effective. Our team provides regular assessments and adapts your routine as needed, keeping you on track towards your evolving fitness goals. We celebrate your milestones and tweak your plan to overcome plateaus and introduce new challenges.</p>
                    </div>
                </div>
            </SkewedContainer>

            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default CreatingABalancedGymWorkoutPlan;