import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForPersonalityDisorders = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Personality Disorders | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover how NDIS Physiotherapy can support individuals with personality disorders in achieving better physical and mental health." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Enhancing Wellness with NDIS Physiotherapy</h1>
                    <h2>Explore the benefits of NDIS Physiotherapy for individuals with personality disorders at Ramp Physio and Fitness.</h2>
                    <p>Personality disorders can present unique challenges that affect an individual's physical and mental well-being. At Ramp Physio and Fitness, we recognize the profound impact that specialized physiotherapy can have on those living with personality disorders. Under the NDIS, our physiotherapy services are tailored to support and empower participants, focusing on improving their physical health, which can, in turn, positively influence their psychological state. In this article, we delve into the advantages of NDIS physiotherapy for personality disorders and how our dedicated team in Homebush, NSW, can assist in the journey towards holistic health.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Personality Disorders</h1>
                        <p>Personality disorders are characterized by enduring patterns of behavior, cognition, and inner experience that deviate markedly from the expectations of an individual's culture. These patterns are inflexible and pervasive, leading to distress or impairment in social, occupational, or other important areas of functioning.</p>

                        <p>At Ramp Physio and Fitness, our approach begins with a compassionate and comprehensive assessment to understand the unique needs and challenges faced by each NDIS participant. Our physiotherapy interventions are designed not just to address physical symptoms but also to foster a sense of achievement and autonomy, which can be particularly beneficial for those with personality disorders.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Physiotherapy Interventions</h1>
                        <p>Every individual's experience with a personality disorder is unique, and so is our physiotherapy treatment. We create personalized treatment plans that cater to the specific needs of NDIS participants, incorporating a mix of therapeutic exercises, manual therapy, and education to enhance physical functioning and promote self-management.</p>

                        <p>Focusing on the strengths of each participant, our physiotherapists guide them through exercises that not only improve mobility and strength but also aim to boost confidence and encourage a positive body image. We continuously adapt our strategies to align with the participant's progress and preferences, ensuring a responsive and effective treatment journey.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Empowering Through Movement</h1>
                    <p>Physical activity is a powerful tool for empowerment, and our physiotherapy services are designed to harness this potential. By encouraging movement, we aim to help NDIS participants with personality disorders to experience better mood regulation, increased energy levels, and improved sleep patterns.</p>

                    <p>Our evidence-based interventions include exercises that can be incorporated into daily routines, fostering a sense of normalcy and control. We believe in the power of movement to not only rehabilitate but also to revitalize and inspire a more active and fulfilling lifestyle.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Collaborative Care and Support</h1>
                        <p>At Ramp Physio and Fitness, we believe in a collaborative approach to care. We work closely with other healthcare professionals involved in the participant's life to provide comprehensive support that addresses both physical and psychosocial aspects of living with a personality disorder.</p>

                        <p>Our team is committed to creating a supportive environment where participants can thrive, offering guidance and motivation to help them navigate their health and fitness journey with confidence and resilience.</p>

                        <br></br>
                        <p>
                            In summary, our NDIS physiotherapy services for personality disorders are designed to promote physical health as a cornerstone for overall well-being. Our experienced physiotherapists are dedicated to providing compassionate care, personalized treatment plans, and ongoing support to help participants reach their full potential.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForPersonalityDisorders;