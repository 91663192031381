import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Benefitsofoutdoorvsindoorworkouts = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximizing Fitness: Outdoor vs. Indoor Workouts</h1>
                    <h2>Explore the unique benefits of outdoor and indoor workouts to tailor your fitness journey with Ramp Physio and Fitness's expert advice.</h2>
                    <p>Choosing between outdoor and indoor workouts can significantly impact your fitness journey. Both environments offer unique benefits that cater to different preferences and goals. At Ramp Physio and Fitness, we understand the importance of a personalized fitness plan. This article will guide you through the advantages of outdoor and indoor workouts, helping you make an informed decision to achieve your health and fitness objectives.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Benefits of Outdoor Training</h1>
                        <p>Exercising in the great outdoors provides a plethora of benefits, not just for your physical health but also for your mental well-being. Here are ten key advantages of outdoor workouts:</p>
                        <ul>
                            <li>Enhanced mood and reduced stress</li>
                            <li>Natural vitamin D exposure</li>
                            <li>Varied terrain for functional fitness</li>
                            <li>Improved concentration and creativity</li>
                            <li>Greater adherence to regular exercise</li>
                            <li>Opportunities for social interaction</li>
                            <li>Cost-effective workout options</li>
                            <li>Connection with nature</li>
                            <li>Increased energy and vitality</li>
                            <li>Environmental enrichment for diverse workouts</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advantages of Indoor Exercise</h1>
                    <p>Indoor workouts are a staple for many fitness enthusiasts. The controlled environment of a gym or fitness center offers specific advantages that can enhance your workout experience. Here are ten benefits of choosing indoor exercise:</p>
                        <ul>
                            <li>Year-round climate control</li>
                            <li>Access to specialized equipment</li>
                            <li>Professional guidance and support</li>
                            <li>Structured classes and programs</li>
                            <li>Safe environment, free from outdoor hazards</li>
                            <li>Targeted training for specific goals</li>
                            <li>Facilities for post-workout recovery</li>
                            <li>Consistent surface for injury prevention</li>
                            <li>Opportunities for community and camaraderie</li>
                            <li>Convenience and accessibility</li>
                        </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Combining Outdoor and Indoor Workouts</h1>
                    <p>Creating a balanced fitness routine often involves combining the best of both worlds. Integrating outdoor and indoor workouts can lead to a comprehensive fitness plan that maximizes the benefits of each environment. Here are six key ideas to consider:</p>
                    <ul>
                        <li>Utilize indoor workouts for strength training and equipment-based exercises.</li>
                        <li>Incorporate outdoor activities for cardiovascular health and enjoyment of nature.</li>
                        <li>Adapt your workout location based on weather conditions and seasons.</li>
                        <li>Combine social outdoor sports with focused indoor training sessions.</li>
                        <li>Use technology to track progress both indoors and outdoors.</li>
                        <li>Balance the structure of the gym with the spontaneity of outdoor activities.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalizing Your Workout Plan</h1>
                    <p>At Ramp Physio and Fitness, we believe that a personalized workout plan is key to achieving your fitness goals. Whether you prefer the fresh air of outdoor activities or the amenities of an indoor gym, we're here to support your preferences with expert guidance. Our team can help you develop a tailored plan that incorporates the unique benefits of both environments, ensuring a balanced and effective approach to your health and fitness.</p>
                    <br></br>
                    <p>
                        To conclude, whether you choose outdoor or indoor workouts, or a combination of both, the most important factor is finding what works best for you. Ramp Physio and Fitness is dedicated to helping you discover the optimal blend of environments to keep you motivated, healthy, and progressing towards your fitness goals. Join us and let's embark on a journey to maximize your fitness potential together.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Benefitsofoutdoorvsindoorworkouts;