import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingStressThroughExercise = () => {
    return (
        <div>
            <Helmet>
                <title>Managing Stress Through Exercise | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover how to manage stress effectively with exercise routines tailored to your lifestyle at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Managing Stress Through Exercise</h1>
                    <h2>Transform your mental health by integrating exercise into your daily routine for effective stress management at Ramp Physio and Fitness.</h2>
                    <p>Stress is an inevitable part of life, but it doesn't have to take over. Exercise is a powerful tool for managing stress, providing both immediate relief and long-term benefits. At Ramp Physio and Fitness, we understand the importance of physical activity in maintaining mental well-being. In this comprehensive guide, we'll explore the connection between exercise and stress relief, and how you can harness this relationship to enhance your quality of life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Managing Stress Through Exercise" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Exercise as Stress Relief</h1>
                        <p>Physical activity has a profound impact on the body's stress response. Exercise increases the production of endorphins, the brain's feel-good neurotransmitters, which act as natural painkillers and mood elevators. It also reduces levels of the body's stress hormones, such as adrenaline and cortisol. Here are 10 ways exercise can help you manage stress:</p>
                        <ul>
                            <li>Boosting endorphin production</li>
                            <li>Lowering stress hormone levels</li>
                            <li>Improving sleep quality</li>
                            <li>Increasing self-confidence</li>
                            <li>Providing a sense of control</li>
                            <li>Offering a healthy coping mechanism</li>
                            <li>Enhancing cognitive function</li>
                            <li>Improving overall mood</li>
                            <li>Encouraging social interaction</li>
                            <li>Promoting relaxation and mindfulness</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Finding Your Fit</h1>
                    <p>Not all exercises are created equal when it comes to stress management. The key is finding activities that you enjoy and that fit into your lifestyle. Whether it's a high-intensity interval training (HIIT) session, a calming RAMP individually tailored and physiotherapy designed and monitored flexibility and core stability programs, or a brisk walk in the park, the best exercise for stress relief is one that resonates with you personally. At Ramp Physio and Fitness, we offer a range of options to help you discover your perfect stress-busting workout.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Building a Routine</h1>
                    <p>Consistency is key when using exercise as a stress management tool. Building a routine that incorporates regular physical activity into your schedule can help mitigate stress before it starts. Here are six key ideas to consider when building your exercise routine for stress management:</p>
                    <ol>
                        <li>Set realistic goals and expectations</li>
                        <li>Choose activities that fit your current fitness level</li>
                        <li>Incorporate a variety of exercises to keep things interesting</li>
                        <li>Remember to include rest and recovery in your schedule</li>
                        <li>Monitor your progress and adjust as needed</li>
                        <li>Seek professional guidance to maximize benefits</li>
                    </ol>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Group Fitness and Community</h1>
                    <p>Joining group fitness classes not only provides structured workouts but also offers the added benefit of community support. Engaging with others who share similar goals can significantly enhance your motivation and commitment to exercise. At Ramp Physio and Fitness, our group classes are designed to foster a sense of belonging while helping you manage stress through collective energy and encouragement.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingStressThroughExercise;