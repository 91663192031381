import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const SpinalDecompression = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Spinal Decompression Therapy</h1>
                    <h2>Experience Relief with Expert Spinal Decompression Therapy</h2>
                    <p>
                        At Ramp Physio and Fitness, we specialize in <strong>spinal decompression therapy</strong> to help alleviate back pain and promote spinal health. Spinal decompression is a non-invasive treatment that gently stretches the spine to relieve pressure on spinal discs and nerves. This technique effectively treats conditions such as <strong>herniated discs</strong>, <strong>sciatica</strong>, <strong>degenerative disc disease</strong>, and <strong>facet joint disorders</strong>.
                    </p>
                    <p>
                        <strong>Suffering from chronic back pain?</strong> Discover how spinal decompression therapy can help you.{' '}
                        <a href="/contact">Contact us</a> today to schedule an appointment with our experienced physiotherapists and take the first step toward a pain-free life.
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Spinal Decompression Therapy and Its Benefits</h1>
                        <p>
                            Spinal decompression therapy involves using a specialized traction table or device to create negative pressure within the spinal discs. This negative pressure can help retract herniated or bulging discs, taking pressure off nerves and other structures in your spine. The therapy promotes the flow of oxygen, water, and nutrient-rich fluids into the discs, allowing them to heal naturally.
                        </p>
                        <p>
                            <strong>Benefits of spinal decompression therapy include:</strong>
                        </p>
                        <br></br>
                        <ul>
                            <li>Reduced back and neck pain</li>
                            <li>Improved mobility and flexibility</li>
                            <li>Decreased symptoms of nerve compression</li>
                            <li>Enhanced spinal health</li>
                        </ul>
                        <br></br>
                        <p>
                            <strong>Curious about how spinal decompression therapy can benefit you?</strong> Let our experts provide a personalized assessment.{' '}
                            <a href="/appointments">Schedule an appointment</a> today and explore your treatment options.
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Our Comprehensive Approach to Spinal Decompression</h1>
                    <p>
                        At Ramp Physio and Fitness, we integrate spinal decompression therapy into a holistic treatment plan tailored to your specific needs. Our physiotherapists combine decompression therapy with other modalities such as manual therapy, exercise rehabilitation, and education on posture and body mechanics. This comprehensive approach ensures that we address both the symptoms and the underlying causes of your spinal issues.
                    </p>
                    <p>
                        <strong>Ready to embark on a personalized treatment journey?</strong> <a href="/contact">Contact us</a> to create a comprehensive plan that includes spinal decompression therapy and other effective treatments.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Conditions Treated with Spinal Decompression Therapy</h1>
                    <p>
                        Spinal decompression therapy can be beneficial for a variety of conditions affecting the spine, including:
                    </p>
                    <br></br>
                    <ul>
                        <li>
                            <strong>Herniated or Bulging Discs</strong>
                        </li>
                        <li>
                            <strong>Sciatica</strong>
                        </li>
                        <li>
                            <strong>Degenerative Disc Disease</strong>
                        </li>
                        <li>
                            <strong>Facet Syndrome</strong>
                        </li>
                        <li>
                            <strong>Posterior Facet Syndrome</strong>
                        </li>
                    </ul>
                    <br></br>
                    <p>
                        <strong>Not sure if spinal decompression therapy is right for you?</strong>{' '}
                        <a href="/contact">Contact us</a> for a comprehensive evaluation and personalized treatment recommendations.
                    </p>
                    <br />
                    <h1>Success Stories from Our Clients</h1>
                    <p>
                        Many of our clients have experienced significant relief and improved quality of life through our spinal decompression therapy programs. From individuals with chronic back pain to those recovering from spinal injuries, we are proud to have helped numerous people regain mobility and return to their daily activities. Read their testimonials to learn how Ramp Physio and Fitness has made a positive impact on their recovery journeys.
                    </p>
                    <br />
                    <h1>Who Can Benefit from Spinal Decompression Therapy?</h1>
                    <p>
                        Spinal decompression therapy is suitable for adults experiencing back or neck pain due to disc-related issues or nerve compression. It's ideal for those who have not found relief through traditional physiotherapy methods or wish to avoid surgery. Our physiotherapists will determine if this therapy is appropriate for you during your assessment.
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>What to Expect During Your Spinal Decompression Session</h1>
                        <p>Your comfort and safety are our top priorities during spinal decompression therapy. During your session:</p>
                        <ul>
                        <br></br>
                            <li>You'll lie comfortably on a motorized table.</li>
                            <li>A harness is secured around your pelvis and torso.</li>
                            <li>
                                The table gently stretches your spine according to a customized plan designed by your physiotherapist.
                            </li>
                        </ul>
                        <br></br>
                        <p>
                            Sessions typically last 30 to 45 minutes, and most clients require multiple sessions over several weeks for optimal results.
                        </p>
                        <p>
                            <strong>Have questions about the procedure?</strong> Our team is here to guide you every step of the way.{' '}
                            <a href="/contact">Contact us</a> to learn more.
                        </p>
                        <br />
                        <h1>Combining Spinal Decompression with Other Therapies for Optimal Results</h1>
                        <p>
                            To maximize the benefits of spinal decompression therapy, we often combine it with other treatments such as manual therapy, exercise rehabilitation, and ergonomic advice. This integrative approach addresses immediate symptoms and promotes long-term spinal health.
                        </p>
                        <p>
                            <strong>Ready to enhance your recovery journey?</strong> <a href="/contact">Contact us</a> to develop a comprehensive treatment plan tailored to your needs.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default SpinalDecompression;
