import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforhyperkyphosishunchback = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Hyperkyphosis - Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy for hyperkyphosis (hunchback) at Ramp Physio and Fitness in Homebush, NSW. Achieve better posture and relief from back pain." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Hyperkyphosis at Ramp Physio and Fitness</h1>
                    <h2>Expert care and personalized treatments to address hyperkyphosis and enhance your quality of life.</h2>
                    <p>Hyperkyphosis, commonly known as hunchback, is a condition characterized by an excessive forward curvature of the upper back. It can lead to chronic pain, reduced mobility, and other health issues. At Ramp Physio and Fitness, we specialize in providing expert physiotherapy to help manage and treat hyperkyphosis. Our team is dedicated to creating personalized treatment plans that cater to your unique needs, helping you achieve better posture and a pain-free lifestyle.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Hyperkyphosis</h1>
                        <p>Hyperkyphosis is more than just poor posture; it's a spinal condition that can significantly impact daily activities. At Ramp Physio and Fitness, we begin with a thorough assessment to understand the extent of your condition and its underlying causes. From there, we develop a targeted treatment plan aimed at reducing curvature, strengthening muscles, and improving overall spinal health.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Treatment Strategies</h1>
                    <p>Each case of hyperkyphosis is unique, which is why our treatments are tailored to the individual. We employ a variety of techniques, including manual therapy, exercise prescription, and education on proper body mechanics. Our goal is to not only alleviate symptoms but also to address the root cause of hyperkyphosis to prevent future complications.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercises for Hyperkyphosis</h1>
                    <p>Exercise is a vital component of managing hyperkyphosis. Our physiotherapists guide clients through targeted exercises designed to strengthen the muscles that support the spine, promote flexibility, and improve posture. Here are 10 exercises we may include in your treatment plan:</p>
                    <ul>
                        <li>Prone thoracic extensions</li>
                        <li>Seated rowing exercises</li>
                        <li>Wall angels</li>
                        <li>Thoracic spine foam rolling</li>
                        <li>Pectoral stretches</li>
                        <li>Chin tucks</li>
                        <li>Isometric poses like Cobra and Child's Pose</li>
                        <li>Pilates exercises focusing on the core and back</li>
                        <li>Resistance band back exercises</li>
                        <li>Bridging for glute and back strength</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Posture and Ergonomics Education</h1>
                    <p>Improving posture isn't just about exercises; it's also about making lifestyle changes. We provide education on proper ergonomics at work and home, helping you maintain optimal posture throughout your day. This holistic approach ensures that the benefits of your treatment extend beyond our clinic, empowering you to manage your condition effectively in everyday life.</p>
                    <br></br>
                    <p>
                        At Ramp Physio and Fitness, we are committed to helping you overcome hyperkyphosis and regain control of your health. With our expert guidance, personalized care, and comprehensive treatment options, you can look forward to improved posture, reduced pain, and a more active, fulfilling life. Book your appointment today and take the first step towards a healthier spine.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforhyperkyphosishunchback;