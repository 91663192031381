import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Gymetiquettedosanddonts = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Gym Etiquette: Do's and Don'ts</h1>
                    <h2>Master the unspoken rules of the gym and create a respectful and efficient workout environment with our comprehensive guide on gym etiquette.</h2>
                    <p>Navigating the social landscape of a gym can be as important as the workout itself. Understanding gym etiquette not only ensures a harmonious environment but also promotes safety, hygiene, and consideration for others. Ramp Physio and Fitness is dedicated to providing a positive gym experience for all. In this article, we dive into the essential do's and don'ts of gym etiquette to help you become a model gym member.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Our Gym
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Etiquette" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Gym Culture</h1>
                        <p>Gym culture can vary from one fitness center to another, but common courtesy is universal. Familiarizing yourself with the basics of gym etiquette can enhance your gym experience and that of your fellow members. From wiping down equipment after use to sharing the space effectively, these simple acts of consideration can make a significant impact.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Respect for Equipment</h1>
                    <p>Properly caring for gym equipment is a cornerstone of good gym etiquette. Not only does it ensure the longevity of the equipment, but it also shows respect for the investment made by the gym and its members. Simple practices like re-racking weights and handling equipment with care can make a world of difference.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Effective Communication</h1>
                    <p>Effective communication is key to maintaining a smooth and efficient gym environment. Whether it's asking to work in on a set or signaling that you're finished with a piece of equipment, clear and polite communication helps prevent misunderstandings and facilitates a cooperative atmosphere.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personal Space Awareness</h1>
                        <p>Respecting personal space is especially important in a gym setting. Being mindful of others' workout zones and avoiding unnecessary encroachment helps maintain a comfortable atmosphere for everyone. This respect for personal space extends to locker rooms and common areas as well.</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Safety First</h1>
                    <p>While the pursuit of fitness goals is commendable, safety should never be compromised. Adhering to proper form, using spotters when necessary, and following the gym's safety guidelines can prevent injuries and ensure a safe environment for all gym-goers.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container6">
                    <div className="service-intro-content6">
                        <h1>Hygiene Matters</h1>
                        <p>Maintaining personal hygiene is a fundamental aspect of gym etiquette. From using deodorant to cleaning up after yourself, these actions contribute to a healthier and more pleasant gym experience for everyone involved.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container7">
                <div className="service-intro-content7">
                    <h1>Do's and Don'ts Recap</h1>
                    <ul>
                        <li>Do wipe down equipment after use.</li>
                        <li>Don't hoard machines or weights.</li>
                        <li>Do re-rack weights after lifting.</li>
                        <li>Don't interrupt someone's workout.</li>
                        <li>Do ask to share equipment politely.</li>
                        <li>Don't make excessive noise.</li>
                        <li>Do wear appropriate attire.</li>
                        <li>Don't bring strong odors into the gym.</li>
                        <li>Do be mindful of your surroundings.</li>
                        <li>Don't leave personal belongings scattered.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Gymetiquettedosanddonts;