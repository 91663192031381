import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingChronicTendonitisWithPhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Managing Chronic Tendonitis</h1>
                    <h2>Explore physiotherapy's role in chronic tendonitis management at Ramp Physio and Fitness, where our experts provide tailored treatments to alleviate pain and restore function.</h2>
                    <p>Chronic tendonitis can be a persistent challenge, but with the right approach, it's possible to manage symptoms and regain an active lifestyle. Ramp Physio and Fitness specializes in personalized physiotherapy strategies to address chronic tendonitis, offering relief and rehabilitation to those affected. In this comprehensive guide, we'll discuss the role of physiotherapy in managing chronic tendonitis and how our services can help you achieve long-term wellness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Chronic Tendonitis</h1>
                        <p>Chronic tendonitis, also known as tendinopathy, is the long-term inflammation or irritation of a tendon. This condition can result from repetitive strain or acute injury and is often experienced by athletes and individuals with physically demanding lifestyles. Symptoms include pain, swelling, and reduced range of motion. Physiotherapy plays a crucial role in managing these symptoms by promoting healing and preventing further injury.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Treatment Options</h1>
                    <p>Physiotherapy offers a variety of treatment options for chronic tendonitis, including manual therapy, exercise programs, and education on activity modification. Our physiotherapists at Ramp Physio and Fitness utilize evidence-based techniques to reduce pain and improve function. These may include:</p>
                    <ul>
                        <li>Soft tissue massage</li>
                        <li>Ultrasound therapy</li>
                        <li>Shockwave therapy</li>
                        <li>Strength and conditioning exercises</li>
                        <li>Joint mobilization</li>
                        <li>Eccentric loading exercises</li>
                        <li>Load management strategies</li>
                        <li>Education on tendon care</li>
                        <li>Taping and bracing</li>
                        <li>Laser therapy</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Benefits of Exercise in Tendonitis</h1>
                    <p>Exercise is a cornerstone of chronic tendonitis management. At Ramp Physio and Fitness, our therapists design exercise programs that specifically target the affected tendon, promoting healing and preventing future injuries. Benefits of exercise for tendonitis include:</p>
                    <ul>
                        <li>Improved tendon strength</li>
                        <li>Increased collagen production</li>
                        <li>Enhanced flexibility and range of motion</li>
                        <li>Reduced inflammation</li>
                        <li>Better load distribution</li>
                        <li>Decreased pain levels</li>
                    </ul>
                    <p>Each program is tailored to the individual's needs, ensuring optimal recovery and performance enhancement.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Preventing Tendonitis Recurrence</h1>
                    <p>Preventing the recurrence of chronic tendonitis is just as important as treating it. Our team at Ramp Physio and Fitness provides guidance on lifestyle modifications and ergonomic adjustments to reduce the risk of re-injury. This includes:</p>
                    <ul>
                        <li>Proper warm-up and cool-down techniques</li>
                        <li>Corrective exercises for muscle imbalances</li>
                        <li>Education on proper technique and posture</li>
                        <li>Advice on suitable footwear and equipment</li>
                        <li>Strategies for gradual return to activity</li>
                    </ul>
                    <p>By implementing these preventive measures, patients can maintain tendon health and continue enjoying their daily activities without pain.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingChronicTendonitisWithPhysiotherapy;