import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RehabilitationAfterKneeReplacementSurgery = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Mobility Post-Knee Replacement</h1>
                    <h2>Embark on a journey to full recovery and regain your mobility with Ramp Physio and Fitness's comprehensive rehabilitation program after knee replacement surgery.</h2>
                    <p>Undergoing knee replacement surgery is a significant step towards alleviating pain and improving the quality of life for those suffering from severe knee problems. At Ramp Physio and Fitness, we understand the challenges that come with post-operative recovery, and we are here to guide you through a tailored rehabilitation program designed to restore your knee function and help you return to your daily activities with confidence.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Rehabilitation: A Key to Success</h1>
                        <p>Rehabilitation after knee replacement surgery is not just about recovery; it's about retraining your body to move correctly with your new knee. Our expert physiotherapists at Ramp Physio and Fitness employ evidence-based techniques to assist you in regaining strength, flexibility, and endurance. We focus on personalized care plans that cater to your specific needs and goals, ensuring a successful return to an active lifestyle.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Phases of Knee Rehabilitation</h1>
                    <p>The road to recovery is divided into distinct phases, each playing a crucial role in the overall success of your rehabilitation. At Ramp Physio and Fitness, we guide you through each phase, from initial post-operative care to advanced strengthening exercises. Our program is designed to ensure that you progress safely and effectively, minimizing the risk of complications and maximizing your recovery potential.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Customized Care for Optimal Results</h1>
                    <p>Every individual's journey after knee replacement surgery is unique. That's why our rehabilitation program is customized to fit your personal circumstances, including your health status, activity level, and personal goals. We work closely with you to develop a rehabilitation plan that not only focuses on your recovery but also on preventing future knee problems.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Empowering Patients Through Education</h1>
                    <p>Education is a vital component of rehabilitation. At Ramp Physio and Fitness, we empower our patients with knowledge about their knee replacement, the rehabilitation process, and strategies for managing pain and swelling. Understanding the 'why' behind each exercise and precaution helps patients become active participants in their recovery, leading to better outcomes and a greater sense of control over their health.</p>
                    <br></br>
                    <p>
                        In conclusion, rehabilitation after knee replacement surgery is a critical component of the healing process. At Ramp Physio and Fitness, we are dedicated to providing the highest quality of care, support, and education to ensure you achieve the best possible results. Our team is committed to helping you regain your mobility and return to the activities you love. Contact us today to learn more about our rehabilitation program and take the first step towards a successful recovery.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RehabilitationAfterKneeReplacementSurgery;