import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForSportsInjuries = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Sports Injury Recovery</h1>
                    <h2>Unlock the power of physiotherapy treatment and exercises tailored for sports injuries at Ramp Physio and Fitness.</h2>
                    <p>Sports injuries can be a major hurdle for athletes and fitness enthusiasts. That's why at Ramp Physio and Fitness, we offer specialized physiotherapy treatments and exercise regimens designed to address the unique demands of sports-related injuries. Our expert team is dedicated to helping you recover effectively and safely, ensuring you get back to your peak performance. Join us as we delve into the world of sports injury rehabilitation and discover how our services can aid your journey to recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Sports Injury Assessment</h1>
                        <p>Every sports injury is unique, and so is every athlete. At Ramp Physio and Fitness, we start with a detailed assessment to understand the specifics of your injury. This involves analyzing your movement patterns, identifying the cause of the injury, and setting realistic goals for your recovery.</p>

                        <p>Our assessment serves as the blueprint for your personalized treatment plan, which includes a combination of hands-on therapy, guided exercises, and advice on injury prevention. By thoroughly understanding your condition, we can tailor a rehabilitation program that aligns with your sport and personal objectives.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Assessment" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Manual Therapy" />
                </div>
                <div className="service-intro-content2">
                    <h1>Manual Therapy and Mobilization</h1>
                    <p>Manual therapy is a cornerstone of sports injury rehabilitation. Our physiotherapists employ techniques such as soft tissue massage, joint mobilization, and stretching to alleviate pain, reduce swelling, and improve range of motion.</p>

                    <p>These hands-on treatments are complemented by specific exercises that target the affected area, promoting healing and preventing further injury. Our approach ensures that you receive comprehensive care that addresses both immediate symptoms and long-term recovery.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Ramp Physio and Fitness Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strength and Conditioning</h1>
                    <p>Building strength is vital for both recovery and prevention of sports injuries. Our exercise programs are designed to strengthen the muscles surrounding the injured area, which can help support and stabilize the affected joints.</p>

                    <p>From isometric exercises to dynamic movements, we guide you through a progression of activities that are intended to restore your strength safely and effectively. Our gym facilities are equipped with state-of-the-art equipment to support your rehabilitation and fitness journey.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Strength Training" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Conditioning" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Functional Training and Recovery</h1>
                        <p>Functional training plays a significant role in the rehabilitation of sports injuries. Our goal is to help you regain the ability to perform the movements and activities that are specific to your sport or daily life.</p>
                        <p>By incorporating exercises that mimic real-life activities, we facilitate a recovery that not only heals the injury but also prepares your body to handle the demands of your sport. This approach ensures that once you return to action, you do so with confidence and reduced risk of re-injury.</p>

                        <br></br>
                        <p>
                            At Ramp Physio and Fitness, we are committed to your full recovery and long-term well-being. Our team of physiotherapists is ready to support you with expert care and a personalized approach to sports injury rehabilitation. Let us help you overcome your injury and get back to doing what you love most.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForSportsInjuries;