import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import agedCareLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForAgedCarePhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Seniors with Physiotherapy</h1>
                    <h2>Explore the transformative impact of physiotherapy treatment and exercises on the well-being of aged care residents at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we specialize in providing comprehensive physiotherapy services tailored to the unique needs of seniors. Our approach focuses on enhancing the quality of life for aged care residents through personalized treatment plans and functional exercises that promote mobility, independence, and overall health. Join us as we delve into the essentials of aged care physiotherapy and how our dedicated team can support the elderly in achieving their health and fitness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Addressing the Challenges of Aging</h1>
                        <p>As we age, our bodies undergo various changes that can affect mobility, strength, and balance. Aged care physiotherapy aims to address these challenges by providing targeted treatments and exercises that help seniors maintain and improve their physical capabilities. Our physiotherapists at Ramp Physio and Fitness are skilled in assessing and managing age-related conditions such as arthritis, osteoporosis, and balance disorders.</p>

                        <p>The initial stage of treatment typically involves a thorough evaluation to understand each resident's specific needs and limitations. This assessment forms the basis for a customized physiotherapy program that may include pain management, fall prevention strategies, and exercises to enhance joint flexibility and muscle strength.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Enhancing Mobility and Independence</h1>
                    <p>Maintaining mobility is crucial for seniors to live independently and perform daily activities. Our physiotherapy programs place a strong emphasis on exercises that improve gait, coordination, and functional movements. By incorporating exercises such as seated leg lifts, standing calf raises, and gentle stretches, we help aged care residents enhance their mobility and reduce the risk of falls.</p>

                    <p>We also utilize therapeutic modalities such as manual therapy and soft tissue work to alleviate discomfort and improve range of motion. Our physiotherapists work closely with residents to ensure exercises are performed safely and effectively, with the goal of fostering greater independence and quality of life.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={agedCareLogo} alt="Aged Care Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Supporting Mental and Social Well-being</h1>
                    <p>Physiotherapy extends beyond physical treatment — it also plays a vital role in supporting the mental and social well-being of seniors. Our team encourages social interaction during therapy sessions and fosters a positive environment where residents can engage with others, share experiences, and build friendships.</p>

                    <p>By promoting an active lifestyle and facilitating group exercises, we contribute to the emotional health of aged care residents, helping to combat feelings of isolation and depression that can often accompany aging.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Pain Management and Rehabilitation</h1>
                        <p>Chronic pain is a common issue among the elderly, often hindering their ability to engage in physical activity. Our physiotherapists employ various pain management techniques, including therapeutic exercises, electrotherapy, and education on pain coping strategies, to help residents manage discomfort and improve their function.</p>
                        <p>Rehabilitation is also a key component of our services, especially for those recovering from surgery or injury. We provide individualized programs aimed at restoring strength, endurance, and confidence, enabling seniors to return to their daily routines with ease.</p>

                        <br></br>
                        <p>
                            In conclusion, aged care physiotherapy at Ramp Physio and Fitness is a multidimensional approach to senior health care. Our experienced physiotherapists are committed to enhancing the lives of aged care residents through tailored treatments and compassionate care. We believe in empowering seniors to lead active, fulfilled lives and are dedicated to supporting them every step of the way.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForAgedCarePhysiotherapy;