import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TheRoleOfNutritionInFitness = () => {
    return (
        <div>
            {/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Fueling Fitness: The Role of Nutrition</h1>
                    <h2>Uncover the synergy between nutrition and fitness, and how Ramp Physio and Fitness integrates dietary strategies to optimize your health and performance.</h2>
                    <p>Nutrition is not just about eating; it's about fueling your body for optimal performance and recovery. At Ramp Physio and Fitness, we recognize the pivotal role that nutrition plays in achieving fitness goals. Whether you're looking to boost your energy, improve your workouts, or recover faster, understanding the connection between diet and exercise is key. This comprehensive guide delves into the science of nutrition in fitness, offering insights and practical tips to help you nourish your body effectively.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Nutrition and Fitness" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Nutrition Fundamentals</h1>
                        <p>Understanding the basics of nutrition is essential for anyone looking to improve their fitness. Macronutrients (carbohydrates, proteins, and fats) and micronutrients (vitamins and minerals) play distinct roles in energy production, muscle growth, and overall health. Balancing these nutrients is crucial for optimal performance. Here are key points to consider:</p>
                        <ul>
                            <li>Carbohydrates are the primary energy source for high-intensity workouts.</li>
                            <li>Proteins are vital for muscle repair and growth.</li>
                            <li>Fats are necessary for long-term energy and hormone production.</li>
                            <li>Vitamins and minerals support a range of bodily functions, including metabolism and recovery.</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Nutrition Plans</h1>
                    <p>At Ramp Physio and Fitness, we believe in personalized nutrition plans that cater to your individual needs and fitness objectives. A one-size-fits-all approach doesn't work when it comes to diet. Here's how we tailor your nutrition plan:</p>
                    <ul>
                        <li>Assessment of your current dietary habits and lifestyle.</li>
                        <li>Consideration of your specific fitness goals, such as weight loss or muscle gain.</li>
                        <li>Incorporation of your food preferences and any dietary restrictions.</li>
                        <li>Continuous monitoring and adjustment based on your progress and feedback.</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Hydration and Performance</h1>
                    <p>Hydration is often overlooked in discussions of nutrition and fitness, yet it's a critical factor for peak performance. Water regulates body temperature, lubricates joints, and helps transport nutrients for energy and health. Here are hydration tips for your fitness routine:</p>
                    <ul>
                        <li>Drink water throughout the day, not just during workouts.</li>
                        <li>Monitor your urine color as an indicator of hydration levels.</li>
                        <li>Consider electrolyte replacement during long or intense exercise sessions.</li>
                        <li>Listen to your thirst cues and respond appropriately.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Timing Your Nutrition</h1>
                        <p>The timing of your meals and snacks can significantly impact your fitness results. Here's how to optimize your nutrition timing:</p>
                        <ul>
                            <li>Eat a balanced meal 2-3 hours before exercising to fuel your workout.</li>
                            <li>Consume a small, carbohydrate-rich snack 30 minutes before if you need an energy boost.</li>
                            <li>Post-workout, have a meal or snack with protein and carbs to aid recovery.</li>
                            <li>Consider your personal digestive comfort and adjust timing as needed.</li>
                        </ul>
                        <br></br>
                        <p>
                            In conclusion, integrating nutrition into your fitness regime is not just about eating right; it's about creating a sustainable, balanced approach that supports your health and fitness goals. At Ramp Physio and Fitness, we're dedicated to providing you with the knowledge and resources to make informed choices about your diet and exercise. Book your consultation today and take the first step towards a healthier, more vibrant you.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TheRoleOfNutritionInFitness;