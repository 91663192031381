import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/Ramp_cover.webp';
import otherinfoimg from '../assets/RAMP Physio Icon.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
import { Link } from 'react-router-dom';
const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-P52MNG23ES"></script>
        <script>
          {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-P52MNG23ES');
                    `}
        </script>
      </Helmet>
      <div className="service-detail-content">
        <div className="service-detail-text">
          <br /><br />
          <h1>Privacy Policy For Ramp Physio And Fitness</h1>
          <p>
            At Ramp Physio and Fitness, one of our main priorities is the privacy of our visitors. This Privacy Policy document outlines the types of information collected and recorded by Ramp Physio and Fitness and how we use it.
          </p>

          <p>
            If you have additional questions or require more information about our Privacy Policy, please do not hesitate to contact us.
          </p>
          <p>
            We operate in 208 Parramatta Road, Homebush, and our services allow us to bring optimal health care to the local community and patients of all ages, conditions, and lifestyles.
            <br /><br />
            Sign up with your email address to receive news and updates.
          </p>
          <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
        </div>
        <div className="service-detail-image-container">
          <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
        </div>
      </div>
      <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
        <div className="service-intro-container1">
          <div className="service-intro-content1">

            <h1>Log Files</h1>
            <p>
              Ramp Physio and Fitness follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.<br /><br />
            </p>

            <h1>Cookies and Web Beacons</h1>
            <p>
              Like any other website, Ramp Physio and Fitness uses "cookies". These cookies are used to store information including visitors' preferences and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.<br /><br />
            </p>

            <h1>Google DoubleClick DART Cookie</h1>
            <p>
              Google is one of the third-party vendors on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.rampphysioandfitness.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">Google Ad Privacy Policy</a>.
            </p>
          </div>
          <div className="intro-image">
            <img src="../assets/bike.webp" alt="Exercise" />
          </div>
        </div>
      </SkewedContainer>
      <div className="service-intro-container2">
        <div className="intro-image">
          <img src="../assets/dumbbells.webp" alt="Exercise" />
        </div>
        <div className="service-intro-content2">
          <h1>Privacy Policies</h1>
          <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Ramp Physio and Fitness.</p>
          <p>
            Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Ramp Physio and Fitness, which are sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
          </p>
          <p>
            Note that Ramp Physio and Fitness has no access to or control over these cookies that are used by third-party advertisers.<br /><br />
          </p>

          <h1>Third Party Privacy Policies</h1>
          <p>
            Ramp Physio and Fitness's Privacy Policy does not apply to other advertisers or websites. Thus, we advise you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
          </p>
          <p>
            You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. <a href="https://www.cookiesandyou.com/" target="_blank" rel="noopener noreferrer">What Are Cookies?</a>
          </p>

        </div>
      </div>

      <div className="service-intro-container4">
        <div className="service-intro-content4">
          <h1>Children's Information</h1>
          <p>
            Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
          </p>
          <p>
            Ramp Physio and Fitness does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.<br /><br />
          </p>

          <h1>Online Privacy Policy Only</h1>
          <p>
            This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Ramp Physio and Fitness. This policy is not applicable to any information collected offline or via channels other than this website.<br /><br />
          </p>
          <h1>Consent</h1>
          <Link to="/australianconsumerlaw" className="cosumerlaw-link">
            <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions. Find out more about Australian Consumer Law (ACL) for Fitness Services.</p>
          </Link>
        </div>
        <div className="intro-image">
          <img src="../assets/Workouts.webp" alt="Exercise" />
        </div>
      </div>
      <SkewedContainer top="left" bgColor="white" noMargin>
        <HealthSection />
      </SkewedContainer>
      <TestimonialSection />
        <div className="service-intro-container3">
          <img src={otherinfoimg} alt="RAMP" className="service-intro-logo" />
          <p className="service-intro-text">
            Ramp Physio and Fitness
          </p>
        </div>
    </div>

  );
};

export default PrivacyPolicy;
