import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Trainingforobstaclecourseracesegtoughmudder = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Conquer Obstacle Course Races</h1>
                    <h2>Unleash your inner warrior with our tailored Tough Mudder training programs at Ramp Physio and Fitness. Maximize your potential and tackle every obstacle with confidence.</h2>
                    <p>Obstacle course races like Tough Mudder offer a thrilling mix of endurance, strength, and mental grit. At Ramp Physio and Fitness, we craft specialized training regimens to prepare you for the mud-soaked courses and challenging obstacles. Whether you're a first-timer or a seasoned mudder, our expert team is here to guide you through a comprehensive training plan that ensures you cross the finish line with a sense of achievement. Let's dive into the strategies that will help you dominate your next obstacle course race.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Mastering the Mud Run</h1>
                        <p>Training for a Tough Mudder isn't just about running; it's about preparing your body and mind for the unpredictable. Our programs emphasize a blend of cardiovascular fitness, strength training, and obstacle-specific techniques. Here are ten key elements we focus on to ensure you're mud-run ready:</p>
                        <ul>
                            <li>Endurance running for cardiovascular stamina</li>
                            <li>Functional strength for obstacle maneuvering</li>
                            <li>Grip strength exercises for hanging challenges</li>
                            <li>Agility drills for quick directional changes</li>
                            <li>Balance training to navigate uneven surfaces</li>
                            <li>Core workouts for overall stability</li>
                            <li>Mental toughness seminars to build resilience</li>
                            <li>Nutrition plans for optimal performance</li>
                            <li>Recovery techniques to prevent injury</li>
                            <li>Community runs to simulate race conditions</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Functional Training Fundamentals</h1>
                    <p>Our Tough Mudder training goes beyond traditional workouts, focusing on functional movements that mimic the demands of obstacle courses. We integrate exercises like deadlifts, pull-ups, and farmer's walks to build the strength and endurance you'll need on race day. Our trainers provide personalized feedback to ensure your technique is spot on, reducing the risk of injury and increasing your efficiency over obstacles.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Building Mental Grit</h1>
                    <p>Obstacle course races test more than just physical strength; they challenge your mental fortitude. Our training includes mental toughness workshops that help you develop the resilience needed to overcome any obstacle. We cover strategies for staying focused, maintaining motivation, and pushing through the toughest parts of the race. With Ramp Physio and Fitness, you'll build the mental armor to face any challenge that comes your way.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Embracing the Power of Teamwork</h1>
                        <p>Teamwork is at the heart of every Tough Mudder event. Our group training sessions not only prepare you physically but also foster the spirit of camaraderie that's essential for success on the course. You'll learn how to work with others to tackle obstacles that require cooperation, and you'll build relationships that extend beyond the finish line. Together, we're stronger.</p>
                        <br></br>
                        <p>
                            In conclusion, Ramp Physio and Fitness is your ultimate training destination for obstacle course races like Tough Mudder. Our expertly designed programs, state-of-the-art facilities, and supportive community provide all the resources you need to succeed. Whether you aim to conquer the course for the first time or set a new personal best, we're here to help you achieve your goals. Join us and experience the transformative power of obstacle course training.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Trainingforobstaclecourseracesegtoughmudder;