import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TheRoleOfMentalHealthInPhysicalFitness = () => {
    return (
        <div>
            <Helmet>
                <title>The Role of Mental Health in Physical Fitness | Ramp Physio and Fitness</title>
                <meta name="description" content="Understand the crucial connection between mental health and physical fitness, and how Ramp Physio and Fitness supports your holistic well-being." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mind and Muscle: A Unified Approach</h1>
                    <h2>Unlock the synergy between mental health and physical fitness with Ramp Physio and Fitness, where we bridge the gap for complete wellness.</h2>
                    <p>Mental health is the cornerstone of physical fitness. At Ramp Physio and Fitness, we recognize the profound connection between a healthy mind and a fit body. This article explores the integral role mental health plays in achieving and maintaining peak physical fitness. From reducing stress to improving motivation and performance, we'll delve into how mental well-being can enhance your fitness journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Mental Health: The Fitness Multiplier</h1>
                        <p>Embracing mental health as part of your fitness routine can significantly enhance the benefits of exercise. Here are 10 ways mental well-being contributes to physical fitness:</p>
                        <ul>
                            <li>Boosts workout motivation and adherence</li>
                            <li>Enhances concentration and focus during exercise</li>
                            <li>Reduces the perception of fatigue</li>
                            <li>Improves stress resilience and coping strategies</li>
                            <li>Increases energy levels and vitality</li>
                            <li>Strengthens the immune system</li>
                            <li>Promotes better sleep quality</li>
                            <li>Facilitates faster recovery from injury</li>
                            <li>Heightens enjoyment of physical activity</li>
                            <li>Supports a positive body image and self-esteem</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercise: A Mental Health Ally</h1>
                    <p>Regular physical activity is a powerful tool for mental health management. Here are key themes linking exercise to mental well-being:</p>
                    <ul>
                        <li>Alleviates symptoms of depression and anxiety</li>
                        <li>Acts as a natural mood enhancer</li>
                        <li>Improves cognitive function and memory</li>
                        <li>Reduces stress and helps manage PTSD</li>
                        <li>Builds resilience and self-efficacy</li>
                        <li>Encourages social interaction and community building</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating Mental Health in Fitness</h1>
                    <p>At Ramp Physio and Fitness, we incorporate mental health strategies into our fitness programs. Here's how we integrate the two for maximum benefit:</p>
                    <ul>
                        <li>Including mindfulness and relaxation techniques in workouts</li>
                        <li>Offering support groups and community events</li>
                        <li>Providing education on the psychological benefits of exercise</li>
                        <li>Encouraging goal-setting and positive reinforcement</li>
                        <li>Designing personalized programs that cater to mental health needs</li>
                        <li>Creating a supportive and non-judgmental environment</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Mental Fitness Plans</h1>
                    <p>Every individual's mental health journey is unique. That's why we offer personalized mental fitness plans to complement our physical training programs. We work with you to understand your mental health goals and craft a plan that supports both your mind and body. From one-on-one counseling to group fitness classes with a mental health focus, we are dedicated to your holistic well-being.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TheRoleOfMentalHealthInPhysicalFitness;