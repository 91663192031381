import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Nutritionmythsdebunkedfactsforbetterfitness = () => {
    return (
        <div>
            <Helmet>
                <title>Nutrition Myths Debunked: Facts for Better Fitness | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the truth behind common nutrition myths to enhance your fitness journey with Ramp Physio and Fitness. Our experts set the record straight for better health and performance." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Nutrition Myths Debunked</h1>
                    <h2>Uncover the truth behind common nutrition fallacies to empower your fitness journey at Ramp Physio and Fitness.</h2>
                    <p>When it comes to nutrition and fitness, misinformation can be a significant barrier to achieving your health goals. At Ramp Physio and Fitness, we're committed to providing you with evidence-based advice to dispel the myths and enhance your well-being. In this comprehensive guide, we'll tackle prevalent misconceptions about nutrition and exercise, so you can make informed decisions that truly benefit your fitness regimen.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Nutrition Myths" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Busting Nutrition Fables</h1>
                        <p>It's time to separate fact from fiction when it comes to what you eat and how it affects your fitness. Let's debunk some of the most persistent nutrition myths with solid evidence to guide you towards better dietary choices and workout results.</p>
                        <ul>
                            <li>Carbs are not the enemy; they're a vital energy source.</li>
                            <li>Fats won't make you fat; balance and type are key.</li>
                            <li>Protein is crucial, but more is not always better.</li>
                            <li>Detox diets aren't necessary for your body to cleanse.</li>
                            <li>Supplements can't replace a balanced diet.</li>
                            <li>Eating late at night doesn't guarantee weight gain.</li>
                            <li>Gluten-free diets aren't necessarily healthier.</li>
                            <li>Organic foods have benefits but aren't always superior.</li>
                            <li>Skipping meals won't help you lose weight effectively.</li>
                            <li>Hydration is about more than just drinking water.</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Key Nutrition Concepts</h1>
                    <p>Understanding the core principles of nutrition is essential for making choices that align with your fitness goals. Here are six key ideas to keep in mind:</p>
                    <ol>
                        <li>Energy balance determines weight gain or loss.</li>
                        <li>Macronutrient ratios should be tailored to your activity level.</li>
                        <li>Micronutrients play a crucial role in overall health.</li>
                        <li>Meal timing can affect workout performance and recovery.</li>
                        <li>Food quality impacts your health beyond just calories.</li>
                        <li>Consistency and moderation are more effective than extreme diets.</li>
                    </ol>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Get Started
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>The Role of Diet in Fitness</h1>
                    <p>Nutrition plays a pivotal role in achieving fitness goals, whether you're looking to build muscle, lose weight, or enhance athletic performance. A balanced diet supports workout recovery, energy levels, and overall health.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Maximize Your Fitness Results</h1>
                    <p>Integrating sound nutritional practices with your fitness routine is the key to maximizing results. At Ramp Physio and Fitness, we can help you navigate the complexities of nutrition and exercise, ensuring you're on the right path to reaching your health and fitness objectives.</p>
                    <br></br>
                    <p>
                        In summary, understanding the truth about nutrition can significantly impact your fitness journey. By debunking common myths, you're equipped to make healthier choices and achieve the results you desire. Let Ramp Physio and Fitness be your guide to better fitness through accurate nutrition information and tailored training programs.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Nutritionmythsdebunkedfactsforbetterfitness;