import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForWhatWeTreat = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Physiotherapy Treatment: Path to Recovery</h1>
                    <h2>Discover the diverse physiotherapy treatments and exercises tailored for a range of conditions at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we are committed to providing comprehensive and individualized physiotherapy treatments and exercises designed to address a variety of conditions. Our expert physiotherapists employ evidence-based techniques to facilitate healing, restore function, and prevent future injuries. In this extensive guide, we'll delve into the world of physiotherapy and explore the specific exercises and treatments that cater to the unique needs of our clients.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Physiotherapy</h1>
                        <p>Physiotherapy is a health care profession focused on the assessment, diagnosis, treatment, and prevention of various physical injuries and disabilities. It involves a range of treatments, including manual therapies, exercise programs, and modalities such as electrotherapy. The goal is to enhance or restore function of multiple body systems, promote mobility and strength, and improve the quality of life for individuals.</p>

                        <p>At Ramp Physio and Fitness, our initial assessments are thorough, ensuring that we understand the full scope of your condition. Following this, we create a customized treatment plan that addresses your specific needs and goals. Our treatments are grounded in the latest clinical evidence and are delivered with the utmost care and professionalism.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Understanding Physiotherapy" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Manual Therapy" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Manual Therapy Techniques</h1>
                        <p>Manual therapy is a cornerstone of physiotherapy treatment, involving hands-on techniques to manipulate muscles and joints. These methods aim to reduce pain, improve circulation, and increase range of motion. At Ramp Physio and Fitness, our therapists are skilled in a variety of manual therapy techniques, including:</p>
                        <p>
                            - Joint mobilization<br></br>
                            - Soft tissue release<br></br>
                            - Trigger point therapy<br></br>
                            - Myofascial release<br></br>
                            - Manual lymphatic drainage<br></br>
                            - Stretching and flexibility exercises<br></br>
                            - Therapeutic massage<br></br>
                            - Dry needling<br></br>
                            - Cupping therapy<br></br>
                            - Traction<br></br>
                        </p>
                        <p>These techniques are tailored to individual client needs, ensuring a focused approach to recovery and rehabilitation.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Physiotherapy Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise-Based Rehabilitation</h1>
                    <p>Exercise is a vital component of physiotherapy, as it aids in restoring strength, endurance, coordination, and balance. At Ramp Physio and Fitness, we prescribe exercises that are specifically designed to target the areas affected by injury or dysfunction. These exercises may include:</p>
                    <p>
                        - Range of motion exercises<br></br>
                        - Strengthening exercises<br></br>
                        - Core stability workouts<br></br>
                        - Balance and proprioception drills<br></br>
                        - Cardiovascular conditioning<br></br>
                        - Functional movement training<br></br>
                        - Postural correction<br></br>
                        - Gait training<br></br>
                        - Plyometric exercises<br></br>
                        - Aquatic therapy<br></br>
                    </p>
                    <p>Our exercise programs are progressive and adapted over time to meet the evolving needs of our clients as they journey through their rehabilitation.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise-Based Rehabilitation" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Advanced Physiotherapy Equipment" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Physiotherapy Equipment</h1>
                    <p>Our clinic is equipped with state-of-the-art physiotherapy equipment to ensure the most effective treatment outcomes. We utilize advanced modalities such as:</p>
                    <p>
                        - Ultrasound therapy<br></br>
                        - Interferential current (IFC)<br></br>
                        - Transcutaneous electrical nerve stimulation (TENS)<br></br>
                        - Laser therapy<br></br>
                        - Shockwave therapy<br></br>
                        - Heat and cold therapy<br></br>
                        - Kinesiology taping<br></br>
                        - Electrical muscle stimulation (EMS)<br></br>
                        - Real-time ultrasound imaging (RTUI)<br></br>
                    </p>
                    <p>These modalities complement our manual therapies and exercise programs, providing a multifaceted approach to treatment.</p>
                </div>
            </div>
            </SkewedContainer>
            
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForWhatWeTreat;