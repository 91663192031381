import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForRheumatoidArthritis = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Managing Rheumatoid Arthritis with Physiotherapy</h1>
                    <h2>Explore the transformative benefits of physiotherapy for rheumatoid arthritis at Ramp Physio and Fitness, where our expert care empowers you to regain mobility and manage pain effectively.</h2>
                    <p>Rheumatoid arthritis (RA) is a chronic inflammatory disorder that not only affects your joints but can also impact your overall quality of life. At Ramp Physio and Fitness, we understand the challenges RA presents, and we're dedicated to providing personalized physiotherapy programs to help manage symptoms and enhance daily living. In this article, we will discuss the role of physiotherapy in RA management, the benefits it offers, and how our specialized approach can make a significant difference in your health journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Personalized Therapies for RA Relief</h1>
                        <p>Every individual with rheumatoid arthritis has a unique experience with the disease. That's why our physiotherapy treatments are tailored to meet your specific needs. From pain relief techniques like manual therapy and electrotherapy to strength training and joint protection strategies, our physiotherapists design a comprehensive plan focused on reducing inflammation, preserving joint function, and improving your overall well-being.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Evidence-Based RA Management</h1>
                    <p>Our approach to managing rheumatoid arthritis is grounded in the latest research and evidence-based practices. Studies have shown that physiotherapy can significantly improve the quality of life for individuals with RA by enhancing physical function, reducing pain, and increasing strength and endurance. Our programs are designed to incorporate these findings, ensuring you receive the most effective care possible.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Comprehensive RA Treatment Techniques</h1>
                    <p>Our treatment techniques for rheumatoid arthritis are diverse and comprehensive, addressing the multifaceted nature of the disease. Here are 10 examples of how physiotherapy can benefit individuals with RA:</p>
                    <ul>
                        <li>Joint mobilization to improve range of motion</li>
                        <li>Soft tissue massage to reduce muscle tension</li>
                        <li>Therapeutic exercises to strengthen muscles</li>
                        <li>Hydrotherapy for gentle resistance training</li>
                        <li>Pain management through TENS and ultrasound</li>
                        <li>Ergonomic advice for joint protection</li>
                        <li>Gait re-education to improve walking</li>
                        <li>Postural education to prevent joint stress</li>
                        <li>Assistive device training for daily activities</li>
                        <li>Education on RA management and self-help techniques</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>RA and Lifestyle: A Holistic Approach</h1>
                        <p>Managing rheumatoid arthritis extends beyond physical treatment. It encompasses a holistic approach that includes lifestyle modifications, stress management, and nutritional guidance. At Ramp Physio and Fitness, we collaborate with you to create a balanced lifestyle plan that supports your physiotherapy regimen and contributes to long-term health and mobility.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForRheumatoidArthritis;