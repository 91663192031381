import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RehabilitationAfterAnkleFracture = () => {
    return (
        <div>
            <Helmet>
                <title>Rehabilitation After Ankle Fracture | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert guidance on rehabilitation after an ankle fracture. Discover a tailored recovery plan with Ramp Physio and Fitness to regain strength and mobility." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Comprehensive Ankle Fracture Rehabilitation</h1>
                    <h2>Embark on a tailored recovery journey after an ankle fracture with the skilled guidance of Ramp Physio and Fitness, located in Homebush, NSW.</h2>
                    <p>Ankle fractures can significantly impact your mobility and quality of life. At Ramp Physio and Fitness, we understand the complexities of recovering from such an injury. Our team provides expert physiotherapy and personalized rehabilitation plans to help you regain strength, flexibility, and function. In this article, we will explore the key components of a successful rehabilitation journey after an ankle fracture.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Ankle Rehabilitation" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Initial Post-Operative Care</h1>
                        <p>Following an ankle fracture, immediate and proper post-operative care is crucial. Our physiotherapists collaborate closely with your medical team to ensure a seamless transition from surgery to rehabilitation. Initially, the focus is on managing pain, reducing swelling, and protecting the ankle to foster an optimal healing environment.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Restoring Range of Motion</h1>
                    <p>Regaining ankle mobility is a key objective in the early stages of rehabilitation. Our therapists employ gentle, controlled exercises designed to restore range of motion without compromising the integrity of the healing tissue. These exercises are tailored to each individual's specific injury and recovery pace.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strengthening and Conditioning</h1>
                    <p>As your ankle heals, we focus on strengthening exercises that target not only the ankle but also the surrounding muscles. This approach helps improve overall stability and prepares you for a return to daily activities. Conditioning exercises further enhance endurance, ensuring your ankle can withstand the stresses of everyday use.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Balance and Proprioception Training</h1>
                    <p>Enhancing proprioception and balance is essential for preventing future injuries and ensuring a confident return to activities. Our programs include dynamic exercises that challenge your body's ability to sense its position and movement, which is vital for stabilizing the ankle.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Functional and Sport-Specific Training</h1>
                    <p>For athletes and active individuals, regaining the ability to perform sport-specific movements is a top priority. Our rehabilitation programs incorporate functional training that mimics the demands of your sport or activity, facilitating a safe and effective return to peak performance.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
            <div className="service-intro-container6">
                <div className="service-intro-content6">
                    <h1>Education and Prevention Strategies</h1>
                    <p>Understanding the mechanisms of injury and how to prevent recurrence is an integral part of our rehabilitation process. We provide education on proper techniques, footwear selection, and lifestyle modifications to safeguard against future ankle injuries.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container7">
                <div className="service-intro-content7">
                    <h1>Key Points in Ankle Fracture Rehab</h1>
                    <ul>
                        <li>Importance of early post-operative care</li>
                        <li>Customized exercises to restore range of motion</li>
                        <li>Strengthening the ankle and surrounding muscles</li>
                        <li>Conditioning for improved endurance</li>
                        <li>Balance and proprioception for injury prevention</li>
                        <li>Functional training for a return to sports</li>
                        <li>Education on injury prevention and proper care</li>
                        <li>Collaboration with medical professionals</li>
                        <li>Personalized recovery plans</li>
                        <li>Post-rehabilitation support and guidance</li>
                    </ul>
                </div>
            </div>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RehabilitationAfterAnkleFracture;