import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const Ndisphysiotherapyforbardetbiedisyndrome = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Bardet-Biedl Syndrome - Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives with NDIS Physiotherapy for Bardet-Biedl Syndrome</h1>
                    <h2>Discover tailored physiotherapy under NDIS for individuals with Bardet-Biedl Syndrome at Ramp Physio and Fitness, fostering independence and well-being.</h2>
                    <p>Bardet-Biedl Syndrome (BBS) is a complex genetic disorder that can significantly impact an individual's quality of life. At Ramp Physio and Fitness, we provide specialized NDIS physiotherapy services designed to support those living with BBS. Our approach is holistic and patient-centered, focusing on enhancing mobility, function, and independence. In this comprehensive guide, we will delve into the ways physiotherapy can be tailored to meet the unique needs of individuals with Bardet-Biedl Syndrome.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding BBS and Physiotherapy</h1>
                        <p>Bardet-Biedl Syndrome presents with a variety of symptoms, including vision loss, obesity, kidney dysfunction, and developmental delays. Physiotherapy plays a crucial role in managing these symptoms by promoting physical activity and preventing secondary conditions. Our experienced physiotherapists at Ramp Physio and Fitness are well-versed in the complexities of BBS, offering interventions that are both effective and compassionate.</p>

                        <p>Our NDIS physiotherapy services are designed to empower our clients, providing them with the tools and knowledge necessary to improve their functional abilities. We work closely with each individual, their families, and their support network to create a comprehensive and personalized plan that aligns with their NDIS goals.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>Customized Therapeutic Interventions</h1>
                        <p>Every individual with Bardet-Biedl Syndrome is unique, and so are their physiotherapy needs. Our therapists specialize in creating customized therapeutic interventions that address the specific challenges faced by our clients. These may include:</p>
                        <div className="service-intro-points">
                            <p>• Strength and conditioning exercises to combat muscle weakness</p>
                            <p>• Balance and coordination activities to improve proprioception</p>
                            <p>• Cardiovascular training to aid in weight management</p>
                            <p>• Stretching routines to enhance flexibility and reduce stiffness</p>
                            <p>• Functional mobility training to promote independence in daily activities</p>
                            <p>• Hydrotherapy for low-impact exercise options</p>
                            <p>• Education on adaptive equipment and assistive technology</p>
                            <p>• Guidance on nutritional strategies to support overall health</p>
                            <p>• Pain management techniques to alleviate discomfort</p>
                            <p>• Collaborative goal-setting to ensure motivation and engagement</p>
                        </div>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container3">
                <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                <p className="service-intro-text">
                    Ramp Physio and Fitness
                </p>
                <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="servicedetail-book-now"
                >
                    Book Now
                </a>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="service-intro-content2">
                        <h1>Integrating Health and Fitness</h1>
                        <p>At Ramp Physio and Fitness, we believe in integrating health and fitness into our NDIS physiotherapy services. This integration not only aids in the physical rehabilitation of individuals with Bardet-Biedl Syndrome but also contributes to their emotional and social well-being. Our state-of-the-art gym facilities and experienced personal trainers provide a supportive environment for clients to engage in physical activity safely and effectively.</p>
                        <p>Our commitment extends beyond individual sessions, as we empower our clients with the knowledge and confidence to maintain an active lifestyle. We strive to create a community where individuals with BBS can thrive, fostering a sense of belonging and accomplishment.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Collaborative Care and Support</h1>
                    <p>Our approach to NDIS physiotherapy for Bardet-Biedl Syndrome is collaborative, involving a multidisciplinary team that works closely with each client. We liaise with other healthcare professionals, educators, and support coordinators to ensure a holistic approach to care. This multidisciplinary approach ensures that all aspects of the client's health and well-being are addressed, resulting in a more comprehensive and effective treatment plan.</p>

                    <p>At Ramp Physio and Fitness, we are committed to providing a welcoming and inclusive environment where clients feel valued and supported. Our team is dedicated to ongoing professional development to stay abreast of the latest research and best practices in physiotherapy for Bardet-Biedl Syndrome.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Ndisphysiotherapyforbardetbiedisyndrome;