import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForChronicPain = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Physiotherapy for Chronic Pain Management</h1>
                    <h2>Discover how physiotherapy treatment and exercises can alleviate chronic pain, tailored by the experts at Ramp Physio and Fitness.</h2>
                    <p>Chronic pain can be a debilitating condition, affecting every aspect of your life. At Ramp Physio and Fitness, we specialize in managing chronic pain through a combination of physiotherapy treatments and carefully selected exercises. Our approach is designed to not only reduce your pain levels but also to restore function and improve your quality of life. In this article, we will delve into the role of physiotherapy in chronic pain management and provide you with practical strategies to manage your condition effectively.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Chronic Pain</h1>
                        <p>Chronic pain is a complex and persistent condition that can arise from various sources, including injuries, diseases, or functional disorders. It is defined as pain that lasts longer than three months and persists beyond the usual course of an acute illness or healing of an injury. Physiotherapy plays a crucial role in the multidisciplinary treatment of chronic pain by addressing both the physical and psychological components of this condition.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Targeted Physiotherapy Interventions</h1>
                        <p>Our physiotherapists at Ramp Physio and Fitness employ a variety of interventions tailored to the individual needs of each person with chronic pain. These may include manual therapy techniques such as massage and joint mobilization, as well as the use of modalities like heat, cold, and electrical stimulation to reduce pain and inflammation.</p>
                        <p>One of the key elements of our treatment is therapeutic exercise. Exercise has been shown to be one of the most effective ways to improve pain tolerance and decrease the intensity of chronic pain. Our physiotherapists will work with you to develop an exercise program that is safe, effective, and personally suited to your condition and fitness level.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Strategies for Pain Relief</h1>
                    <p>Exercise is a cornerstone of chronic pain management. Here are ten exercise strategies that can be incorporated into your daily routine to help manage chronic pain:</p>
                    <p>
                        - Gradual strengthening exercises<br/>
                        - Low-impact aerobic activities<br/>
                        - Controlled stretching routines<br/>
                        - Postural correction exercises<br/>
                        - Stability and balance training<br/>
                        - Functional movement patterns<br/>
                        - Breathing techniques and relaxation exercises<br/>
                        - Joint protection strategies<br/>
                        - Pain modulation exercises<br/>
                        - Self-management education
                    </p>
                    <p>These exercises are aimed at improving flexibility, strength, and endurance, all of which are vital for managing chronic pain. Our physiotherapists will guide you through these exercises, ensuring they are performed correctly and safely.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Care and Support</h1>
                        <p>At Ramp Physio and Fitness, we believe in providing personalized care that addresses the unique challenges of each individual with chronic pain. Alongside physiotherapy treatments and exercises, we offer support and guidance on lifestyle modifications, ergonomics, and self-management techniques to empower you to take control of your pain and improve your overall well-being.</p>
                        <p>Our team is committed to working with you to develop a comprehensive management plan that incorporates all aspects of your health. We aim to not only alleviate your pain but also to enhance your physical function and support your return to the activities you enjoy.</p>

                        <br></br>
                        <p>
                            In conclusion, chronic pain requires a comprehensive and multidimensional approach. At Ramp Physio and Fitness, our expert team is dedicated to providing the highest quality of care through personalized physiotherapy treatments and exercise programs. We are committed to helping you manage your chronic pain and achieve a healthier, more active lifestyle. Contact us to book your appointment and take the first step towards effective pain management.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForChronicPain;