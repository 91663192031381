import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const AdaptingWorkoutsForInjuriesAndPhysicalLimitations = () => {
    return (
        <div>
            <Helmet>
                <title>Adapting Workouts for Injuries and Physical Limitations | Ramp Physio and Fitness</title>
                <meta name="description" content="Learn how to adapt workouts for injuries and physical limitations with expert guidance from Ramp Physio and Fitness. Achieve your fitness goals safely and effectively." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Adapting Workouts to Fit Your Needs</h1>
                    <h2>Explore tailored fitness solutions for individuals with injuries or physical limitations at Ramp Physio and Fitness, where your safety and progress are our top priorities.</h2>
                    <p>At Ramp Physio and Fitness, we understand that injuries and physical limitations can pose significant challenges to maintaining an active lifestyle. That's why we specialize in adapting workouts to meet the unique needs of each individual. Whether you're recovering from an injury, living with a disability, or managing a chronic condition, our expert team is here to design a fitness program that respects your body's capabilities while helping you achieve your health and fitness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Customized Exercise Programs</h1>
                        <p>Our approach to fitness is rooted in the philosophy that exercise should be accessible and beneficial for everyone. We craft customized exercise programs that consider your specific injuries or limitations. By utilizing the latest research and rehabilitation techniques, we ensure that each workout supports your recovery and promotes overall well-being.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Safe and Effective Training</h1>
                    <p>Our trainers are well-versed in adapting exercises to minimize risk and maximize benefits. From low-impact options to chair exercises, we offer a spectrum of workouts designed to accommodate various physical capabilities. Here are 10 examples of how we can modify exercises:</p>
                    <ul>
                        <li>Seated resistance band workouts for upper body strength</li>
                        <li>Pool exercises for low-impact cardio and resistance training</li>
                        <li>Tailored and physiotherapy designed and monitored flexibility and core stability programs</li>
                        <li>Modified squats or lunges with support for lower body strength</li>
                        <li>Hand cycling for individuals with lower limb limitations</li>
                        <li>Isometric exercises for muscle engagement without joint strain</li>
                        <li>Balance training using stability tools</li>
                        <li>Adaptive boxing drills for coordination and cardio</li>
                        <li>Guided stretching routines for improved range of motion</li>
                        <li>Functional movement exercises tailored to daily life activities</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Expert Guidance and Support</h1>
                    <p>Our team of physiotherapists and fitness professionals provide expert guidance and support throughout your fitness journey. We work closely with you to monitor your progress, adjust your program as needed, and ensure that you feel confident and motivated every step of the way.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Inclusive Fitness Community</h1>
                    <p>Join a fitness community that celebrates diversity and inclusivity. At Ramp Physio and Fitness, we foster an environment where individuals of all abilities can thrive. Our adaptive gym equipment and accessible facilities ensure that everyone can participate and enjoy the benefits of physical activity.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default AdaptingWorkoutsForInjuriesAndPhysicalLimitations;