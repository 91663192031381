import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ExercisesToImproveJointHealthAndLongevity = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Optimize Joint Health for Lifelong Mobility</h1>
                    <h2>Unlock the secrets to joint longevity with tailored exercises at Ramp Physio and Fitness, your pathway to sustainable health and peak physical performance.</h2>
                    <p>Joint health is a vital component of overall well-being, particularly as we age. At Ramp Physio and Fitness, we understand the importance of maintaining strong and flexible joints for a lifetime of mobility. In this comprehensive guide, we'll explore targeted exercises designed to enhance joint health and longevity, drawing from expert insights to support your fitness journey. Discover how you can fortify your joints against wear and tear, improve your range of motion, and reduce the risk of joint-related conditions.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Joint Health" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Strengthening Exercises for Joint Support</h1>
                        <p>Building strength around your joints is crucial for stability and preventing injury. Our strength training routines focus on key muscle groups that support joint function, including the knees, hips, and shoulders. By engaging in exercises like squats, lunges, and resistance band workouts, you can create a muscular shield for your joints, enhancing their resilience and durability.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Flexibility and Mobility Workouts</h1>
                    <p>Enhancing joint flexibility and mobility is as important as building strength. Incorporating dynamic stretching and mobility exercises into your routine can improve your joints' range of motion, reduce stiffness, and aid in injury prevention. From Strength and Stability with RAMP’s Tailored Programs to foam rolling, we offer a variety of approaches to keep your joints limber and pain-free.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Low-Impact Activities for Joint Health</h1>
                    <p>Engaging in low-impact exercises is another effective way to maintain joint health without placing undue stress on them. Activities such as swimming, cycling, and using the elliptical machine provide excellent cardiovascular benefits while minimizing the risk of joint damage.</p>
                    <ul>
                        <li>Aquatic exercises: Water provides natural resistance and buoyancy, reducing joint strain.</li>
                        <li>Elliptical training: A smooth motion that mimics running without the high impact.</li>
                        <li>Stationary cycling: Strengthens the leg muscles with less force on the knees.</li>
                        <li>Pilates: Enhances core stability and promotes joint flexibility.</li>
                        <li>Tai Chi: Combines gentle movements and balance to support joint function.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Functional Movement to Simulate Daily Activities</h1>
                    <p>Functional exercises mimic the movements you perform in daily life, helping to prepare your joints for everyday tasks. By practicing movements like step-ups, push-ups, and rotational exercises, you can improve your functional fitness, making day-to-day activities easier and safer for your joints.</p>
                    <br></br>
                    <p>
                        In conclusion, Ramp Physio and Fitness is committed to providing you with a comprehensive approach to joint health and longevity. Our tailored exercise programs are designed to strengthen, stretch, and protect your joints, ensuring a lifetime of mobility and wellness. Join us to experience a proactive approach to joint care, and take control of your joint health today.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ExercisesToImproveJointHealthAndLongevity;