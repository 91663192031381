import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RehabilitationTechniquesForRotatorCuffInjuries = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Heal Your Shoulder with Expert Care</h1>
                    <h2>Unlock the potential for a full recovery from rotator cuff injuries with Ramp Physio and Fitness' specialized rehabilitation techniques in Homebush, NSW.</h2>
                    <p>Rotator cuff injuries can be a significant setback, whether you're an athlete, a weekend warrior, or someone simply dealing with the aches and pains of daily life. At Ramp Physio and Fitness, our expert team employs a range of rehabilitation techniques to help restore your shoulder to its optimal function. In this comprehensive guide, we delve into the world of rotator cuff recovery, providing you with the knowledge and tools needed for a successful rehabilitation journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Rotator Cuff Rehab" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Rotator Cuff Injuries</h1>
                        <p>Rotator cuff injuries can range from mild strains to complete tears, affecting your ability to perform simple tasks. Understanding the nature of your injury is the first step towards rehabilitation. Our physiotherapists provide thorough assessments to tailor a recovery plan that addresses your specific needs.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Rehab Programs</h1>
                    <p>Every individual's path to recovery is unique. At Ramp Physio and Fitness, we create personalized rehabilitation programs that incorporate a mix of techniques, such as targeted exercises, manual therapy, and modalities like ultrasound or laser therapy, to promote healing and prevent future injuries.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strengthening and Conditioning</h1>
                    <p>Strengthening the muscles around the shoulder is crucial for a full recovery. Our conditioning program includes exercises designed to gradually rebuild the strength of your rotator cuff and surrounding muscles, ensuring a solid foundation for shoulder stability and function.</p>
                    <ul>
                        <li>Isometric exercises</li>
                        <li>Resistance band movements</li>
                        <li>Dumbbell exercises</li>
                        <li>Cable machine routines</li>
                        <li>Bodyweight exercises</li>
                        <li>Eccentric loading techniques</li>
                        <li>Functional training</li>
                        <li>Proprioceptive training</li>
                        <li>Scapular stabilization drills</li>
                        <li>Plyometric exercises</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Therapy Techniques</h1>
                    <p>In addition to traditional exercises, we leverage advanced therapy techniques, such as dry needling and myofascial release, to address muscle tension and trigger points that can contribute to rotator cuff pain. These methods help improve range of motion and facilitate the healing process.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RehabilitationTechniquesForRotatorCuffInjuries;