import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Sports Performance</h1>
                    <h2>Maximize Your Sports Performance with Targeted Physiotherapy</h2>
                    <p>At Ramp Physio and Fitness, we are dedicated to helping athletes of all levels reach their full potential. Whether you’re a professional athlete or a weekend warrior, our expert physiotherapists offer specialized programs designed to enhance your sports performance. We focus on improving your strength, flexibility, endurance, and overall athletic ability, while also helping you prevent injuries and recover faster from strenuous activities.</p>
                    <p>Ready to take your sports performance to the next level? Contact us today to schedule a consultation and start your personalized training program with our experienced physiotherapists.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Overcome Barriers to Peak Performance with Customized Physiotherapy</h1>
                        <p>Every athlete faces unique challenges that can hinder peak performance, such as muscle imbalances, poor flexibility, or recurring injuries. At Ramp Physio and Fitness, we identify these barriers through comprehensive assessments and provide targeted treatments to address them. Our goal is to optimize your body’s function, allowing you to perform at your best in your chosen sport.</p>
                        <p>Struggling to reach your full athletic potential? Let us help you identify and overcome the barriers holding you back. Contact us to schedule an assessment and start your journey to peak performance.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Sports Performance Programs for Athletes</h1>
                    <p>Achieving optimal sports performance requires a personalized approach that considers your specific sport, goals, and physical condition. Our sports performance programs at Ramp Physio and Fitness are tailored to meet the unique needs of each athlete. We combine advanced physiotherapy techniques, strength and conditioning exercises, and sport-specific training to help you excel in your sport, reduce the risk of injury, and recover more quickly after intense workouts.</p>
                    <p>Looking to excel in your sport? Contact us to create a personalized sports performance program that fits your goals and helps you achieve peak athletic performance.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Success Stories: Elevating Sports Performance with Our Physiotherapy</h1>
                    <p>Our clients' success stories highlight the effectiveness of our sports performance programs. From improving race times to overcoming chronic injuries and achieving personal bests, our athletes have reached new heights with our expert support. Read their testimonials to see how Ramp Physio and Fitness can help you enhance your sports performance and achieve your athletic goals.</p>
                    <p>Inspired by our athletes’ success? Start your journey to better sports performance with our expert physiotherapy services. Contact us today to book your appointment and take the first step toward achieving your athletic goals.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
