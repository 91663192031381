import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import motorVehicleInjuryLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForMotorVehicleInjuries = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Motor Vehicle Injuries | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy treatment and exercises for motor vehicle injuries to help you recover and regain your strength and mobility." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert Physiotherapy for Motor Vehicle Injuries</h1>
                    <h2>Discover personalized treatment and exercises for motor vehicle injuries at Ramp Physio and Fitness, Homebush, NSW.</h2>
                    <p>Motor vehicle accidents can lead to a range of physical injuries, from whiplash and soft tissue damage to fractures and spinal cord trauma. At Ramp Physio and Fitness, we specialize in providing comprehensive physiotherapy treatment and exercises designed to address the unique challenges of motor vehicle injuries. Our goal is to support your recovery journey, helping you regain your strength, mobility, and confidence on the road to wellness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Assessment and Immediate Care</h1>
                        <p>Our first step in treating motor vehicle injuries is a thorough assessment to understand the full extent of your condition. We focus on immediate care to reduce pain and prevent further damage. This may involve:</p>
                        <div className="dot-points">
                            <p>- Pain management techniques</p>
                            <p>- Manual therapy to alleviate discomfort</p>
                            <p>- Gentle mobility exercises to maintain range of motion</p>
                            <p>- Education on injury care and prevention</p>
                            <p>- Supportive bracing or taping if necessary</p>
                        </div>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Immediate Care" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Manual Therapy" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Rehabilitation Programs</h1>
                    <p>Once we've established a baseline for your recovery, we tailor a rehabilitation program to your specific needs. This program may include:</p>
                    <div className="dot-points">
                        <p>- Targeted exercises to rebuild strength</p>
                        <p>- Joint mobilization to restore function</p>
                        <p>- Soft tissue therapies to aid healing</p>
                        <p>- Balance and proprioception training</p>
                        <p>- Modalities such as ultrasound or TENS for pain relief</p>
                    </div>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={motorVehicleInjuryLogo} alt="Motor Vehicle Injury Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Advanced Techniques for Recovery</h1>
                    <p>As you progress through your rehabilitation, we introduce advanced techniques to further enhance your recovery. These may include:</p>
                    <div className="dot-points">
                        <p>- Progressive resistance training</p>
                        <p>- Functional movement patterns</p>
                        <p>- Sports-specific drills for athletes</p>
                        <p>- Postural re-education and ergonomic advice</p>
                        <p>- Gait analysis and correction</p>
                    </div>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Advanced Recovery" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Functional Training" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Long-Term Health and Wellness</h1>
                        <p>Our commitment to your recovery extends beyond the immediate aftermath of an accident. We provide support and strategies for long-term health, including:</p>
                        <div className="dot-points">
                            <p>- Ongoing maintenance exercises</p>
                            <p>- Strategies to prevent re-injury</p>
                            <p>- Nutrition and lifestyle advice</p>
                            <p>- Stress management techniques</p>
                            <p>- Access to our gym and personal training services</p>
                        </div>
                        <br></br>
                        <p>
                            At Ramp Physio and Fitness, we are dedicated to providing you with the highest quality care and support throughout your recovery from motor vehicle injuries. Our expert team is committed to helping you return to your pre-accident level of health and fitness, or even surpass it. Book your appointment today to start your journey back to optimal health.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForMotorVehicleInjuries;