import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GoogleTagManager from './components/utils/GoogleTagManager';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import FooterBar from './components/Footerbar';
import Team from './components/Team'
import Services from './components/Services';
import NDIS from './components/NDIS';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsandConditions from './components/TermsandConditions';
import Ultrasound from './components/Ultrasound';
import PoorPosture from './components/PoorPosture';
import PostOpRehab from './components/PostOpRehab';
import WorkcoverNSW from './components/WorkcoverNSW';
import PersonalTraining from './components/PersonalTraining';
import RemedialMassage from './components/RemedialMassage';
import SportsPerformance from './components/SportsPerformance';
import WorkersCompensation from './components/WorkersCompensation';
import CarAccidentInjuries from './components/CarAccidentInjuries';
import EMS from './components/EMS';
import MuscularStrains from './components/MuscularStrains';
import BackPain from './components/BackPain';
import FAQ from './components/FAQ';
import News from './components/News';
import PhysiotherapyInHomebush from './components/physiotherapy-in-homebush';
import ACL from './components/acl';
import ACLinjuryphysiotherapy from './components/aclinjuryphysiotherapy'
import ACLrehabilitation from './components/aclrehab'
import ACLsurgery from './components/aclsurgery'
import ACLtear from './components/acltear'
import CorrectiveExercise from './components/correctiveexercisesforposture'
import Deeptissueremedialmassage from './components/deeptissueremedialmassage'
import Discbulge from './components/discbulge'
import Emsformusclerecovery from './components/emsformusclerecovery'
import Frozenshoulder from './components/frozenshoulder'
import Kneepainphysiotherapy from './components/kneepainphysiotherapy'
import Lumbartraction from './components/lumbartraction'
import Manualtherapytechniques from './components/manualtherapytechniques'
import Musculoskeletalphysiotherapy from './components/musculoskeletalphysiotherapy'
import Ndisphysiotherapy from './components/ndisphysiotherapy'
import Neckpain from './components/neckpain'
import Neurologicalphysiotherapy from './components/neurologicalphysiotherapy'
import Normatec3 from './components/normatec3'
import Normateccompression from './components/normateccompression'
import Physiotherapyclinicnearme from './components/physiotherapyclinicnearme'
import Physiotherapyexercises from './components/physiotherapyexercises'
import Physiotherapyforbackpain from './components/physiotherapyforbackpain'
import Physiotherapyforsciatica from './components/physiotherapyforsciatica'
import Physiotherapyforwhiplash from './components/physiotherapyforwhiplash'
import Plastercastingandcastremoval from './components/plastercastingcastremoval'
import Remedialmassageforbackpain from './components/remedialmassageforbackpain'
import Remedialmassagetherapy from './components/remedialmassagetherapy'
import Shockwavetherapy from './components/shockwavetherapy'
import Shockwaveforpainrelief from './components/shockwavetherapyforpainrelief'
import Shockwavetherapyforplantarfasciitis from './components/shockwavetherapyforplantarfasciitis'
import Sportinjury from './components/sportsinjury'
import Sportperformanceenhancement from './components/sportsperformanceenhancement'
import Sportperformancephysiotherapy from './components/sportsperformancephysiotherapy'
import Ultrasoundphysiotherapy from './components/ultrasoundphysiotherapy'
import Gymnearme from './components/gymnearme'
import Homebushphysiotherapy from './components/homebushphysiotherapy'
import Australianconsumerlaw from './components/australianconsumerlaw'
import Dryneedlingandacupuncture from './components/dryneedlingandacupuncture'
import Spinaldecompressiontherapy from './components/spinaldecompressiontherapy'
import Sciaticatreatment from './components/sciaticatreatment'
import Facetsyndrometreatment from './components/facetsyndrometreatment'
import Degenerativediscs from './components/degenerativediscs'
import Managingsciatica from './components/Managingsciatica'
import Tmjphysiotherapy from './components/Tmjphysiotherapy'
import Physiotherapyforarthritispainmanagement from './components/physiotherapyforarthritispainmanagement'
import PhysiotherapyForSpecificConditions from './components/physiotherapyforspecificconditions'
import Gymknowledge from './components/gymknowledge'
import UnderstandingPhysiotherapy from './components/understandingphysiotherapy'
import Rehabilitation from './components/Rehabilitation'
import BenefitsofGymTraining from './components/benefitsofgymtraining'
import NDISKnowledge from './components/NDISKnowledge'
import IntellectualAndDevelopmental from './components/intellectualAndDevelopmental'
import NeurologicalConditions from './components/neurologicalConditions'
import NeuromuscularAndChronic from './components/neuromuscularAndChronic'
import MentalHealth from './components/mentalHealth'
import KeyTreatmentsAndQuestions from './components/keyTreatmentsAndQuestions'
import KeyTreatmentsForNDIS from './components/keytreatmentsforNDIS'
import FrequentlyAskedQuestions from './components/frequentlyaskedquestions'
import ScrollToTop from './components/utils/ScrollToTop'
import ThankYou from './components/postsubmit_thankyou'
import './App.css';
import AnkleSprainTreatmentAndPrevention from './components/anklespraintreatmentandprevention';
import BalanceAndFallPreventionStrategiesForSeniors from './components/balanceandfallpreventionstrategiesforseniors';
import BenefitsOfProprioceptiveTrainingInInjuryPrevention from './components/benefitsofproprioceptivetrainingininjuryprevention';
import BenefitsOfRegularPhysiotherapyAssessments from './components/benefitsofregularphysiotherapyassessments';
import CoreStrengtheningExercisesForLowerBackSupport from './components/corestrengtheningexercisesforlowerbacksupport';
import ErgonomicsAndWorkplaceInjuryPrevention from './components/ergonomicsandworkplaceinjuryprevention';
import ImportanceofwarmUpandcoolDownexercises from './components/importanceofwarm-upandcool-downexercises';
import LymphaticDrainageMassageTherapy from './components/lymphaticdrainagemassagetherapy';
import ManagementOfChronicPelvicPainSyndrome from './components/managementofchronicpelvicpainsyndrome';
import ManagingChronicPainWithPhysiotherapy from './components/managingchronicpainwithphysiotherapy';
import ManagingChronicTendonitisWithPhysiotherapy from './components/managingchronictendonitiswithphysiotherapy';
import ManagingFibromyalgiaSymptomsWithPhysiotherapy from './components/managingfibromyalgiasymptomswithphysiotherapy';
import ManagingHypermobilitySyndromeWithPhysiotherapy from './components/managinghypermobilitysyndromewithphysiotherapy';
import ManagingLymphedemaWithPhysiotherapy from './components/managinglymphedemawithphysiotherapy';
import ManagingMultipleSclerosisSymptomsWithPhysiotherapy from './components/managingmultiplesclerosissymptomswithphysiotherapy';
import ManagingPlantarWartsWithPhysiotherapy from './components/managingplantarwartswithphysiotherapy';
import ManagingSciaticaWithPhysiotherapyExercises from './components/managingsciaticawithphysiotherapyexercises';
import ManagingStressIncontinenceWithPhysiotherapy from './components/managingstressincontinencewithphysiotherapy';
import MindBodyconnection from './components/mind-bodyconnection';
import NeuromuscularElectricalStimulationInRehabilitation from './components/neuromuscularelectricalstimulationinrehabilitation';
import Pediatricphysiotherapy from './components/pediatricphysiotherapy';
import PhysiotherapyAndGymForCoreStrengthAndStability from './components/physiotherapyandgymforcorestrengthandstability';
import PhysiotherapyApproachesToTreatingBursitis from './components/physiotherapyapproachestotreatingbursitis';
import PhysiotherapyApproachesToTreatingSpondylolisthesis from './components/physiotherapyapproachestotreatingspondylolisthesis';
import PhysiotherapyForAnkylosingSpondylitis from './components/physiotherapyforankylosingspondylitis';
import PhysiotherapyForArthritisPainManagement from './components/physiotherapyforarthritispainmanagement';
import Physiotherapyforbellspalsyrecovery from './components/physiotherapyforbellspalsyrecovery';
import PhysiotherapyForCarpalTunnelSyndromeRelief from './components/physiotherapyforcarpaltunnelsyndromerelief';
import PhysiotherapyForCerebralPalsy from './components/physiotherapyforcerebralpalsy';
import Physiotherapyforchronicfatiguesyndromemecfs from './components/physiotherapyforchronicfatiguesyndromemecfs';
import PhysiotherapyForChronicNeckTensionAndHeadaches from './components/physiotherapyforchronicnecktensionandheadaches';
import Physiotherapyforchronicobstructivepulmonarydiseasecopd from './components/physiotherapyforchronicobstructivepulmonarydiseasecopd';
import Physiotherapyfordupuytrenscontracture from './components/physiotherapyfordupuytrenscontracture';
import PhysiotherapyForFacialNervePalsy from './components/physiotherapyforfacialnervepalsy';
import Physiotherapyforflatfeetpesplanus from './components/physiotherapyforflatfeetpesplanus';
import Physiotherapyforgolferselbow from './components/physiotherapyforgolferselbow';
import Physiotherapyforguillainbarrsyndromerecovery from './components/physiotherapyforguillainbarrsyndromerecovery';
import PhysiotherapyForHeadachesAndMigraines from './components/physiotherapyforheadachesandmigraines';
import PhysiotherapyForHeelSpurs from './components/physiotherapyforheelspurs';
import Physiotherapyforhyperkyphosishunchback from './components/physiotherapyforhyperkyphosishunchback';
import PhysiotherapyForJuvenileArthritis from './components/physiotherapyforjuvenilearthritis';
import PhysiotherapyForLabralTearsInTheHip from './components/physiotherapyforlabraltearsinthehip';
import Physiotherapyforlateralepicondylitistenniselbow from './components/physiotherapyforlateralepicondylitistenniselbow';
import Physiotherapyformortonsneuroma from './components/physiotherapyformortonsneuroma';
import PhysiotherapyForOsteitisPubisInAthletes from './components/physiotherapyforosteitispubisinathletes';
import PhysiotherapyForOsteoporosis from './components/physiotherapyforosteoporosis';
import Physiotherapyforparkinsonsdisease from './components/physiotherapyforparkinsonsdisease';
import PhysiotherapyForPatellarDislocation from './components/physiotherapyforpatellardislocation';
import PhysiotherapyForPelvicPain from './components/physiotherapyforpelvicpain';
import PhysiotherapyForPeripheralNeuropathy from './components/physiotherapyforperipheralneuropathy';
import Physiotherapyforrespiratoryconditions from './components/physiotherapyforrespiratoryconditions';
import PhysiotherapyForRheumatoidArthritis from './components/physiotherapyforrheumatoidarthritis';
import Physiotherapyforrunnerskneepatellofemoralpainsyndrome from './components/physiotherapyforrunnerskneepatellofemoralpainsyndrome';
import PhysiotherapyForSacroiliacJointDysfunction from './components/physiotherapyforsacroiliacjointdysfunction';
import Physiotherapyforscheuermannsdisease from './components/physiotherapyforscheuermannsdisease';
import PhysiotherapyForScoliosisManagement from './components/physiotherapyforscoliosismanagement';
import PhysiotherapyForShoulderImpingementSyndrome from './components/physiotherapyforshoulderimpingementsyndrome';
import PhysiotherapyForSpinalCordInjuries from './components/physiotherapyforspinalcordinjuries';
import PhysiotherapyForStrokeRehabilitation from './components/physiotherapyforstrokerehabilitation';
import PhysiotherapyForTemporomandibularJointTmjDisorders from './components/physiotherapyfortemporomandibularjoint(tmj)disorders';
import PhysiotherapyForTennisElbow from './components/physiotherapyfortenniselbow';
import PhysiotherapyForThoracicOutletSyndrome from './components/physiotherapyforthoracicoutletsyndrome';
import PhysiotherapyForTorticollisInInfants from './components/physiotherapyfortorticollisininfants';
import Physiotherapyforwhiplashassociateddisorders from './components/physiotherapyforwhiplashassociateddisorders';
import PhysiotherapyInOccupationalHealth from './components/physiotherapyinoccupationalhealth';
import PhysiotherapyTechniquesForManagingEdema from './components/physiotherapytechniquesformanagingedema';
import PreAndpostOperativephysiotherapy from './components/pre-andpost-operativephysiotherapy';
import Preventingsportsinjuries from './components/preventingsportsinjuries';
import RehabilitationAfterAnkleFracture from './components/rehabilitationafteranklefracture';
import RehabilitationAfterFemurFracture from './components/rehabilitationafterfemurfracture';
import RehabilitationAfterHipReplacementSurgery from './components/rehabilitationafterhipreplacementsurgery';
import RehabilitationAfterKneeReplacementSurgery from './components/rehabilitationafterkneereplacementsurgery';
import RehabilitationAfterMeniscusSurgery from './components/rehabilitationaftermeniscussurgery';
import RehabilitationAfterRotatorCuffSurgery from './components/rehabilitationafterrotatorcuffsurgery';
import RehabilitationAfterShoulderReplacementSurgery from './components/rehabilitationaftershoulderreplacementsurgery';
import RehabilitationAfterWristFracture from './components/rehabilitationafterwristfracture';
import RehabilitationStrategiesForMuscleAtrophy from './components/rehabilitationstrategiesformuscleatrophy';
import RehabilitationStrategiesForSpinalStenosis from './components/rehabilitationstrategiesforspinalstenosis';
import RehabilitationTechniquesForAchillesTendonRupture from './components/rehabilitationtechniquesforachillestendonrupture';
import RehabilitationTechniquesForRotatorCuffInjuries from './components/rehabilitationtechniquesforrotatorcuffinjuries';
import TheBenefitsOfFunctionalMovementScreening from './components/thebenefitsoffunctionalmovementscreening';
import TheBenefitsOfIsokineticTestingInRehabilitation from './components/thebenefitsofisokinetictestinginrehabilitation';
import TheImportanceOfGaitAnalysisInPhysiotherapy from './components/theimportanceofgaitanalysisinphysiotherapy';
import TheRoleOfOrthoticsInPhysiotherapy from './components/theroleoforthoticsinphysiotherapy';
import TheRoleOfPhysiotherapyInManagingDiabetes from './components/theroleofphysiotherapyinmanagingdiabetes';
import Understandingandtreatingosgoodschlatterdisease from './components/understandingandtreatingosgoodschlatterdisease';
import UnderstandingAndTreatingPlantarFasciitis from './components/understandingandtreatingplantarfasciitis';
import UnderstandingBiofeedbackInPhysiotherapy from './components/understandingbiofeedbackinphysiotherapy';
import UnderstandingBiomechanicsInSportsPhysiotherapy from './components/understandingbiomechanicsinsportsphysiotherapy';
import UnderstandingCraniosacralTherapyInPhysiotherapy from './components/understandingcraniosacraltherapyinphysiotherapy';
import Understandingkinesiotaping from './components/understandingkinesiotaping';
import UnderstandingMyofascialReleaseTechniques from './components/understandingmyofascialreleasetechniques';
import UnderstandingTensTherapyInPhysiotherapy from './components/understandingtenstherapyinphysiotherapy';
import UnderstandingVestibularDisordersAndPhysiotherapyTreatments from './components/understandingvestibulardisordersandphysiotherapytreatments';
import VestibularRehabilitationForDizzinessAndVertigo from './components/vestibularrehabilitationfordizzinessandvertigo';
import Womenshealthphysiotherapy from './components/womenshealthphysiotherapy';

import AdaptingGymWorkoutsForHomeExercise from './components/adaptinggymworkoutsforhomeexercise';
import AdaptingWorkoutsForChronicConditions from './components/adaptingworkoutsforchronicconditions';
import AdaptingWorkoutsForInjuriesAndPhysicalLimitations from './components/adaptingworkoutsforinjuriesandphysicallimitations';
import Advancedtrainingtechniquessupersetsdropsetsandmore from './components/advancedtrainingtechniquessupersetsdropsetsandmore';
import BalancingCardioAndStrengthTraining from './components/balancingcardioandstrengthtraining';
import Beginnersguidetostartingagymroutine from './components/beginnersguidetostartingagymroutine';
import BenefitsOfFunctionalTrainingExercises from './components/benefitsoffunctionaltrainingexercises';
import Benefitsofoutdoorvsindoorworkouts from './components/benefitsofoutdoorvsindoorworkouts';
import BenefitsOfPersonalTrainingSessions from './components/benefitsofpersonaltrainingsessions';
import Buildinganeffectivewarmupandcooldownroutine from './components/buildinganeffectivewarmupandcooldownroutine';
import Buildingmindmuscleconnectionforbetterresults from './components/buildingmindmuscleconnectionforbetterresults';
import Buildingmuscleonaplantbaseddiet from './components/buildingmuscleonaplantbaseddiet';
import Cardiovsstrengthtrainingwhatsbestforyou from './components/cardiovsstrengthtrainingwhatsbestforyou';
import Circuittrainingbenefitsandsampleworkouts from './components/circuittrainingbenefitsandsampleworkouts';
import CreatingABalancedGymWorkoutPlan from './components/creatingabalancedgymworkoutplan';
import Developingastrongcoreexercisesbeyondsitups from './components/developingastrongcoreexercisesbeyondsitups';
import EffectiveGymRoutinesForBeginners from './components/effectivegymroutinesforbeginners';
import EffectiveGymWorkoutsForBusyProfessionals from './components/effectivegymworkoutsforbusyprofessionals';
import EffectiveStretchingTechniquesForFlexibility from './components/effectivestretchingtechniquesforflexibility';
import EffectiveWeightLossWorkoutsAtTheGym from './components/effectiveweightlossworkoutsatthegym';
import ExercisesForHealthyKneesAndInjuryPrevention from './components/exercisesforhealthykneesandinjuryprevention';
import ExercisesToAlleviateNeckAndShoulderTension from './components/exercisestoalleviateneckandshouldertension';
import ExercisesToEnhanceLungCapacity from './components/exercisestoenhancelungcapacity';
import Exercisestoimprovehandeyecoordination from './components/exercisestoimprovehandeyecoordination';
import ExercisesToImproveJointHealthAndLongevity from './components/exercisestoimprovejointhealthandlongevity';
import Gymetiquettedosanddonts from './components/gymetiquettedosanddonts';
import Gymetiquetteunwrittenruleseverymembershouldknow from './components/gymetiquetteunwrittenruleseverymembershouldknow';
import GymExercisesToStrengthenYourBackAndReducePain from './components/gymexercisestostrengthenyourbackandreducepain';
import GymStrategiesForEnhancingMentalClarity from './components/gymstrategiesforenhancingmentalclarity';
import GymTrainingForHormonalBalance from './components/gymtrainingforhormonalbalance';
import Gymtrainingforweightlosstipsandtricks from './components/gymtrainingforweightlosstipsandtricks';
import Gymworkoutsduringpregnancysafetytips from './components/gymworkoutsduringpregnancysafetytips';
import GymWorkoutsForBetterBoneDensity from './components/gymworkoutsforbetterbonedensity';
import GymWorkoutsForBoostingMetabolism from './components/gymworkoutsforboostingmetabolism';
import GymWorkoutsForBuildingEndurance from './components/gymworkoutsforbuildingendurance';
import GymWorkoutsForBuildingLeanMuscle from './components/gymworkoutsforbuildingleanmuscle';
import GymWorkoutsForCombattingSedentaryLifestyles from './components/gymworkoutsforcombattingsedentarylifestyles';
import GymWorkoutsForEnhancingFlexibility from './components/gymworkoutsforenhancingflexibility';
import GymWorkoutsForImprovingAgilityAndSpeed from './components/gymworkoutsforimprovingagilityandspeed';
import GymWorkoutsForImprovingBalanceAndCoordination from './components/gymworkoutsforimprovingbalanceandcoordination';
import GymWorkoutsForImprovingCardiovascularHealth from './components/gymworkoutsforimprovingcardiovascularhealth';
import GymWorkoutsForImprovingSleepQuality from './components/gymworkoutsforimprovingsleepquality';
import GymWorkoutsForManagingDiabetes from './components/gymworkoutsformanagingdiabetes';
import GymWorkoutsForPostureCorrection from './components/gymworkoutsforposturecorrection';
import GymWorkoutsForRehabilitationAndInjuryRecovery from './components/gymworkoutsforrehabilitationandinjuryrecovery';
import Gymworkoutsforseniorsstayingfitatanyage from './components/gymworkoutsforseniorsstayingfitatanyage';
import GymWorkoutsForStressRelief from './components/gymworkoutsforstressrelief';
import Gymworkoutsforteenagersbuildinghealthyhabits from './components/gymworkoutsforteenagersbuildinghealthyhabits';
import GymWorkoutsTailoredForSeniors from './components/gymworkoutstailoredforseniors';
import GymWorkoutsToImprovePosture from './components/gymworkoutstoimproveposture';
import Highintensityfunctionaltraininghiftexplained from './components/highintensityfunctionaltraininghiftexplained';
import Highintensityintervaltraininghiitexplained from './components/highintensityintervaltraininghiitexplained';
import HowToCreateAPersonalizedWorkoutPlan from './components/howtocreateapersonalizedworkoutplan';
import HowToOvercomeGymAnxiety from './components/howtoovercomegymanxiety';
import HowToTrackAndMeasureFitnessProgress from './components/howtotrackandmeasurefitnessprogress';
import HowToUseFreeWeightsSafely from './components/howtousefreeweightssafely';
import HydrationStrategiesForOptimalPerformance from './components/hydrationstrategiesforoptimalperformance';
import ImprovingFlexibilityAndMobilityThroughStretching from './components/improvingflexibilityandmobilitythroughstretching';
import IncorporatingDanceFitnessForFunWorkouts from './components/incorporatingdancefitnessforfunworkouts';
import IncorporatingFlexibilityAndMobilityIntoYourRoutine from './components/incorporatingflexibilityandmobilityintoyourroutine';
import IncorporatingMindfulnessAndMeditationIntoFitness from './components/incorporatingmindfulnessandmeditationintofitness';
import IncorporatingPlyometricsForExplosivePower from './components/incorporatingplyometricsforexplosivepower';
import ManagingStressThroughExercise from './components/managingstressthroughexercise';
import Maximizingfatlosswithhighintensityintervaltraininghiit from './components/maximizingfatlosswithhighintensityintervaltraininghiit';
import Mindmuscleconnectionenhancingworkouteffectiveness from './components/mindmuscleconnectionenhancingworkouteffectiveness';
import Nutritionmythsdebunkedfactsforbetterfitness from './components/nutritionmythsdebunkedfactsforbetterfitness';
import Nutritiontimingpreandpostworkoutmeals from './components/nutritiontimingpreandpostworkoutmeals';
import NutritionTipsForVegetariansAndVegansInFitness from './components/nutritiontipsforvegetariansandvegansinfitness';
import OptimizingFatLossWithCircuitTraining from './components/optimizingfatlosswithcircuittraining';
import Overcomingplateausstrategiestokeepprogressing from './components/overcomingplateausstrategiestokeepprogressing';
import PreventingCommonGymInjuries from './components/preventingcommongyminjuries';
import Seasonalfitnessadjustingyourworkoutroutinethroughouttheyear from './components/seasonalfitnessadjustingyourworkoutroutinethroughouttheyear';
import SettingRealisticFitnessGoalsAndHowToAchieveThem from './components/settingrealisticfitnessgoalsandhowtoachievethem';
import StayingMotivatedOnYourFitnessJourney from './components/stayingmotivatedonyourfitnessjourney';
import Stayingmotivatedsettingandachievingfitnessgoals from './components/stayingmotivatedsettingandachievingfitnessgoals';
import StrategiesForHealthyWeightGain from './components/strategiesforhealthyweightgain';
import StrategiesForStayingFitWhileTraveling from './components/strategiesforstayingfitwhiletraveling';
import Strengthtrainingforwomendebunkingmyths from './components/strengthtrainingforwomendebunkingmyths';
import Strengthtrainingvsbodybuildingwhatsthedifference from './components/strengthtrainingvsbodybuildingwhatsthedifference';
import Thebenefitsoffoamrollingandselfmyofascialrelease from './components/thebenefitsoffoamrollingandselfmyofascialrelease';
import Thebenefitsofhighproteindietsintraining from './components/thebenefitsofhighproteindietsintraining';
import TheBenefitsOfResistanceBandTraining from './components/thebenefitsofresistancebandtraining';
import TheImpactOfAlcoholOnFitnessAndRecovery from './components/theimpactofalcoholonfitnessandrecovery';
import TheImpactOfMusicOnWorkoutPerformance from './components/theimpactofmusiconworkoutperformance';
import TheImpactOfSleepOnMuscleRecoveryAndPerformance from './components/theimpactofsleeponmusclerecoveryandperformance';
import TheImpactOfTechnologyOnModernFitness from './components/theimpactoftechnologyonmodernfitness';
import TheImportanceOfConsistencyInFitness from './components/theimportanceofconsistencyinfitness';
import TheImportanceOfFiberInAthleticPerformance from './components/theimportanceoffiberinathleticperformance';
import TheImportanceOfGoalSettingInFitness from './components/theimportanceofgoalsettinginfitness';
import TheImportanceOfProperHydrationDuringWorkouts from './components/theimportanceofproperhydrationduringworkouts';
import TheImportanceOfRestAndRecoveryInTraining from './components/theimportanceofrestandrecoveryintraining';
import TheImportanceOfRestDaysAndActiveRecovery from './components/theimportanceofrestdaysandactiverecovery';
import Theimportanceofwarmupandcooldowninyourgymsession from './components/theimportanceofwarmupandcooldowninyourgymsession';
import TheRoleOfAntioxidantsInMuscleRecovery from './components/theroleofantioxidantsinmusclerecovery';
import TheRoleOfFunctionalMovementScreening from './components/theroleoffunctionalmovementscreening';
import TheRoleOfGutHealthInFitnessAndWellbeing from './components/theroleofguthealthinfitnessandwellbeing';
import TheRoleOfHealthyFatsInFitnessNutrition from './components/theroleofhealthyfatsinfitnessnutrition';
import TheRoleOfMentalHealthInPhysicalFitness from './components/theroleofmentalhealthinphysicalfitness';
import TheRoleOfNutritionInAchievingFitnessGoals from './components/theroleofnutritioninachievingfitnessgoals';
import TheRoleOfNutritionInFitness from './components/theroleofnutritioninfitness';
import TheScienceBehindMuscleHypertrophy from './components/thesciencebehindmusclehypertrophy';
import Timeefficientworkoutsforbusyschedules from './components/timeefficientworkoutsforbusyschedules';
import Top10ExercisesForBuildingMuscleMass from './components/top10exercisesforbuildingmusclemass';
import TopExercisesForStrengtheningYourCore from './components/topexercisesforstrengtheningyourcore';
import TopSupplementsToSupportYourFitnessJourney from './components/topsupplementstosupportyourfitnessjourney';
import TrackingYourFitnessProgressWithTechnology from './components/trackingyourfitnessprogresswithtechnology';
import TrainingForEnduranceSportsAtTheGym from './components/trainingforendurancesportsatthegym';
import TrainingForFunctionalStrengthAndDailyActivities from './components/trainingforfunctionalstrengthanddailyactivities';
import Trainingforobstaclecourseracesegtoughmudder from './components/trainingforobstaclecourseracesegtoughmudder';
import Trainingforspecificsportstailoredgymprograms from './components/trainingforspecificsportstailoredgymprograms';
import TrainingProgramsForImprovingReactionTime from './components/trainingprogramsforimprovingreactiontime';
import TrainingProgramsForStrengtheningTheImmuneSystem from './components/trainingprogramsforstrengtheningtheimmunesystem';
import TrainingStrategiesForEnduranceAthletes from './components/trainingstrategiesforenduranceathletes';
import TrainingTipsForMarathonPreparation from './components/trainingtipsformarathonpreparation';
import Trainingwithfreeweightsvsmachines from './components/trainingwithfreeweightsvsmachines';
import Trainingwithkettlebellsexercisesandbenefits from './components/trainingwithkettlebellsexercisesandbenefits';
import Trainingwithmedicineballsversatileexercises from './components/trainingwithmedicineballsversatileexercises';
import Trainingwithsandbagsunstableloadbenefits from './components/trainingwithsandbagsunstableloadbenefits';
import Trainingwithsuspensiontrainersegtrx from './components/trainingwithsuspensiontrainersegtrx';
import UnderstandingAndPreventingOvertraining from './components/understandingandpreventingovertraining';
import UnderstandingAndUtilizingActiveRecoveryDays from './components/understandingandutilizingactiverecoverydays';
import UnderstandingAndUtilizingGymEquipmentSafely from './components/understandingandutilizinggymequipmentsafely';
import UnderstandingBodyCompositionAndItsRoleInFitness from './components/understandingbodycompositionanditsroleinfitness';
import UnderstandingBodyCompositionAndSettingFitnessGoals from './components/understandingbodycompositionandsettingfitnessgoals';
import UnderstandingBodyweightTrainingBenefits from './components/understandingbodyweighttrainingbenefits';
import UnderstandingCaloricNeedsForDifferentFitnessGoals from './components/understandingcaloricneedsfordifferentfitnessgoals';
import Understandingdifferenttrainingtechniquessupersetsdropsetsandmore from './components/understandingdifferenttrainingtechniquessupersetsdropsetsandmore';
import UnderstandingHeartRateZonesForOptimalTraining from './components/understandingheartratezonesforoptimaltraining';
import UnderstandingIntermittentFastingAndFitness from './components/understandingintermittentfastingandfitness';
import Understandingmacrosproteincarbsandfats from './components/understandingmacrosproteincarbsandfats';
import UnderstandingTheGlycemicIndexForEnergyManagement from './components/understandingtheglycemicindexforenergymanagement';
import UnderstandingVo2MaxAndCardiovascularFitness from './components/understandingvo2maxandcardiovascularfitness';
import WorkoutTipsForBuildingEndurance from './components/workouttipsforbuildingendurance';
import Rampfitnesspersonaltraining from './components/rampfitnesspersonaltraining';

import Howoftenwillineedtoseemyndisphysio from './components/howoftenwillineedtoseemyndisphysio';
import NdisPhysiotherapyAssistedStretchingToRelieveStiffnessInJoints from './components/ndisphysiotherapyassistedstretchingtorelievestiffnessinjoints';
import NdisPhysiotherapyElectrotherapyAndHeatTherapyToReduceInflammation from './components/ndisphysiotherapyelectrotherapyandheattherapytoreduceinflammation';
import NdisPhysiotherapyForAcquiredAndTraumaticBrainInjuries from './components/ndisphysiotherapyforacquiredandtraumaticbraininjuries';
import Ndisphysiotherapyforangelmanssyndrome from './components/ndisphysiotherapyforangelmanssyndrome';
import NdisPhysiotherapyForAnxiety from './components/ndisphysiotherapyforanxiety';
import Ndisphysiotherapyforautismspectrumdisorder from './components/ndisphysiotherapyforautismspectrumdisorder';
import Ndisphysiotherapyforadhd from './components/ndisphysiotherapyforadhd';
import Ndisphysiotherapyforbardetbiedisyndrome from './components/ndisphysiotherapyforbardetbiedisyndrome';
import NdisPhysiotherapyForBipolarDisorder from './components/ndisphysiotherapyforbipolardisorder';
import NdisPhysiotherapyForCerebralPalsy from './components/ndisphysiotherapyforcerebralpalsy';
import NdisPhysiotherapyForChromosomalDisorders from './components/ndisphysiotherapyforchromosomaldisorders';
import NdisPhysiotherapyForChronicFatigue from './components/ndisphysiotherapyforchronicfatigue';
import NdisPhysiotherapyForDementia from './components/ndisphysiotherapyfordementia';
import NdisPhysiotherapyForDepression from './components/ndisphysiotherapyfordepression';
import NdisPhysiotherapyForDevelopmentalDelay from './components/ndisphysiotherapyfordevelopmentaldelay';
import NdisPhysiotherapyForDisorderedEating from './components/ndisphysiotherapyfordisorderedeating';
import NdisPhysiotherapyForDownSyndrome from './components/ndisphysiotherapyfordownsyndrome';
import NdisPhysiotherapyForEpilepsy from './components/ndisphysiotherapyforepilepsy';
import NdisPhysiotherapyForFibromyalgia from './components/ndisphysiotherapyforfibromyalgia';
import NdisPhysiotherapyForFoetalAlcoholSpectrumSyndrome from './components/ndisphysiotherapyforfoetalalcoholspectrumsyndrome';
import NdisPhysiotherapyForFragileXSyndrome from './components/ndisphysiotherapyforfragilexsyndrome';
import NdisPhysiotherapyForGuillainBarreSyndrome from './components/ndisphysiotherapyforguillainbarresyndrome';
import Ndisphysiotherapyforhuntingtonsdisease from './components/ndisphysiotherapyforhuntingtonsdisease';
import NdisPhysiotherapyForIntellectualDisabilities from './components/ndisphysiotherapyforintellectualdisabilities';
import NdisPhysiotherapyForMentalHealthConditions from './components/ndisphysiotherapyformentalhealthconditions';
import NdisPhysiotherapyForMotorNeuroneDisease from './components/ndisphysiotherapyformotorneuronedisease';
import NdisPhysiotherapyForMultipleSclerosis from './components/ndisphysiotherapyformultiplesclerosis';
import NdisPhysiotherapyForMuscularDystrophy from './components/ndisphysiotherapyformusculardystrophy';
import NdisPhysiotherapyProvider from './components/ndisphysiotherapyprovider';
import NdisPhysiotherapyForNeurologicalConditions from './components/ndisphysiotherapyforneurologicalconditions';
import Ndisphysiotherapyforparkinsonsdisease from './components/ndisphysiotherapyforparkinsonsdisease';
import NdisPhysiotherapyForPeripheralNeuropathy from './components/ndisphysiotherapyforperipheralneuropathy';
import NdisPhysiotherapyForPersonalityDisorders from './components/ndisphysiotherapyforpersonalitydisorders';
import Ndisphysiotherapyforpraderwillisyndrome from './components/ndisphysiotherapyforpraderwillisyndrome';
import NdisPhysiotherapyForPsychoticIllnesses from './components/ndisphysiotherapyforpsychoticillnesses';
import NdisPhysiotherapyForPtsd from './components/ndisphysiotherapyforptsd';
import NdisPhysiotherapyForSchizophrenia from './components/ndisphysiotherapyforschizophrenia';
import NdisPhysiotherapyForSpinalCordInjuries from './components/ndisphysiotherapyforspinalcordinjuries';
import NdisPhysiotherapyForStroke from './components/ndisphysiotherapyforstroke';
import NdisPhysiotherapyForWilliamsSyndrome from './components/ndisphysiotherapyforwilliamssyndrome';
import NdisPhysiotherapyFunctionalTaskTrainingIncludingBalancingAndMovement from './components/ndisphysiotherapyfunctionaltasktrainingincludingbalancingandmovement';
import NdisPhysiotherapyImprovingMoodAndEnergyLevels from './components/ndisphysiotherapyimprovingmoodandenergylevels';
import NdisPhysiotherapyStrengtheningExercisesToBoostMuscleConditionAndPosture from './components/ndisphysiotherapystrengtheningexercisestoboostmuscleconditionandposture';
import NdisPhysiotherapyTissuesMassagesToBreakUpTensionInMuscles from './components/ndisphysiotherapytissuesmassagestobreakuptensioninmuscles';
import NdisPhysiotherapyTrainingCapabilityInHandlingEverydayActionsAndTasks from './components/ndisphysiotherapytrainingcapabilityinhandlingeverydayactionsandtasks';
import Whatdoesatypicalndisphysiosessionlooklike from './components/whatdoesatypicalndisphysiosessionlooklike';
import Willmyndisphysiotherapistgivemeexercisestodoathome from './components/willmyndisphysiotherapistgivemeexercisestodoathome';

import PhysiotherapyTreatmentAndExercisesForAgedCarePhysiotherapy from './components/physiotherapytreatmentandexercisesforagedcarephysiotherapy';
import PhysiotherapyTreatmentAndExercisesForArthritis from './components/physiotherapytreatmentandexercisesforarthritis';
import PhysiotherapyTreatmentAndExercisesForBackPain from './components/physiotherapytreatmentandexercisesforbackpain';
import PhysiotherapyTreatmentAndExercisesForBalanceProblems from './components/physiotherapytreatmentandexercisesforbalanceproblems';
import PhysiotherapyTreatmentAndExercisesForChronicPain from './components/physiotherapytreatmentandexercisesforchronicpain';
import PhysiotherapyTreatmentAndExercisesForDryNeedling from './components/physiotherapytreatmentandexercisesfordryneedling';
import PhysiotherapyTreatmentAndExercisesForElbowPain from './components/physiotherapytreatmentandexercisesforelbowpain';
import PhysiotherapyTreatmentAndExercisesForFibromyalgia from './components/physiotherapytreatmentandexercisesforfibromyalgia';
import PhysiotherapyTreatmentAndExercisesForGaitAndBalance from './components/physiotherapytreatmentandexercisesforgaitandbalance';
import PhysiotherapyTreatmentAndExercisesForHeadaches from './components/physiotherapytreatmentandexercisesforheadaches';
import PhysiotherapyTreatmentAndExercisesForHipPain from './components/physiotherapytreatmentandexercisesforhippain';
import PhysiotherapyTreatmentAndExercisesForKneePain from './components/physiotherapytreatmentandexercisesforkneepain';
import PhysiotherapyTreatmentAndExercisesForLegPain from './components/physiotherapytreatmentandexercisesforlegpain';
import PhysiotherapyTreatmentAndExercisesForLowerBackPain from './components/physiotherapytreatmentandexercisesforlowerbackpain';
import PhysiotherapyTreatmentAndExercisesForMiddleBackPain from './components/physiotherapytreatmentandexercisesformiddlebackpain';
import PhysiotherapyTreatmentAndExercisesForMigraines from './components/physiotherapytreatmentandexercisesformigraines';
import PhysiotherapyTreatmentAndExercisesForMotorVehicleInjuries from './components/physiotherapytreatmentandexercisesformotorvehicleinjuries';
import PhysiotherapyTreatmentAndExercisesForNeckPain from './components/physiotherapytreatmentandexercisesforneckpain';
import PhysiotherapyTreatmentAndExercisesForPaediatricPhysiotherapy from './components/physiotherapytreatmentandexercisesforpaediatricphysiotherapy';
import Physiotherapytreatmentandexercisesforpostnatalphysiotherapy from './components/physiotherapytreatmentandexercisesforpostnatalphysiotherapy';
import PhysiotherapyTreatmentAndExercisesForPostSurgicalRehabilitation from './components/physiotherapytreatmentandexercisesforpostsurgicalrehabilitation';
import PhysiotherapyTreatmentAndExercisesForPostureCorrection from './components/physiotherapytreatmentandexercisesforposturecorrection';
import PhysiotherapyTreatmentAndExercisesForPregnancyCare from './components/physiotherapytreatmentandexercisesforpregnancycare';
import PhysiotherapyTreatmentAndExercisesForRemedialMassage from './components/physiotherapytreatmentandexercisesforremedialmassage';
import PhysiotherapyTreatmentAndExercisesForSciatica from './components/physiotherapytreatmentandexercisesforsciatica';
import PhysiotherapyTreatmentAndExercisesForShoulderPain from './components/physiotherapytreatmentandexercisesforshoulderpain';
import PhysiotherapyTreatmentAndExercisesForSidePain from './components/physiotherapytreatmentandexercisesforsidepain';
import PhysiotherapyTreatmentAndExercisesForSoftTissueAndMuscleInjuries from './components/physiotherapytreatmentandexercisesforsofttissueandmuscleinjuries';
import PhysiotherapyTreatmentAndExercisesForSportsInjuries from './components/physiotherapytreatmentandexercisesforsportsinjuries';
import PhysiotherapyTreatmentAndExercisesForSportsInjuryPrevention from './components/physiotherapytreatmentandexercisesforsportsinjuryprevention';
import PhysiotherapyTreatmentAndExercisesForUpperBackPain from './components/physiotherapytreatmentandexercisesforupperbackpain';
import PhysiotherapyTreatmentAndExercisesForWhatWeTreat from './components/physiotherapytreatmentandexercisesforwhatwetreat';
import PhysiotherapyTreatmentAndExercisesForWhiplash from './components/physiotherapytreatmentandexercisesforwhiplash';
import Physiotherapytreatmentandexercisesforwomenshealthphysiotherapy from './components/physiotherapytreatmentandexercisesforwomenshealthphysiotherapy';
import PhysiotherapyTreatmentAndExercisesForWristPain from './components/physiotherapytreatmentandexercisesforwristpain';



const App = () => {
  return (
    <Router>
      <div>
       <GoogleTagManager />
        <Header />
        <ScrollToTop /> 
        <Routes>
          {/* main page routes */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pricing" element={<About />} />
          <Route path="/schedule" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/join" element={<About />} />
          <Route path='/ndis' element={<NDIS />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/termsandconditions' element={<TermsandConditions />} />
          <Route path='/ultrasound' element={<Ultrasound />} />
          <Route path='/poorposture' element={<PoorPosture />} />
          <Route path='/postoprehab' element={<PostOpRehab />} />
          <Route path='/workcovernsw' element={<WorkcoverNSW />} />
          <Route path='/personaltraining' element={<PersonalTraining />} />
          <Route path='/remedialmassage' element={<RemedialMassage />} />
          <Route path='/sportsperformance' element={<SportsPerformance />} />
          <Route path='/workerscompensation' element={<WorkersCompensation />} />
          <Route path='/caraccidentinjuries' element={<CarAccidentInjuries />} />
          <Route path='/ems' element={<EMS />} />
          <Route path='/muscularstrains' element={<MuscularStrains />} />
          <Route path='/backpain' element={<BackPain />} />
          <Route path='/FAQ' element={<FAQ />} />
          <Route path="/australianconsumerlaw" element={<Australianconsumerlaw />} />
          {/* news page routes */}
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/news" element={<News />} />
          <Route path="/physiotherapy-in-homebush" element={<PhysiotherapyInHomebush />} />
          <Route path="/news/gymknowledge" element={<Gymknowledge />} />
          <Route path="/news/understandingphysiotherapy" element={<UnderstandingPhysiotherapy />} />
          <Route path="/news/rehabilitation" element={<Rehabilitation />} />
          <Route path="/news/benefitsofgymtraining" element={<BenefitsofGymTraining />} />
          <Route path="/news/physiotherapyforspecificconditions" element={<PhysiotherapyForSpecificConditions />} />
          <Route path="/news/ndisknowledge" element={<NDISKnowledge />} />
          <Route path="/news/intellectualanddevelopmental" element={<IntellectualAndDevelopmental />} />
          <Route path="/news/neurologicalconditions" element={<NeurologicalConditions />} />
          <Route path="/news/neuromuscularandchronic" element={<NeuromuscularAndChronic />} />
          <Route path="/news/mentalhealth" element={<MentalHealth />} />
          <Route path="/news/keytreatmentsandcommonquestions" element={<KeyTreatmentsForNDIS />} />
          <Route path="/news/frequentlyaskedquestions" element={<FrequentlyAskedQuestions />} />
          <Route path="/news/keytreatmentsforndis" element={<KeyTreatmentsForNDIS />} />
          <Route path="/news/acl" element={<ACL />} />
          <Route path="/news/aclinjuryphysiotherapy" element={<ACLinjuryphysiotherapy />} />
          <Route path="/news/aclrehab" element={<ACLrehabilitation />} />
          <Route path="/news/aclsurgery" element={<ACLsurgery />} />
          <Route path="/news/acltear" element={<ACLtear />} />
          <Route path="/news/correctiveexercisesforposture" element={<CorrectiveExercise />} />
          <Route path="/news/deeptissueremedialmassage" element={<Deeptissueremedialmassage />} />
          <Route path="/news/discbulge" element={<Discbulge />} />
          <Route path="/news/emsformusclerecovery" element={<Emsformusclerecovery />} />
          <Route path="/news/frozenshoulder" element={<Frozenshoulder />} />
          <Route path="/news/kneepainphysiotherapy" element={<Kneepainphysiotherapy />} />
          <Route path="/news/lumbartraction" element={<Lumbartraction />} />
          <Route path="/news/manualtherapytechniques" element={<Manualtherapytechniques />} />
          <Route path="/news/musculoskeletalphysiotherapy" element={<Musculoskeletalphysiotherapy />} />
          <Route path="/news/ndisphysiotherapy" element={<Ndisphysiotherapy />} />
          <Route path="/news/neckpain" element={<Neckpain />} />
          <Route path="/news/neurologicalphysiotherapy" element={<Neurologicalphysiotherapy />} />
          <Route path="/news/normatec3" element={<Normatec3 />} />
          <Route path="/news/normateccompression" element={<Normateccompression />} />
          <Route path="/news/physiotherapyclinicnearme" element={<Physiotherapyclinicnearme />} />
          <Route path="/news/physiotherapyexercises" element={<Physiotherapyexercises />} />
          <Route path="/news/physiotherapyforbackpain" element={<Physiotherapyforbackpain />} />
          <Route path="/news/physiotherapyforsciatica" element={<Physiotherapyforsciatica />} />
          <Route path="/news/physiotherapyforwhiplash" element={<Physiotherapyforwhiplash />} />
          <Route path="/news/plastercastingandcastremoval" element={<Plastercastingandcastremoval />} />  
          <Route path="/news/remedialmassageforbackpain" element={<Remedialmassageforbackpain />} />
          <Route path="/news/remedialmassagetherapy" element={<Remedialmassagetherapy />} />
          <Route path="/news/shockwavetherapy" element={<Shockwavetherapy />} />
          <Route path="/news/shockwavetherapyforpainrelief" element={<Shockwaveforpainrelief />} />
          <Route path="/news/shockwavetherapyforplantarfasciitis" element={<Shockwavetherapyforplantarfasciitis />} />
          <Route path="/news/sportsinjury" element={<Sportinjury />} />
          <Route path="/news/sportsperformanceenhancement" element={<Sportperformanceenhancement />} />
          <Route path="/news/sportsperformancephysiotherapy" element={<Sportperformancephysiotherapy />} />
          <Route path="/news/ultrasoundphysiotherapy" element={<Ultrasoundphysiotherapy />} />
          <Route path="/news/gymnearme" element={<Gymnearme />} />
          <Route path="/news/homebushphysiotherapy" element={<Homebushphysiotherapy />} />
          <Route path="/news/dryneedlingandacupuncture" element={<Dryneedlingandacupuncture />} />
          <Route path="/news/spinaldecompressiontherapy" element={<Spinaldecompressiontherapy />} />
          <Route path="/news/sciaticatreatment" element={<Sciaticatreatment />} />
          <Route path="/news/facetsyndrometreatment" element={<Facetsyndrometreatment />} />
          <Route path="/news/degenerativediscs" element={<Degenerativediscs />} />
          <Route path="/news/managingsciatica" element={<Managingsciatica />} />
          <Route path="/news/Tmjphysiotherapy" element={<Tmjphysiotherapy />} />
          <Route path="/news/physiotherapyforarthritispainmanagement" element={<Physiotherapyforarthritispainmanagement />} />
        
          <Route path="/news/anklespraintreatmentandprevention" element={<AnkleSprainTreatmentAndPrevention />} />
          <Route path="/news/balanceandfallpreventionstrategiesforseniors" element={<BalanceAndFallPreventionStrategiesForSeniors />} />
          <Route path="/news/benefitsofproprioceptivetrainingininjuryprevention" element={<BenefitsOfProprioceptiveTrainingInInjuryPrevention />} />
          <Route path="/news/benefitsofregularphysiotherapyassessments" element={<BenefitsOfRegularPhysiotherapyAssessments />} />
          <Route path="/news/corestrengtheningexercisesforlowerbacksupport" element={<CoreStrengtheningExercisesForLowerBackSupport />} />
          <Route path="/news/ergonomicsandworkplaceinjuryprevention" element={<ErgonomicsAndWorkplaceInjuryPrevention />} />
          <Route path="/news/importanceofwarm-upandcool-downexercises" element={<ImportanceofwarmUpandcoolDownexercises />} />
          <Route path="/news/lymphaticdrainagemassagetherapy" element={<LymphaticDrainageMassageTherapy />} />
          <Route path="/news/managementofchronicpelvicpainsyndrome" element={<ManagementOfChronicPelvicPainSyndrome />} />
          <Route path="/news/managingchronicpainwithphysiotherapy" element={<ManagingChronicPainWithPhysiotherapy />} />
          <Route path="/news/managingchronictendonitiswithphysiotherapy" element={<ManagingChronicTendonitisWithPhysiotherapy />} />
          <Route path="/news/managingfibromyalgiasymptomswithphysiotherapy" element={<ManagingFibromyalgiaSymptomsWithPhysiotherapy />} />
          <Route path="/news/managinghypermobilitysyndromewithphysiotherapy" element={<ManagingHypermobilitySyndromeWithPhysiotherapy />} />
          <Route path="/news/managinglymphedemawithphysiotherapy" element={<ManagingLymphedemaWithPhysiotherapy />} />
          <Route path="/news/managingmultiplesclerosissymptomswithphysiotherapy" element={<ManagingMultipleSclerosisSymptomsWithPhysiotherapy />} />
          <Route path="/news/managingplantarwartswithphysiotherapy" element={<ManagingPlantarWartsWithPhysiotherapy />} />
          <Route path="/news/managingsciaticawithphysiotherapyexercises" element={<ManagingSciaticaWithPhysiotherapyExercises />} />
          <Route path="/news/managingstressincontinencewithphysiotherapy" element={<ManagingStressIncontinenceWithPhysiotherapy />} />
          <Route path="/news/mind-bodyconnection" element={<MindBodyconnection />} />
          <Route path="/news/neuromuscularelectricalstimulationinrehabilitation" element={<NeuromuscularElectricalStimulationInRehabilitation />} />
          <Route path="/news/pediatricphysiotherapy" element={<Pediatricphysiotherapy />} />
          <Route path="/news/physiotherapyandgymforcorestrengthandstability" element={<PhysiotherapyAndGymForCoreStrengthAndStability />} />
          <Route path="/news/physiotherapyapproachestotreatingbursitis" element={<PhysiotherapyApproachesToTreatingBursitis />} />
          <Route path="/news/physiotherapyapproachestotreatingspondylolisthesis" element={<PhysiotherapyApproachesToTreatingSpondylolisthesis />} />
          <Route path="/news/physiotherapyforankylosingspondylitis" element={<PhysiotherapyForAnkylosingSpondylitis />} />
          <Route path="/news/physiotherapyforarthritispainmanagement" element={<PhysiotherapyForArthritisPainManagement />} />
          <Route path="/news/physiotherapyforbellspalsyrecovery" element={<Physiotherapyforbellspalsyrecovery />} />
          <Route path="/news/physiotherapyforcarpaltunnelsyndromerelief" element={<PhysiotherapyForCarpalTunnelSyndromeRelief />} />
          <Route path="/news/physiotherapyforcerebralpalsy" element={<PhysiotherapyForCerebralPalsy />} />
          <Route path="/news/physiotherapyforchronicfatiguesyndromemecfs" element={<Physiotherapyforchronicfatiguesyndromemecfs />} />
          <Route path="/news/physiotherapyforchronicnecktensionandheadaches" element={<PhysiotherapyForChronicNeckTensionAndHeadaches />} />
          <Route path="/news/physiotherapyforchronicobstructivepulmonarydiseasecopd" element={<Physiotherapyforchronicobstructivepulmonarydiseasecopd />} />
          <Route path="/news/physiotherapyfordupuytrenscontracture" element={<Physiotherapyfordupuytrenscontracture />} />
          <Route path="/news/physiotherapyforfacialnervepalsy" element={<PhysiotherapyForFacialNervePalsy />} />
          <Route path="/news/physiotherapyforflatfeetpesplanus" element={<Physiotherapyforflatfeetpesplanus />} />
          <Route path="/news/physiotherapyforgolferselbow" element={<Physiotherapyforgolferselbow />} />
          <Route path="/news/physiotherapyforguillainbarrsyndromerecovery" element={<Physiotherapyforguillainbarrsyndromerecovery />} />
          <Route path="/news/physiotherapyforheadachesandmigraines" element={<PhysiotherapyForHeadachesAndMigraines />} />
          <Route path="/news/physiotherapyforheelspurs" element={<PhysiotherapyForHeelSpurs />} />
          <Route path="/news/physiotherapyforhyperkyphosishunchback" element={<Physiotherapyforhyperkyphosishunchback />} />
          <Route path="/news/physiotherapyforjuvenilearthritis" element={<PhysiotherapyForJuvenileArthritis />} />
          <Route path="/news/physiotherapyforlabraltearsinthehip" element={<PhysiotherapyForLabralTearsInTheHip />} />
          <Route path="/news/physiotherapyforlateralepicondylitistenniselbow" element={<Physiotherapyforlateralepicondylitistenniselbow />} />
          <Route path="/news/physiotherapyformortonsneuroma" element={<Physiotherapyformortonsneuroma />} />
          <Route path="/news/physiotherapyforosteitispubisinathletes" element={<PhysiotherapyForOsteitisPubisInAthletes />} />
          <Route path="/news/physiotherapyforosteoporosis" element={<PhysiotherapyForOsteoporosis />} />
          <Route path="/news/physiotherapyforparkinsonsdisease" element={<Physiotherapyforparkinsonsdisease />} />
          <Route path="/news/physiotherapyforpatellardislocation" element={<PhysiotherapyForPatellarDislocation />} />
          <Route path="/news/physiotherapyforpelvicpain" element={<PhysiotherapyForPelvicPain />} />
          <Route path="/news/physiotherapyforperipheralneuropathy" element={<PhysiotherapyForPeripheralNeuropathy />} />
          <Route path="/news/physiotherapyforrespiratoryconditions" element={<Physiotherapyforrespiratoryconditions />} />
          <Route path="/news/physiotherapyforrheumatoidarthritis" element={<PhysiotherapyForRheumatoidArthritis />} />
          <Route path="/news/physiotherapyforrunnerskneepatellofemoralpainsyndrome" element={<Physiotherapyforrunnerskneepatellofemoralpainsyndrome />} />
          <Route path="/news/physiotherapyforsacroiliacjointdysfunction" element={<PhysiotherapyForSacroiliacJointDysfunction />} />
          <Route path="/news/physiotherapyforscheuermannsdisease" element={<Physiotherapyforscheuermannsdisease />} />
          <Route path="/news/physiotherapyforscoliosismanagement" element={<PhysiotherapyForScoliosisManagement />} />
          <Route path="/news/physiotherapyforshoulderimpingementsyndrome" element={<PhysiotherapyForShoulderImpingementSyndrome />} />
          <Route path="/news/physiotherapyforspinalcordinjuries" element={<PhysiotherapyForSpinalCordInjuries />} />
          <Route path="/news/physiotherapyforstrokerehabilitation" element={<PhysiotherapyForStrokeRehabilitation />} />
          <Route path="/news/physiotherapyfortemporomandibularjoint(tmj)disorders" element={<PhysiotherapyForTemporomandibularJointTmjDisorders />} />
          <Route path="/news/physiotherapyfortenniselbow" element={<PhysiotherapyForTennisElbow />} />
          <Route path="/news/physiotherapyforthoracicoutletsyndrome" element={<PhysiotherapyForThoracicOutletSyndrome />} />
          <Route path="/news/physiotherapyfortorticollisininfants" element={<PhysiotherapyForTorticollisInInfants />} />
          <Route path="/news/physiotherapyforwhiplashassociateddisorders" element={<Physiotherapyforwhiplashassociateddisorders />} />
          <Route path="/news/physiotherapyinoccupationalhealth" element={<PhysiotherapyInOccupationalHealth />} />
          <Route path="/news/physiotherapytechniquesformanagingedema" element={<PhysiotherapyTechniquesForManagingEdema />} />
          <Route path="/news/pre-andpost-operativephysiotherapy" element={<PreAndpostOperativephysiotherapy />} />
          <Route path="/news/preventingsportsinjuries" element={<Preventingsportsinjuries />} />
          <Route path="/news/rehabilitationafteranklefracture" element={<RehabilitationAfterAnkleFracture />} />
          <Route path="/news/rehabilitationafterfemurfracture" element={<RehabilitationAfterFemurFracture />} />
          <Route path="/news/rehabilitationafterhipreplacementsurgery" element={<RehabilitationAfterHipReplacementSurgery />} />
          <Route path="/news/rehabilitationafterkneereplacementsurgery" element={<RehabilitationAfterKneeReplacementSurgery />} />
          <Route path="/news/rehabilitationaftermeniscussurgery" element={<RehabilitationAfterMeniscusSurgery />} />
          <Route path="/news/rehabilitationafterrotatorcuffsurgery" element={<RehabilitationAfterRotatorCuffSurgery />} />
          <Route path="/news/rehabilitationaftershoulderreplacementsurgery" element={<RehabilitationAfterShoulderReplacementSurgery />} />
          <Route path="/news/rehabilitationafterwristfracture" element={<RehabilitationAfterWristFracture />} />
          <Route path="/news/rehabilitationstrategiesformuscleatrophy" element={<RehabilitationStrategiesForMuscleAtrophy />} />
          <Route path="/news/rehabilitationstrategiesforspinalstenosis" element={<RehabilitationStrategiesForSpinalStenosis />} />
          <Route path="/news/rehabilitationtechniquesforachillestendonrupture" element={<RehabilitationTechniquesForAchillesTendonRupture />} />
          <Route path="/news/rehabilitationtechniquesforrotatorcuffinjuries" element={<RehabilitationTechniquesForRotatorCuffInjuries />} />
          <Route path="/news/thebenefitsoffunctionalmovementscreening" element={<TheBenefitsOfFunctionalMovementScreening />} />
          <Route path="/news/thebenefitsofisokinetictestinginrehabilitation" element={<TheBenefitsOfIsokineticTestingInRehabilitation />} />
          <Route path="/news/theimportanceofgaitanalysisinphysiotherapy" element={<TheImportanceOfGaitAnalysisInPhysiotherapy />} />
          <Route path="/news/theroleoforthoticsinphysiotherapy" element={<TheRoleOfOrthoticsInPhysiotherapy />} />
          <Route path="/news/theroleofphysiotherapyinmanagingdiabetes" element={<TheRoleOfPhysiotherapyInManagingDiabetes />} />
          <Route path="/news/understandingandtreatingosgoodschlatterdisease" element={<Understandingandtreatingosgoodschlatterdisease />} />
          <Route path="/news/understandingandtreatingplantarfasciitis" element={<UnderstandingAndTreatingPlantarFasciitis />} />
          <Route path="/news/understandingbiofeedbackinphysiotherapy" element={<UnderstandingBiofeedbackInPhysiotherapy />} />
          <Route path="/news/understandingbiomechanicsinsportsphysiotherapy" element={<UnderstandingBiomechanicsInSportsPhysiotherapy />} />
          <Route path="/news/understandingcraniosacraltherapyinphysiotherapy" element={<UnderstandingCraniosacralTherapyInPhysiotherapy />} />
          <Route path="/news/understandingkinesiotaping" element={<Understandingkinesiotaping />} />
          <Route path="/news/understandingmyofascialreleasetechniques" element={<UnderstandingMyofascialReleaseTechniques />} />
          <Route path="/news/understandingtenstherapyinphysiotherapy" element={<UnderstandingTensTherapyInPhysiotherapy />} />
          <Route path="/news/understandingvestibulardisordersandphysiotherapytreatments" element={<UnderstandingVestibularDisordersAndPhysiotherapyTreatments />} />
          <Route path="/news/vestibularrehabilitationfordizzinessandvertigo" element={<VestibularRehabilitationForDizzinessAndVertigo />} />
          <Route path="/news/womenshealthphysiotherapy" element={<Womenshealthphysiotherapy />} />

          <Route path="/news/adaptinggymworkoutsforhomeexercise" element={<AdaptingGymWorkoutsForHomeExercise />} />
          <Route path="/news/adaptingworkoutsforchronicconditions" element={<AdaptingWorkoutsForChronicConditions />} />
          <Route path="/news/adaptingworkoutsforinjuriesandphysicallimitations" element={<AdaptingWorkoutsForInjuriesAndPhysicalLimitations />} />
          <Route path="/news/advancedtrainingtechniquessupersetsdropsetsandmore" element={<Advancedtrainingtechniquessupersetsdropsetsandmore />} />
          <Route path="/news/balancingcardioandstrengthtraining" element={<BalancingCardioAndStrengthTraining />} />
          <Route path="/news/beginnersguidetostartingagymroutine" element={<Beginnersguidetostartingagymroutine />} />
          <Route path="/news/benefitsoffunctionaltrainingexercises" element={<BenefitsOfFunctionalTrainingExercises />} />
          <Route path="/news/benefitsofoutdoorvsindoorworkouts" element={<Benefitsofoutdoorvsindoorworkouts />} />
          <Route path="/news/benefitsofpersonaltrainingsessions" element={<BenefitsOfPersonalTrainingSessions />} />
          <Route path="/news/buildinganeffectivewarmupandcooldownroutine" element={<Buildinganeffectivewarmupandcooldownroutine />} />
          <Route path="/news/buildingmindmuscleconnectionforbetterresults" element={<Buildingmindmuscleconnectionforbetterresults />} />
          <Route path="/news/buildingmuscleonaplantbaseddiet" element={<Buildingmuscleonaplantbaseddiet />} />
          <Route path="/news/cardiovsstrengthtrainingwhatsbestforyou" element={<Cardiovsstrengthtrainingwhatsbestforyou />} />
          <Route path="/news/circuittrainingbenefitsandsampleworkouts" element={<Circuittrainingbenefitsandsampleworkouts />} />
          <Route path="/news/creatingabalancedgymworkoutplan" element={<CreatingABalancedGymWorkoutPlan />} />
          <Route path="/news/developingastrongcoreexercisesbeyondsitups" element={<Developingastrongcoreexercisesbeyondsitups />} />
          <Route path="/news/effectivegymroutinesforbeginners" element={<EffectiveGymRoutinesForBeginners />} />
          <Route path="/news/effectivegymworkoutsforbusyprofessionals" element={<EffectiveGymWorkoutsForBusyProfessionals />} />
          <Route path="/news/effectivestretchingtechniquesforflexibility" element={<EffectiveStretchingTechniquesForFlexibility />} />
          <Route path="/news/effectiveweightlossworkoutsatthegym" element={<EffectiveWeightLossWorkoutsAtTheGym />} />
          <Route path="/news/exercisesforhealthykneesandinjuryprevention" element={<ExercisesForHealthyKneesAndInjuryPrevention />} />
          <Route path="/news/exercisestoalleviateneckandshouldertension" element={<ExercisesToAlleviateNeckAndShoulderTension />} />
          <Route path="/news/exercisestoenhancelungcapacity" element={<ExercisesToEnhanceLungCapacity />} />
          <Route path="/news/exercisestoimprovehandeyecoordination" element={<Exercisestoimprovehandeyecoordination />} />
          <Route path="/news/exercisestoimprovejointhealthandlongevity" element={<ExercisesToImproveJointHealthAndLongevity />} />
          <Route path="/news/gymetiquettedosanddonts" element={<Gymetiquettedosanddonts />} />
          <Route path="/news/gymetiquetteunwrittenruleseverymembershouldknow" element={<Gymetiquetteunwrittenruleseverymembershouldknow />} />
          <Route path="/news/gymexercisestostrengthenyourbackandreducepain" element={<GymExercisesToStrengthenYourBackAndReducePain />} />
          <Route path="/news/gymstrategiesforenhancingmentalclarity" element={<GymStrategiesForEnhancingMentalClarity />} />
          <Route path="/news/gymtrainingforhormonalbalance" element={<GymTrainingForHormonalBalance />} />
          <Route path="/news/gymtrainingforweightlosstipsandtricks" element={<Gymtrainingforweightlosstipsandtricks />} />
          <Route path="/news/gymworkoutsduringpregnancysafetytips" element={<Gymworkoutsduringpregnancysafetytips />} />
          <Route path="/news/gymworkoutsforbetterbonedensity" element={<GymWorkoutsForBetterBoneDensity />} />
          <Route path="/news/gymworkoutsforboostingmetabolism" element={<GymWorkoutsForBoostingMetabolism />} />
          <Route path="/news/gymworkoutsforbuildingendurance" element={<GymWorkoutsForBuildingEndurance />} />
          <Route path="/news/gymworkoutsforbuildingleanmuscle" element={<GymWorkoutsForBuildingLeanMuscle />} />
          <Route path="/news/gymworkoutsforcombattingsedentarylifestyles" element={<GymWorkoutsForCombattingSedentaryLifestyles />} />
          <Route path="/news/gymworkoutsforenhancingflexibility" element={<GymWorkoutsForEnhancingFlexibility />} />
          <Route path="/news/gymworkoutsforimprovingagilityandspeed" element={<GymWorkoutsForImprovingAgilityAndSpeed />} />
          <Route path="/news/gymworkoutsforimprovingbalanceandcoordination" element={<GymWorkoutsForImprovingBalanceAndCoordination />} />
          <Route path="/news/gymworkoutsforimprovingcardiovascularhealth" element={<GymWorkoutsForImprovingCardiovascularHealth />} />
          <Route path="/news/gymworkoutsforimprovingsleepquality" element={<GymWorkoutsForImprovingSleepQuality />} />
          <Route path="/news/gymworkoutsformanagingdiabetes" element={<GymWorkoutsForManagingDiabetes />} />
          <Route path="/news/gymworkoutsforposturecorrection" element={<GymWorkoutsForPostureCorrection />} />
          <Route path="/news/gymworkoutsforrehabilitationandinjuryrecovery" element={<GymWorkoutsForRehabilitationAndInjuryRecovery />} />
          <Route path="/news/gymworkoutsforseniorsstayingfitatanyage" element={<Gymworkoutsforseniorsstayingfitatanyage />} />
          <Route path="/news/gymworkoutsforstressrelief" element={<GymWorkoutsForStressRelief />} />
          <Route path="/news/gymworkoutsforteenagersbuildinghealthyhabits" element={<Gymworkoutsforteenagersbuildinghealthyhabits />} />
          <Route path="/news/gymworkoutstailoredforseniors" element={<GymWorkoutsTailoredForSeniors />} />
          <Route path="/news/gymworkoutstoimproveposture" element={<GymWorkoutsToImprovePosture />} />
          <Route path="/news/highintensityfunctionaltraininghiftexplained" element={<Highintensityfunctionaltraininghiftexplained />} />
          <Route path="/news/highintensityintervaltraininghiitexplained" element={<Highintensityintervaltraininghiitexplained />} />
          <Route path="/news/howtocreateapersonalizedworkoutplan" element={<HowToCreateAPersonalizedWorkoutPlan />} />
          <Route path="/news/howtoovercomegymanxiety" element={<HowToOvercomeGymAnxiety />} />
          <Route path="/news/howtotrackandmeasurefitnessprogress" element={<HowToTrackAndMeasureFitnessProgress />} />
          <Route path="/news/howtousefreeweightssafely" element={<HowToUseFreeWeightsSafely />} />
          <Route path="/news/hydrationstrategiesforoptimalperformance" element={<HydrationStrategiesForOptimalPerformance />} />
          <Route path="/news/improvingflexibilityandmobilitythroughstretching" element={<ImprovingFlexibilityAndMobilityThroughStretching />} />
          <Route path="/news/incorporatingdancefitnessforfunworkouts" element={<IncorporatingDanceFitnessForFunWorkouts />} />
          <Route path="/news/incorporatingflexibilityandmobilityintoyourroutine" element={<IncorporatingFlexibilityAndMobilityIntoYourRoutine />} />
          <Route path="/news/incorporatingmindfulnessandmeditationintofitness" element={<IncorporatingMindfulnessAndMeditationIntoFitness />} />
          <Route path="/news/incorporatingplyometricsforexplosivepower" element={<IncorporatingPlyometricsForExplosivePower />} />
          <Route path="/news/managingstressthroughexercise" element={<ManagingStressThroughExercise />} />
          <Route path="/news/maximizingfatlosswithhighintensityintervaltraininghiit" element={<Maximizingfatlosswithhighintensityintervaltraininghiit />} />
          <Route path="/news/mindmuscleconnectionenhancingworkouteffectiveness" element={<Mindmuscleconnectionenhancingworkouteffectiveness />} />
          <Route path="/news/nutritionmythsdebunkedfactsforbetterfitness" element={<Nutritionmythsdebunkedfactsforbetterfitness />} />
          <Route path="/news/nutritiontimingpreandpostworkoutmeals" element={<Nutritiontimingpreandpostworkoutmeals />} />
          <Route path="/news/nutritiontipsforvegetariansandvegansinfitness" element={<NutritionTipsForVegetariansAndVegansInFitness />} />
          <Route path="/news/optimizingfatlosswithcircuittraining" element={<OptimizingFatLossWithCircuitTraining />} />
          <Route path="/news/overcomingplateausstrategiestokeepprogressing" element={<Overcomingplateausstrategiestokeepprogressing />} />
          <Route path="/news/preventingcommongyminjuries" element={<PreventingCommonGymInjuries />} />
          <Route path="/news/seasonalfitnessadjustingyourworkoutroutinethroughouttheyear" element={<Seasonalfitnessadjustingyourworkoutroutinethroughouttheyear />} />
          <Route path="/news/settingrealisticfitnessgoalsandhowtoachievethem" element={<SettingRealisticFitnessGoalsAndHowToAchieveThem />} />
          <Route path="/news/stayingmotivatedonyourfitnessjourney" element={<StayingMotivatedOnYourFitnessJourney />} />
          <Route path="/news/stayingmotivatedsettingandachievingfitnessgoals" element={<Stayingmotivatedsettingandachievingfitnessgoals />} />
          <Route path="/news/strategiesforhealthyweightgain" element={<StrategiesForHealthyWeightGain />} />
          <Route path="/news/strategiesforstayingfitwhiletraveling" element={<StrategiesForStayingFitWhileTraveling />} />
          <Route path="/news/strengthtrainingforwomendebunkingmyths" element={<Strengthtrainingforwomendebunkingmyths />} />
          <Route path="/news/strengthtrainingvsbodybuildingwhatsthedifference" element={<Strengthtrainingvsbodybuildingwhatsthedifference />} />
          <Route path="/news/thebenefitsoffoamrollingandselfmyofascialrelease" element={<Thebenefitsoffoamrollingandselfmyofascialrelease />} />
          <Route path="/news/thebenefitsofhighproteindietsintraining" element={<Thebenefitsofhighproteindietsintraining />} />
          <Route path="/news/thebenefitsofresistancebandtraining" element={<TheBenefitsOfResistanceBandTraining />} />
          <Route path="/news/theimpactofalcoholonfitnessandrecovery" element={<TheImpactOfAlcoholOnFitnessAndRecovery />} />
          <Route path="/news/theimpactofmusiconworkoutperformance" element={<TheImpactOfMusicOnWorkoutPerformance />} />
          <Route path="/news/theimpactofsleeponmusclerecoveryandperformance" element={<TheImpactOfSleepOnMuscleRecoveryAndPerformance />} />
          <Route path="/news/theimpactoftechnologyonmodernfitness" element={<TheImpactOfTechnologyOnModernFitness />} />
          <Route path="/news/theimportanceofconsistencyinfitness" element={<TheImportanceOfConsistencyInFitness />} />
          <Route path="/news/theimportanceoffiberinathleticperformance" element={<TheImportanceOfFiberInAthleticPerformance />} />
          <Route path="/news/theimportanceofgoalsettinginfitness" element={<TheImportanceOfGoalSettingInFitness />} />
          <Route path="/news/theimportanceofproperhydrationduringworkouts" element={<TheImportanceOfProperHydrationDuringWorkouts />} />
          <Route path="/news/theimportanceofrestandrecoveryintraining" element={<TheImportanceOfRestAndRecoveryInTraining />} />
          <Route path="/news/theimportanceofrestdaysandactiverecovery" element={<TheImportanceOfRestDaysAndActiveRecovery />} />
          <Route path="/news/theimportanceofwarmupandcooldowninyourgymsession" element={<Theimportanceofwarmupandcooldowninyourgymsession />} />
          <Route path="/news/theroleofantioxidantsinmusclerecovery" element={<TheRoleOfAntioxidantsInMuscleRecovery />} />
          <Route path="/news/theroleoffunctionalmovementscreening" element={<TheRoleOfFunctionalMovementScreening />} />
          <Route path="/news/theroleofguthealthinfitnessandwellbeing" element={<TheRoleOfGutHealthInFitnessAndWellbeing />} />
          <Route path="/news/theroleofhealthyfatsinfitnessnutrition" element={<TheRoleOfHealthyFatsInFitnessNutrition />} />
          <Route path="/news/theroleofmentalhealthinphysicalfitness" element={<TheRoleOfMentalHealthInPhysicalFitness />} />
          <Route path="/news/theroleofnutritioninachievingfitnessgoals" element={<TheRoleOfNutritionInAchievingFitnessGoals />} />
          <Route path="/news/theroleofnutritioninfitness" element={<TheRoleOfNutritionInFitness />} />
          <Route path="/news/thesciencebehindmusclehypertrophy" element={<TheScienceBehindMuscleHypertrophy />} />
          <Route path="/news/timeefficientworkoutsforbusyschedules" element={<Timeefficientworkoutsforbusyschedules />} />
          <Route path="/news/top10exercisesforbuildingmusclemass" element={<Top10ExercisesForBuildingMuscleMass />} />
          <Route path="/news/topexercisesforstrengtheningyourcore" element={<TopExercisesForStrengtheningYourCore />} />
          <Route path="/news/topsupplementstosupportyourfitnessjourney" element={<TopSupplementsToSupportYourFitnessJourney />} />
          <Route path="/news/trackingyourfitnessprogresswithtechnology" element={<TrackingYourFitnessProgressWithTechnology />} />
          <Route path="/news/trainingforendurancesportsatthegym" element={<TrainingForEnduranceSportsAtTheGym />} />
          <Route path="/news/trainingforfunctionalstrengthanddailyactivities" element={<TrainingForFunctionalStrengthAndDailyActivities />} />
          <Route path="/news/trainingforobstaclecourseracesegtoughmudder" element={<Trainingforobstaclecourseracesegtoughmudder />} />
          <Route path="/news/trainingforspecificsportstailoredgymprograms" element={<Trainingforspecificsportstailoredgymprograms />} />
          <Route path="/news/trainingprogramsforimprovingreactiontime" element={<TrainingProgramsForImprovingReactionTime />} />
          <Route path="/news/trainingprogramsforstrengtheningtheimmunesystem" element={<TrainingProgramsForStrengtheningTheImmuneSystem />} />
          <Route path="/news/trainingstrategiesforenduranceathletes" element={<TrainingStrategiesForEnduranceAthletes />} />
          <Route path="/news/trainingtipsformarathonpreparation" element={<TrainingTipsForMarathonPreparation />} />
          <Route path="/news/trainingwithfreeweightsvsmachines" element={<Trainingwithfreeweightsvsmachines />} />
          <Route path="/news/trainingwithkettlebellsexercisesandbenefits" element={<Trainingwithkettlebellsexercisesandbenefits />} />
          <Route path="/news/trainingwithmedicineballsversatileexercises" element={<Trainingwithmedicineballsversatileexercises />} />
          <Route path="/news/trainingwithsandbagsunstableloadbenefits" element={<Trainingwithsandbagsunstableloadbenefits />} />
          <Route path="/news/trainingwithsuspensiontrainersegtrx" element={<Trainingwithsuspensiontrainersegtrx />} />
          <Route path="/news/understandingandpreventingovertraining" element={<UnderstandingAndPreventingOvertraining />} />
          <Route path="/news/understandingandutilizingactiverecoverydays" element={<UnderstandingAndUtilizingActiveRecoveryDays />} />
          <Route path="/news/understandingandutilizinggymequipmentsafely" element={<UnderstandingAndUtilizingGymEquipmentSafely />} />
          <Route path="/news/understandingbodycompositionanditsroleinfitness" element={<UnderstandingBodyCompositionAndItsRoleInFitness />} />
          <Route path="/news/understandingbodycompositionandsettingfitnessgoals" element={<UnderstandingBodyCompositionAndSettingFitnessGoals />} />
          <Route path="/news/understandingbodyweighttrainingbenefits" element={<UnderstandingBodyweightTrainingBenefits />} />
          <Route path="/news/understandingcaloricneedsfordifferentfitnessgoals" element={<UnderstandingCaloricNeedsForDifferentFitnessGoals />} />
          <Route path="/news/understandingdifferenttrainingtechniquessupersetsdropsetsandmore" element={<Understandingdifferenttrainingtechniquessupersetsdropsetsandmore />} />
          <Route path="/news/understandingheartratezonesforoptimaltraining" element={<UnderstandingHeartRateZonesForOptimalTraining />} />
          <Route path="/news/understandingintermittentfastingandfitness" element={<UnderstandingIntermittentFastingAndFitness />} />
          <Route path="/news/understandingmacrosproteincarbsandfats" element={<Understandingmacrosproteincarbsandfats />} />
          <Route path="/news/understandingtheglycemicindexforenergymanagement" element={<UnderstandingTheGlycemicIndexForEnergyManagement />} />
          <Route path="/news/understandingvo2maxandcardiovascularfitness" element={<UnderstandingVo2MaxAndCardiovascularFitness />} />
          <Route path="/news/workouttipsforbuildingendurance" element={<WorkoutTipsForBuildingEndurance />} />
          <Route path="/news/rampfitnesspersonaltraining" element={<Rampfitnesspersonaltraining />} />
        
          <Route path="/news/howoftenwillineedtoseemyndisphysio" element={<Howoftenwillineedtoseemyndisphysio />} />
          <Route path="/news/ndisphysiotherapyassistedstretchingtorelievestiffnessinjoints" element={<NdisPhysiotherapyAssistedStretchingToRelieveStiffnessInJoints />} />
          <Route path="/news/ndisphysiotherapyelectrotherapyandheattherapytoreduceinflammation" element={<NdisPhysiotherapyElectrotherapyAndHeatTherapyToReduceInflammation />} />
          <Route path="/news/ndisphysiotherapyforacquiredandtraumaticbraininjuries" element={<NdisPhysiotherapyForAcquiredAndTraumaticBrainInjuries />} />
          <Route path="/news/ndisphysiotherapyforangelmanssyndrome" element={<Ndisphysiotherapyforangelmanssyndrome />} />
          <Route path="/news/ndisphysiotherapyforanxiety" element={<NdisPhysiotherapyForAnxiety />} />
          <Route path="/news/ndisphysiotherapyforautismspectrumdisorder" element={<Ndisphysiotherapyforautismspectrumdisorder />} />
          <Route path="/news/ndisphysiotherapyforadhd" element={<Ndisphysiotherapyforadhd />} />
          <Route path="/news/ndisphysiotherapyforbardetbiedisyndrome" element={<Ndisphysiotherapyforbardetbiedisyndrome />} />
          <Route path="/news/ndisphysiotherapyforbipolardisorder" element={<NdisPhysiotherapyForBipolarDisorder />} />
          <Route path="/news/ndisphysiotherapyforcerebralpalsy" element={<NdisPhysiotherapyForCerebralPalsy />} />
          <Route path="/news/ndisphysiotherapyforchromosomaldisorders" element={<NdisPhysiotherapyForChromosomalDisorders />} />
          <Route path="/news/ndisphysiotherapyforchronicfatigue" element={<NdisPhysiotherapyForChronicFatigue />} />
          <Route path="/news/ndisphysiotherapyfordementia" element={<NdisPhysiotherapyForDementia />} />
          <Route path="/news/ndisphysiotherapyfordepression" element={<NdisPhysiotherapyForDepression />} />
          <Route path="/news/ndisphysiotherapyfordevelopmentaldelay" element={<NdisPhysiotherapyForDevelopmentalDelay />} />
          <Route path="/news/ndisphysiotherapyfordisorderedeating" element={<NdisPhysiotherapyForDisorderedEating />} />
          <Route path="/news/ndisphysiotherapyfordownsyndrome" element={<NdisPhysiotherapyForDownSyndrome />} />
          <Route path="/news/ndisphysiotherapyforepilepsy" element={<NdisPhysiotherapyForEpilepsy />} />
          <Route path="/news/ndisphysiotherapyforfibromyalgia" element={<NdisPhysiotherapyForFibromyalgia />} />
          <Route path="/news/ndisphysiotherapyforfoetalalcoholspectrumsyndrome" element={<NdisPhysiotherapyForFoetalAlcoholSpectrumSyndrome />} />
          <Route path="/news/ndisphysiotherapyforfragilexsyndrome" element={<NdisPhysiotherapyForFragileXSyndrome />} />
          <Route path="/news/ndisphysiotherapyforguillainbarresyndrome" element={<NdisPhysiotherapyForGuillainBarreSyndrome />} />
          <Route path="/news/ndisphysiotherapyforhuntingtonsdisease" element={<Ndisphysiotherapyforhuntingtonsdisease />} />
          <Route path="/news/ndisphysiotherapyforintellectualdisabilities" element={<NdisPhysiotherapyForIntellectualDisabilities />} />
          <Route path="/news/ndisphysiotherapyformentalhealthconditions" element={<NdisPhysiotherapyForMentalHealthConditions />} />
          <Route path="/news/ndisphysiotherapyformotorneuronedisease" element={<NdisPhysiotherapyForMotorNeuroneDisease />} />
          <Route path="/news/ndisphysiotherapyformultiplesclerosis" element={<NdisPhysiotherapyForMultipleSclerosis />} />
          <Route path="/news/ndisphysiotherapyformusculardystrophy" element={<NdisPhysiotherapyForMuscularDystrophy />} />
          <Route path="/news/ndisphysiotherapyforndisphysiotherapy" element={<NdisPhysiotherapyProvider />} />
          <Route path="/news/ndisphysiotherapyforneurologicalconditions" element={<NdisPhysiotherapyForNeurologicalConditions />} />
          <Route path="/news/ndisphysiotherapyforparkinsonsdisease" element={<Ndisphysiotherapyforparkinsonsdisease />} />
          <Route path="/news/ndisphysiotherapyforperipheralneuropathy" element={<NdisPhysiotherapyForPeripheralNeuropathy />} />
          <Route path="/news/ndisphysiotherapyforpersonalitydisorders" element={<NdisPhysiotherapyForPersonalityDisorders />} />
          <Route path="/news/ndisphysiotherapyforpraderwillisyndrome" element={<Ndisphysiotherapyforpraderwillisyndrome />} />
          <Route path="/news/ndisphysiotherapyforpsychoticillnesses" element={<NdisPhysiotherapyForPsychoticIllnesses />} />
          <Route path="/news/ndisphysiotherapyforptsd" element={<NdisPhysiotherapyForPtsd />} />
          <Route path="/news/ndisphysiotherapyforschizophrenia" element={<NdisPhysiotherapyForSchizophrenia />} />
          <Route path="/news/ndisphysiotherapyforspinalcordinjuries" element={<NdisPhysiotherapyForSpinalCordInjuries />} />
          <Route path="/news/ndisphysiotherapyforstroke" element={<NdisPhysiotherapyForStroke />} />
          <Route path="/news/ndisphysiotherapyforwilliamssyndrome" element={<NdisPhysiotherapyForWilliamsSyndrome />} />
          <Route path="/news/ndisphysiotherapyfunctionaltasktrainingincludingbalancingandmovement" element={<NdisPhysiotherapyFunctionalTaskTrainingIncludingBalancingAndMovement />} />
          <Route path="/news/ndisphysiotherapyimprovingmoodandenergylevels" element={<NdisPhysiotherapyImprovingMoodAndEnergyLevels />} />
          <Route path="/news/ndisphysiotherapystrengtheningexercisestoboostmuscleconditionandposture" element={<NdisPhysiotherapyStrengtheningExercisesToBoostMuscleConditionAndPosture />} />
          <Route path="/news/ndisphysiotherapytissuesmassagestobreakuptensioninmuscles" element={<NdisPhysiotherapyTissuesMassagesToBreakUpTensionInMuscles />} />
          <Route path="/news/ndisphysiotherapytrainingcapabilityinhandlingeverydayactionsandtasks" element={<NdisPhysiotherapyTrainingCapabilityInHandlingEverydayActionsAndTasks />} />
          <Route path="/news/whatdoesatypicalndisphysiosessionlooklike" element={<Whatdoesatypicalndisphysiosessionlooklike />} />
          <Route path="/news/willmyndisphysiotherapistgivemeexercisestodoathome" element={<Willmyndisphysiotherapistgivemeexercisestodoathome />} />

          <Route path="/news/physiotherapytreatmentandexercisesforagedcarephysiotherapy" element={<PhysiotherapyTreatmentAndExercisesForAgedCarePhysiotherapy />} />
          <Route path="/news/physiotherapytreatmentandexercisesforarthritis" element={<PhysiotherapyTreatmentAndExercisesForArthritis />} />
          <Route path="/news/physiotherapytreatmentandexercisesforbackpain" element={<PhysiotherapyTreatmentAndExercisesForBackPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesforbalanceproblems" element={<PhysiotherapyTreatmentAndExercisesForBalanceProblems />} />
          <Route path="/news/physiotherapytreatmentandexercisesforchronicpain" element={<PhysiotherapyTreatmentAndExercisesForChronicPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesfordryneedling" element={<PhysiotherapyTreatmentAndExercisesForDryNeedling />} />
          <Route path="/news/physiotherapytreatmentandexercisesforelbowpain" element={<PhysiotherapyTreatmentAndExercisesForElbowPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesforfibromyalgia" element={<PhysiotherapyTreatmentAndExercisesForFibromyalgia />} />
          <Route path="/news/physiotherapytreatmentandexercisesforgaitandbalance" element={<PhysiotherapyTreatmentAndExercisesForGaitAndBalance />} />
          <Route path="/news/physiotherapytreatmentandexercisesforheadaches" element={<PhysiotherapyTreatmentAndExercisesForHeadaches />} />
          <Route path="/news/physiotherapytreatmentandexercisesforhippain" element={<PhysiotherapyTreatmentAndExercisesForHipPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesforkneepain" element={<PhysiotherapyTreatmentAndExercisesForKneePain />} />
          <Route path="/news/physiotherapytreatmentandexercisesforlegpain" element={<PhysiotherapyTreatmentAndExercisesForLegPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesforlowerbackpain" element={<PhysiotherapyTreatmentAndExercisesForLowerBackPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesformiddlebackpain" element={<PhysiotherapyTreatmentAndExercisesForMiddleBackPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesformigraines" element={<PhysiotherapyTreatmentAndExercisesForMigraines />} />
          <Route path="/news/physiotherapytreatmentandexercisesformotorvehicleinjuries" element={<PhysiotherapyTreatmentAndExercisesForMotorVehicleInjuries />} />
          <Route path="/news/physiotherapytreatmentandexercisesforneckpain" element={<PhysiotherapyTreatmentAndExercisesForNeckPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesforpaediatricphysiotherapy" element={<PhysiotherapyTreatmentAndExercisesForPaediatricPhysiotherapy />} />
          <Route path="/news/physiotherapytreatmentandexercisesforpostnatalphysiotherapy" element={<Physiotherapytreatmentandexercisesforpostnatalphysiotherapy />} />
          <Route path="/news/physiotherapytreatmentandexercisesforpostsurgicalrehabilitation" element={<PhysiotherapyTreatmentAndExercisesForPostSurgicalRehabilitation />} />
          <Route path="/news/physiotherapytreatmentandexercisesforposturecorrection" element={<PhysiotherapyTreatmentAndExercisesForPostureCorrection />} />
          <Route path="/news/physiotherapytreatmentandexercisesforpregnancycare" element={<PhysiotherapyTreatmentAndExercisesForPregnancyCare />} />
          <Route path="/news/physiotherapytreatmentandexercisesforremedialmassage" element={<PhysiotherapyTreatmentAndExercisesForRemedialMassage />} />
          <Route path="/news/physiotherapytreatmentandexercisesforsciatica" element={<PhysiotherapyTreatmentAndExercisesForSciatica />} />
          <Route path="/news/physiotherapytreatmentandexercisesforshoulderpain" element={<PhysiotherapyTreatmentAndExercisesForShoulderPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesforsidepain" element={<PhysiotherapyTreatmentAndExercisesForSidePain />} />
          <Route path="/news/physiotherapytreatmentandexercisesforsofttissueandmuscleinjuries" element={<PhysiotherapyTreatmentAndExercisesForSoftTissueAndMuscleInjuries />} />
          <Route path="/news/physiotherapytreatmentandexercisesforsportsinjuries" element={<PhysiotherapyTreatmentAndExercisesForSportsInjuries />} />
          <Route path="/news/physiotherapytreatmentandexercisesforsportsinjuryprevention" element={<PhysiotherapyTreatmentAndExercisesForSportsInjuryPrevention />} />
          <Route path="/news/physiotherapytreatmentandexercisesforupperbackpain" element={<PhysiotherapyTreatmentAndExercisesForUpperBackPain />} />
          <Route path="/news/physiotherapytreatmentandexercisesforwhatwetreat" element={<PhysiotherapyTreatmentAndExercisesForWhatWeTreat />} />
          <Route path="/news/physiotherapytreatmentandexercisesforwhiplash" element={<PhysiotherapyTreatmentAndExercisesForWhiplash />} />
          <Route path="/news/physiotherapytreatmentandexercisesforwomenshealthphysiotherapy" element={<Physiotherapytreatmentandexercisesforwomenshealthphysiotherapy />} />
          <Route path="/news/physiotherapytreatmentandexercisesforwristpain" element={<PhysiotherapyTreatmentAndExercisesForWristPain />} />
        </Routes>
        <FooterBar />
      </div>
    </Router>
  );

};

export default App;

