import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForBuildingLeanMuscle = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts for Building Lean Muscle | Ramp Physio and Fitness</title>
                <meta name="description" content="Maximize muscle growth with our expert-designed gym workouts for building lean muscle. Ramp up your fitness journey with Ramp Physio and Fitness in Homebush, NSW." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximize Muscle Growth</h1>
                    <h2>Unlock the secrets of building lean muscle with our expert-designed gym workouts at Ramp Physio and Fitness. Transform your physique with our personalized training programs and state-of-the-art gym facilities in Homebush, NSW.</h2>
                    <p>Building lean muscle is not just about lifting weights; it's about following a structured workout plan that promotes muscle growth while minimizing fat gain. At Ramp Physio and Fitness, we offer gym workouts tailored to help you achieve a lean, muscular physique through a combination of resistance training, cardiovascular exercises, and nutrition guidance. This article will guide you through the essential components of building lean muscle and how our gym workouts can be your roadmap to a stronger, healthier body.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Workouts" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Strategic Workout Programs</h1>
                        <p>Our gym workouts are carefully crafted to optimize muscle growth and enhance fat loss. The programs include a blend of compound movements, isolation exercises, and high-intensity interval training (HIIT) to stimulate muscle fibers effectively. By following our six-week workout program, you'll not only build lean muscle but also improve your overall fitness and endurance.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Lean Muscle Building Blocks</h1>
                    <p>Understanding the science of muscle building is key to achieving your goals. Our workouts focus on progressive overload, nutrition, recovery, and consistency. We ensure that you're not only working hard but also working smart, with workouts that are both effective and sustainable in the long term.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Nutrition for Muscle Gain</h1>
                    <p>Nutrition is just as important as the workout itself when it comes to building lean muscle. We provide guidance on how to fuel your body with the right balance of macronutrients, ensuring that your muscles have the necessary building blocks to grow and recover.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Cardio for Lean Physique</h1>
                    <p>Cardiovascular exercise is a crucial component of our gym workouts for building lean muscle. It helps to burn fat, improve heart health, and increase metabolism. Our programs include a mix of steady-state cardio and HIIT to maximize fat loss while preserving muscle mass.</p>
                    <ul>
                        <li>High-Intensity Interval Training (HIIT)</li>
                        <li>Steady-State Cardio</li>
                        <li>Treadmill Intervals</li>
                        <li>Rowing Machine Sessions</li>
                        <li>Cycling Sprints</li>
                        <li>Jump Rope Circuits</li>
                        <li>Swimming Laps</li>
                        <li>Elliptical Workouts</li>
                        <li>Stair Climbing Challenges</li>
                        <li>Outdoor Running Routes</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForBuildingLeanMuscle;