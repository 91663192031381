import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const SettingRealisticFitnessGoalsAndHowToAchieveThem = () => {
    return (
        <div>
            <Helmet>
                <title>Setting Realistic Fitness Goals | Ramp Physio and Fitness</title>
                <meta name="description" content="Learn how to set and achieve realistic fitness goals with Ramp Physio and Fitness. Discover tips for success and start your journey today!" />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Setting Realistic Fitness Goals</h1>
                    <h2>Embark on a journey toward sustainable fitness with achievable goals and personalized strategies, tailored to your lifestyle and ambitions at Ramp Physio and Fitness.</h2>
                    <p>Setting realistic fitness goals is crucial to not only achieving but also maintaining a healthy and active lifestyle. In this comprehensive guide, Ramp Physio and Fitness provides insights into how to set attainable fitness objectives and outlines strategies that will help you reach them. From understanding the importance of SMART goals to recognizing the role of professional guidance, this article is your roadmap to success.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Start Today
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Fitness Goals" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding SMART Goals</h1>
                        <p>SMART goals—Specific, Measurable, Achievable, Relevant, and Time-bound—are the foundation for setting fitness targets that are realistic and attainable. By defining your goals with these criteria in mind, you create a clear path forward and can measure your progress effectively, ensuring each step you take is aligned with your ultimate fitness aspirations.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Break Down Your Goals</h1>
                    <p>Breaking down your long-term goals into smaller, manageable milestones makes your fitness journey more approachable and less daunting. This approach allows for regular celebrations of success, keeps motivation high, and provides opportunities to adjust your plan as needed, ensuring continuous progress toward your overarching objectives.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Get Started
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Embrace Professional Guidance</h1>
                    <p>Working with a professional can significantly enhance your ability to set and achieve fitness goals. Our expert trainers and physiotherapists offer personalized advice, structured plans, and motivational support, ensuring that your fitness program is effective, safe, and specifically designed to meet your individual needs and abilities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Monitor and Adjust Regularly</h1>
                    <p>Consistent monitoring and willingness to adjust your fitness plan are essential for achieving your goals. Regular check-ins allow you to track your progress, celebrate achievements, and identify areas where changes might be needed. This dynamic approach keeps you on the right track and helps prevent plateaus or loss of interest.</p>
                    <br></br>
                    <p>
                        Achieving your fitness goals is a journey that requires commitment, patience, and a strategic plan. At Ramp Physio and Fitness, we are dedicated to helping you set realistic targets and providing the resources and support you need to reach them. Let us be your partner in fitness, and together, we'll turn your aspirations into achievements. Start your journey with us today and take the first step towards a healthier, fitter you.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default SettingRealisticFitnessGoalsAndHowToAchieveThem;