import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import postureLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForPostureCorrection = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Posture Correction with Physiotherapy</h1>
                    <h2>Unlock the secrets to perfect posture through expert physiotherapy treatment and exercises at Ramp Physio and Fitness.</h2>
                    <p>Poor posture can lead to a myriad of health problems, including back pain, neck tension, and reduced mobility. At Ramp Physio and Fitness, we specialize in physiotherapy treatment and exercises designed to correct posture and promote overall well-being. Our personalized approach ensures that each client receives the attention and care necessary to achieve optimal alignment and physical health. In this detailed guide, we delve into the essential strategies and exercises that form the foundation of effective posture correction.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Fundamentals of Proper Posture</h1>
                        <p>Understanding the key elements of proper posture is the first step in correcting alignment issues. Good posture involves maintaining the natural curves of the spine, aligning the head over the shoulders, and distributing weight evenly on both feet. Our physiotherapists at Ramp Physio and Fitness use a holistic approach to assess posture and identify areas that require improvement.</p>

                        <p>Through a combination of hands-on techniques and targeted exercises, we work to strengthen the core and back muscles, increase flexibility, and enhance body awareness. Our goal is to help you establish a strong, balanced posture that supports your daily activities and reduces the risk of injury.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={postureLogo} alt="Posture Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strategies for Lasting Posture Improvement</h1>
                    <p>Correcting posture is not a quick fix but a journey that involves consistent effort and the right strategies. Our physiotherapists provide tailored advice on how to maintain ideal posture throughout the day, whether you're sitting at a desk, standing for long periods, or engaging in physical activity.</p>

                    <p>We also emphasize the importance of ergonomic workspaces and proper lifting techniques to support your posture correction efforts. By incorporating these strategies into your daily routine, you can make lasting changes to your posture and experience significant improvements in comfort and function.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Targeted Posture Correction Exercises</h1>
                        <p>Key to any posture correction program are exercises that specifically target the muscles responsible for maintaining alignment. At Ramp Physio and Fitness, we guide you through a series of exercises that focus on strengthening the core, upper back, and glutes, as well as stretching tight chest and hip flexor muscles.</p>

                        <p>These exercises are carefully selected and customized to address your unique postural needs, ensuring that you not only improve your posture but also enhance your overall strength and mobility.</p>
                        <br></br>
                        <p>
                            With our physiotherapy treatment and exercise regimen, you can expect to see a noticeable difference in your posture, leading to a healthier, more confident you. Let Ramp Physio and Fitness be your partner in achieving the perfect posture and a pain-free lifestyle.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForPostureCorrection;