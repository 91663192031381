import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingVestibularDisordersAndPhysiotherapyTreatments = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating Vestibular Disorders</h1>
                    <h2>Understand and conquer vestibular disorders with tailored physiotherapy treatments at Ramp Physio and Fitness, where we commit to guiding you back to balance and optimal health.</h2>
                    <p>Vestibular disorders can disrupt your life, causing dizziness, imbalance, and a range of symptoms that affect your daily activities. At Ramp Physio and Fitness, we specialize in understanding and treating these complex conditions with individualized physiotherapy programs. This article will explore the nature of vestibular disorders and how physiotherapy can be a game-changer in your road to recovery.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>What Are Vestibular Disorders?</h1>
                        <p>Vestibular disorders encompass a variety of issues that affect the vestibular system, which is responsible for maintaining balance and spatial orientation. These conditions can lead to symptoms such as vertigo, dizziness, visual disturbance, and unsteadiness. Understanding the root causes and symptoms of vestibular disorders is the first step towards effective treatment and management.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy's Role in Treatment</h1>
                    <p>Physiotherapy plays a critical role in the treatment and management of vestibular disorders. Through a detailed assessment and a personalized treatment plan, physiotherapists at Ramp Physio and Fitness use evidence-based techniques to alleviate symptoms and improve quality of life. Vestibular rehabilitation therapy (VRT) is a specialized form of therapy that aims to restore normal function of the vestibular system.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Customized Vestibular Rehabilitation</h1>
                    <p>Vestibular rehabilitation therapy is a customized exercise-based program designed to reduce vertigo and dizziness, gaze instability, and imbalance. At Ramp Physio and Fitness, we create tailored VRT programs that address your specific symptoms and challenges, incorporating exercises that can be performed at home to facilitate ongoing improvement.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Components of Vestibular Rehab</h1>
                    <p>Vestibular rehabilitation therapy includes various components tailored to address individual needs. These may include habituation exercises to reduce dizziness, gaze stabilization exercises to improve vision during head movements, and balance training to improve steadiness. Here are 10 examples of exercises and techniques used in VRT:</p>
                    <ul>
                        <li>Head movement exercises to reduce dizziness</li>
                        <li>Eye tracking and focusing exercises</li>
                        <li>Balance training and proprioceptive enhancement</li>
                        <li>Walking exercises to improve gait</li>
                        <li>Strengthening exercises for postural support</li>
                        <li>Coordination and agility drills</li>
                        <li>Functional retraining for daily activities</li>
                        <li>Fall prevention strategies</li>
                        <li>Relaxation and breathing techniques for anxiety management</li>
                        <li>Canalith repositioning maneuvers for BPPV</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingVestibularDisordersAndPhysiotherapyTreatments;