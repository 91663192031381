import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Gymworkoutsforseniorsstayingfitatanyage = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Gym Workouts for Seniors</h1>
                    <h2>Staying Fit at Any Age with Ramp Physio and Fitness</h2>
                    <p>At Ramp Physio and Fitness, we understand the importance of maintaining an active lifestyle as we age. Our gym workouts for seniors are designed to ensure that staying fit at any age is not only possible but enjoyable. We focus on exercises that increase strength, flexibility, and balance, all of which are crucial for a healthy and independent life. In this article, we will guide you through the benefits of senior workouts and provide you with a tailored fitness plan.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Senior Fitness" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Benefits of Senior Fitness</h1>
                        <p>Engaging in regular physical activity can have a profound impact on the overall well-being of seniors. From improving cardiovascular health to enhancing mental acuity, the advantages of a consistent exercise regimen are numerous. Here are 10 key benefits of senior fitness:</p>
                        <ul>
                            <li>Increased muscular strength</li>
                            <li>Improved joint flexibility</li>
                            <li>Better balance and coordination</li>
                            <li>Enhanced cognitive function</li>
                            <li>Reduced risk of chronic diseases</li>
                            <li>Improved bone density</li>
                            <li>Boosted mood and energy levels</li>
                            <li>Greater independence in daily activities</li>
                            <li>Enhanced social engagement</li>
                            <li>Longevity and quality of life</li>
                        </ul>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="service-intro-content2">
                    <h1>Creating a Safe Workout Plan</h1>
                    <p>Developing a workout plan for seniors requires careful consideration of individual health conditions and fitness levels. At Ramp Physio and Fitness, we prioritize safety and effectiveness in our senior workout programs. Each plan is tailored to meet the needs of our members, ensuring that exercises are both achievable and beneficial. Here are the steps we take to create a safe workout plan:</p>
                    <ul>
                        <li>Assessment of physical capabilities</li>
                        <li>Consideration of medical history</li>
                        <li>Setting realistic fitness goals</li>
                        <li>Incorporating a variety of exercises</li>
                        <li>Adjusting intensity and duration appropriately</li>
                        <li>Ensuring proper warm-up and cool-down</li>
                        <li>Monitoring progress and making adjustments as needed</li>
                        <li>Providing support and encouragement</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Essential Exercises for Seniors</h1>
                    <p>Our senior fitness program includes a range of exercises that target key muscle groups and promote overall health. These exercises are selected for their effectiveness and safety for older adults. Here are 10 essential exercises we incorporate into our senior gym workouts:</p>
                    <ul>
                        <li>Seated leg extensions</li>
                        <li>Standing arm curls</li>
                        <li>Wall push-ups</li>
                        <li>Chair squats</li>
                        <li>Toe stands</li>
                        <li>Seated rowing</li>
                        <li>Leg curls</li>
                        <li>Overhead press</li>
                        <li>Side leg raises</li>
                        <li>Back extensions</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="service-intro-content2">
                    <h1>Join Our Senior Fitness Community</h1>
                    <p>At Ramp Physio and Fitness, we believe in the power of community to motivate and support our members. Our senior fitness programs are more than just workouts; they're an opportunity to connect with others who share similar health and wellness goals. By joining our community, you'll enjoy the benefits of group exercise, including:</p>
                    <ul>
                        <li>Companionship and camaraderie</li>
                        <li>Shared motivation and encouragement</li>
                        <li>Structured routines led by professional trainers</li>
                        <li>Fun and engaging workout sessions</li>
                        <li>Regular progress check-ins and celebrations</li>
                    </ul>
                    <p>
                        We invite you to become a part of our senior fitness family and start your journey to a healthier, more vibrant life. Whether you're looking to build strength, improve mobility, or simply enjoy the social aspects of group exercise, Ramp Physio and Fitness is here to support you every step of the way.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Gymworkoutsforseniorsstayingfitatanyage;