import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const StayingMotivatedOnYourFitnessJourney = () => {
    return (
        <div>
            <Helmet>
                <title>Stay Motivated on Your Fitness Journey | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover effective strategies to maintain motivation and consistency on your fitness journey with Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Staying Motivated on Your Fitness Journey</h1>
                    <h2>Unlock the secrets to lasting motivation and achieve your health and fitness goals with Ramp Physio and Fitness.</h2>
                    <p>Embarking on a fitness journey is an exciting endeavor, but maintaining motivation can sometimes be a challenge. At Ramp Physio and Fitness, we understand the importance of staying inspired and committed to your health goals. In this comprehensive guide, we'll explore proven strategies and practical tips to help you stay motivated and on track with your fitness journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Get Started
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Set Realistic Fitness Goals</h1>
                        <p>Starting with clear, achievable goals is the cornerstone of any successful fitness journey. By setting realistic expectations and milestones, you can create a sense of accomplishment and fuel your motivation to keep pushing forward. Whether it's improving your strength, losing weight, or running a marathon, setting specific, measurable, achievable, relevant, and time-bound (SMART) goals will provide the direction and purpose you need.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Find Your Fitness Community</h1>
                    <p>Surrounding yourself with a supportive community can be a powerful motivator. Whether it's a group fitness class, a running club, or a workout buddy, having others to share your journey with can provide encouragement, accountability, and fun. At Ramp Physio and Fitness, our community is ready to welcome you and help you thrive.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Us
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Embrace Routine and Structure</h1>
                    <p>Creating a structured routine can help turn your fitness activities into habits. Consistency is key, and by scheduling regular workout times, you're more likely to stick with your program. Remember, flexibility within your routine is important too—life happens, and being able to adapt will keep you on track without feeling discouraged.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Track Your Progress</h1>
                    <p>Monitoring your progress is a fantastic way to see how far you've come and what you've achieved. Use fitness apps, journals, or check-ins with a personal trainer to keep track of your workouts, diet, and overall wellbeing. Celebrating small victories along the way can provide a significant boost to your motivation.</p>
                    <br></br>
                    <ul>
                        <li>Set short-term and long-term goals</li>
                        <li>Join a fitness challenge or event</li>
                        <li>Take progress photos or measurements</li>
                        <li>Share your achievements with friends or on social media</li>
                        <li>Reward yourself for milestones reached</li>
                        <li>Adjust goals as needed to stay challenged</li>
                        <li>Reflect on your journey and growth</li>
                        <li>Seek feedback from a professional coach</li>
                        <li>Use a fitness tracker to monitor activity levels</li>
                        <li>Stay curious and learn new exercises or sports</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default StayingMotivatedOnYourFitnessJourney;