import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForMotorNeuroneDisease = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives: NDIS Physiotherapy for Motor Neurone Disease</h1>
                    <h2>Explore the benefits of specialized NDIS physiotherapy for individuals living with Motor Neurone Disease at Ramp Physio and Fitness.</h2>
                    <p>Motor Neurone Disease (MND) is a progressive neurological condition that affects the nerves in the brain and spinal cord. As a recognized NDIS provider, Ramp Physio and Fitness is dedicated to providing comprehensive physiotherapy services to support those living with MND. Our goal is to enhance quality of life, promote independence, and tailor interventions that align with each individual's NDIS plan. This article will delve into the crucial role of physiotherapy for MND and how our empathetic and skilled team can assist in managing symptoms and improving mobility.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding MND and NDIS Support</h1>
                        <p>MND is characterized by the weakening of muscles, leading to stiffness, loss of mobility, and difficulties with speech, swallowing, and breathing. The NDIS provides funding for therapies, equipment, and services to support those with MND. Ramp Physio and Fitness works within the NDIS framework, ensuring our therapies are accessible and centered around your needs and goals.</p>

                        <p>Our initial NDIS physiotherapy assessment for MND focuses on understanding your current functional abilities and challenges. We consider factors such as muscle strength, range of motion, respiratory function, and mobility needs. From there, we craft a personalized treatment plan aimed at maintaining your independence for as long as possible.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Strategies</h1>
                    <p>At Ramp Physio and Fitness, we believe in a tailored approach to physiotherapy for MND. This may involve exercises to maintain joint flexibility, muscle strength, and respiratory capacity. We also provide guidance on adaptive equipment and assistive technology to support your daily activities and reduce the risk of falls.</p>

                    <p>Our therapists are trained in various techniques, such as passive stretching, active-assistive exercises, and breathing exercises, which are crucial for managing MND symptoms. We also collaborate with other NDIS service providers, such as occupational therapists and speech pathologists, to ensure a multidisciplinary approach to your care.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Mobility and Function</h1>
                    <p>Our physiotherapy interventions for MND are designed to help maintain functional movement and minimize the impact of muscle weakness. We focus on exercises that are adaptable to your changing needs, ensuring that you can perform them safely and effectively.</p>

                    <p>Key elements of our approach include:
                        <br></br>
                        - Active and passive range-of-motion exercises
                        <br></br>
                        - Strength training for unaffected or less affected muscles
                        <br></br>
                        - Breathing exercises to enhance respiratory function
                        <br></br>
                        - Balance and coordination training
                        <br></br>
                        - Gait training and the use of mobility aids
                        <br></br>
                        - Education on energy conservation techniques
                        <br></br>
                        - Strategies to manage symptoms such as spasticity and pain
                        <br></br>
                        - Support for caregivers in handling and transferring techniques
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Maximizing Quality of Life</h1>
                        <p>At Ramp Physio and Fitness, we are committed to maximizing the quality of life for individuals living with MND. We understand the emotional and physical challenges that come with this condition and strive to provide a supportive and positive environment for our clients.</p>
                        <p>We offer:
                            <br></br>
                            - Regular reassessment and adaptation of treatment plans
                            <br></br>
                            - Access to specialized equipment and modifications
                            <br></br>
                            - Collaboration with multidisciplinary teams for comprehensive care
                            <br></br>
                            - Support for families and caregivers through education and resources
                        </p>

                        <br></br>
                        <p>
                            In conclusion, NDIS physiotherapy for Motor Neurone Disease at Ramp Physio and Fitness is about more than just treatment—it's about empowering individuals to live their lives to the fullest. Our experienced team is dedicated to providing compassionate, individualized care that respects your dignity and supports your journey every step of the way.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForMotorNeuroneDisease;