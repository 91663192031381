import React from 'react';
import './ServiceDetail.css';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import SkewedContainer from 'sc-react';
import HealthSection from './utils/HealthSection';
import NDISCategoryConditionsList from './NDISCategoryConditionsList';
import { Helmet } from 'react-helmet';
import mapImage from '../assets/map.png';

const PhysiotherapyInHomebush = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy in Homebush | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy services in Homebush. Our experienced team provides evidence-based treatments for pain relief, injury recovery and rehabilitation." />
            </Helmet>

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Physiotherapy in Homebush</h1>
                    <h2>Your Local Physiotherapy Experts</h2>
                    <p>
                        Located conveniently on Parramatta Road, Ramp Physio and Fitness provides expert physiotherapy services to the Homebush community. Our modern facility offers comprehensive physiotherapy care with free onsite parking for easy access.
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now">
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Physiotherapy in Homebush" className="service-detail-image" />
                </div>
            </div>

            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Expert Physiotherapy Services in Homebush</h1>
                        <p>Our physiotherapy services in Homebush include:</p>
                        <ul>
                            <li>Sports Injury Treatment</li>
                            <li>Back and Neck Pain Management</li>
                            <li>Post-Surgery Rehabilitation</li>
                            <li>NDIS Physiotherapy</li>
                            <li>Exercise Programs</li>
                            <li>Chronic Pain Management</li>
                        </ul>
                        <p>We proudly serve patients from Homebush, Strathfield, Flemington, and surrounding areas.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Physiotherapy Treatment" />
                    </div>
                </div>
            </SkewedContainer>

            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Gym Facilities" />
                </div>
                <div className="service-intro-content2">
                    <h1>Why Choose Ramp Physio and Fitness in Homebush?</h1>
                    <ul>
                        <li>Experienced physiotherapists</li>
                        <li>Modern facilities with state-of-the-art equipment</li>
                        <li>Free onsite parking</li>
                        <li>Convenient location on Parramatta Road</li>
                        <li>NDIS registered provider</li>
                        <li>Evidence-based treatment approaches</li>
                    </ul>
                    <br></br>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now">
                        Book Now
                    </a>
                </div>
            </div>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <NDISCategoryConditionsList />
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Visit Our Homebush Physiotherapy Clinic</h1>
                    <p>Located on Parramatta Road, our Homebush clinic offers expert physiotherapy services with free onsite parking. Contact us at 8188 1618 to book your appointment or use our online booking system.</p>
                    <p>We serve clients across Homebush, Flemington, Strathfield, and the greater Sydney area.</p>
                    <br></br><br></br>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now">
                        Book Now
                    </a>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise Equipment" />
                </div>
            </div>

            <SkewedContainer top="left" bgColor="white" noMargin>
                <HealthSection />
                
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
                    <div className="map-section">
                        <img src={mapImage} alt="Map Location" />
                    </div>
                    <div className="contact-details" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <div className="contact-info">
                            <p>e: info@rampphysioandfitness.com.au</p>
                            <p>PH: (02) 8188 1618</p>
                        </div>
                        <div className="contact-address">
                            <p>208 Parramatta Road, Homebush,</p>
                            <p>NSW, AUSTRALIA 2140</p>
                        </div>
                    </div>
                </div>
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyInHomebush;
