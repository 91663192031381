import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingCaloricNeedsForDifferentFitnessGoals = () => {
    return (
        <div>
            <Helmet>
                <title>Understanding Caloric Needs for Different Fitness Goals | Ramp Physio and Fitness</title>
                <meta name="description" content="Master your diet and fitness goals by understanding how to calculate and manage your caloric needs. Personalized plans at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Caloric Intake</h1>
                    <h2>Navigate your fitness journey with a tailored understanding of caloric needs at Ramp Physio and Fitness, your partner in achieving health and fitness goals.</h2>
                    <p>Understanding your caloric needs is a cornerstone of any fitness or weight management plan. At Ramp Physio and Fitness, we recognize that every individual's body is unique, and so are their energy requirements. This guide will help you understand how to determine your daily caloric needs based on various fitness goals, such as weight loss, muscle gain, or maintenance. By the end of this article, you'll be equipped with the knowledge to tailor your nutrition plan to your personal objectives.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Understanding Caloric Needs" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Calories: The Energy Units</h1>
                        <p>Calories are the units of energy that measure the amount of energy in the food and drinks we consume. They are essential for providing the fuel our bodies need to function and perform daily activities, including exercise. Our bodies require a certain number of calories to maintain basic life functions, known as the basal metabolic rate (BMR). However, our total daily energy expenditure (TDEE) also includes the energy we use during physical activity and the process of digesting food. Understanding this balance is key to managing your weight and fitness levels.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Calculating Your Caloric Needs</h1>
                    <p>Calculating your caloric needs is a personalized process that takes into account several factors, including age, sex, weight, height, and level of physical activity. There are various methods and formulas used to estimate BMR and TDEE, such as the Harris-Benedict Equation or the Mifflin-St Jeor Equation. Once you have an estimate of your TDEE, you can adjust your caloric intake to align with your fitness goals, whether that's to lose weight, gain muscle, or maintain your current weight.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Weight Loss and Caloric Deficit</h1>
                    <p>To lose weight, you must create a caloric deficit by consuming fewer calories than your body expends. A safe and sustainable deficit is typically around 500 calories per day, which can lead to a loss of approximately one pound of weight per week. It's important to combine a calorie-controlled diet with regular exercise to ensure that you're losing fat rather than muscle mass. Be mindful not to restrict calories too drastically, as this can lead to negative health effects and hinder long-term success.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Muscle Gain and Caloric Surplus</h1>
                        <p>If your goal is to gain muscle, you'll need to be in a caloric surplus, meaning you consume more calories than you burn. This surplus provides the extra energy needed to fuel your workouts and support muscle repair and growth. However, the surplus should be moderate to avoid excessive fat gain. It's also crucial to ensure that you're getting enough protein in your diet to support muscle synthesis and recovery.</p>
                        <br></br>
                        <ul>
                            <li>Protein-rich foods like chicken, fish, and legumes</li>
                            <li>Complex carbohydrates for sustained energy</li>
                            <li>Healthy fats for hormone production and nutrient absorption</li>
                            <li>Ample fruits and vegetables for vitamins and minerals</li>
                            <li>Hydration to support metabolic processes</li>
                            <li>Pre- and post-workout nutrition for optimal performance and recovery</li>
                            <li>Consistent meal timing to maintain energy levels</li>
                            <li>Adequate sleep for muscle repair and growth</li>
                            <li>Strength training to stimulate muscle hypertrophy</li>
                            <li>Monitoring progress and adjusting intake as needed</li>
                        </ul>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingCaloricNeedsForDifferentFitnessGoals;