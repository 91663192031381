import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForAcquiredAndTraumaticBrainInjuries = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Brain Injuries</h1>
                    <h2>Expert care for acquired and traumatic brain injuries at Ramp Physio and Fitness.</h2>
                    <p>Acquired and traumatic brain injuries can have a profound impact on an individual's life. At Ramp Physio and Fitness, we specialize in NDIS physiotherapy services tailored to support those affected by brain injuries. Our experienced team is dedicated to providing comprehensive care that addresses the unique challenges faced by each client. In this guide, we will explore the facets of physiotherapy for brain injuries under the NDIS scheme and how our services can help individuals regain independence and improve their quality of life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Path to Recovery</h1>
                        <p>Rehabilitation after a brain injury is a journey that requires patience, expertise, and a personalized approach. Our physiotherapists employ a range of strategies to promote recovery, including:</p>
                        <div className="dot-points">
                            <p>- Neurological rehabilitation</p>
                            <p>- Mobility training</p>
                            <p>- Strength and conditioning exercises</p>
                            <p>- Balance and coordination activities</p>
                            <p>- Cognitive and perceptual retraining</p>
                            <p>- Pain management techniques</p>
                            <p>- Functional task practice</p>
                            <p>- Community reintegration support</p>
                            <p>- Family and caregiver education</p>
                            <p>- Ongoing assessment and adaptation of treatment plans</p>
                        </div>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Impact on Physical Function</h1>
                        <p>Brain injuries can significantly affect physical functioning in multiple ways, including muscle weakness, spasticity, balance problems, and coordination difficulties. The brain's ability to control movement and process sensory information may be compromised, leading to challenges in everyday activities. Physical symptoms can vary greatly depending on the location and severity of the injury, with some individuals experiencing difficulties on one side of the body (hemiplegia) while others may face more generalized movement challenges.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Understanding Brain Injury Types</h1>
                    <p>Brain injuries can be classified into two main categories: acquired and traumatic. Acquired brain injuries (ABI) are not hereditary, congenital, degenerative, or induced by birth trauma. They occur after birth and can result from events such as strokes, infections, or lack of oxygen to the brain. Traumatic brain injuries (TBI), on the other hand, are caused by an external force, such as a blow or jolt to the head, which can disrupt normal brain function.</p>

                    <p>Recognizing the differences between ABI and TBI is crucial for developing effective treatment plans. Our physiotherapists are trained to understand these distinctions and provide targeted interventions to address the specific needs of each client.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Maximizing NDIS Benefits</h1>
                    <p>Navigating the National Disability Insurance Scheme (NDIS) can be overwhelming, especially after a brain injury. Our team at Ramp Physio and Fitness is well-versed in the NDIS and can assist clients with:</p>
                    <div className="dot-points">
                        <p>- Understanding NDIS eligibility and plan management</p>
                        <p>- Accessing funding for physiotherapy services</p>
                        <p>- Setting realistic and achievable goals</p>
                        <p>- Advocating for necessary supports and services</p>
                        <p>- Collaborating with other healthcare professionals</p>
                        <p>- Providing reports and documentation for NDIS reviews</p>
                    </div>
                    <p>We are committed to helping our clients make the most of their NDIS plan, ensuring they receive the care and support they need for optimal recovery.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForAcquiredAndTraumaticBrainInjuries;