import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingCraniosacralTherapyInPhysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Understanding Craniosacral Therapy in Physiotherapy</h1>
                    <h2>Explore the gentle, yet profound, healing approach of Craniosacral Therapy at Ramp Physio and Fitness to support your body's natural ability to heal and balance.</h2>
                    <p>Craniosacral Therapy (CST) is a subtle and therapeutic form of bodywork that addresses the membranes and fluid that surround the brain and spinal cord. By tuning into the craniosacral rhythm and using soft-touch techniques, CST practitioners at Ramp Physio and Fitness aim to release restrictions in the craniosacral system to improve the functioning of the central nervous system. This article will delve into the world of Craniosacral Therapy, its benefits, and how it integrates into holistic physiotherapy practices.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Essence of Craniosacral Therapy</h1>
                        <p>Craniosacral Therapy is founded on the concept that the body possesses inherent wisdom to heal itself. The therapy focuses on enhancing the body's natural healing processes by optimizing the environment around the brain and spinal cord. Our CST practitioners use a light touch to palpate the synarthrodial joints of the cranium, tracing the ebb and flow of cerebrospinal fluid to determine areas of restriction or imbalance. By doing so, they support the body's ability to self-correct and fortify overall health and well-being.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Benefits of Craniosacral Therapy</h1>
                    <p>Craniosacral Therapy offers a multitude of benefits for individuals with various conditions. Here are ten key benefits CST can provide:</p>
                    <ul>
                        <li>Relief from migraine and headache</li>
                        <li>Reduction in neck and back pain</li>
                        <li>Alleviation of stress and tension-related disorders</li>
                        <li>Improvement in chronic fatigue syndrome</li>
                        <li>Support for post-traumatic stress disorder (PTSD)</li>
                        <li>Assistance with orthopedic problems</li>
                        <li>Enhancement of emotional well-being</li>
                        <li>Relief from TMJ syndrome</li>
                        <li>Support during post-concussion recovery</li>
                        <li>Improved functioning of the autonomic nervous system</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Who Can Benefit from CST?</h1>
                    <p>Craniosacral Therapy is suitable for people of all ages, from newborns to the elderly. It is particularly beneficial for those experiencing chronic pain, neurological impairment, or those seeking a complementary approach to traditional medical treatments. CST is also an excellent choice for individuals looking to enhance their body's resilience to stress and improve their overall health and vitality.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>The CST Experience at Ramp Physio</h1>
                    <p>At Ramp Physio and Fitness, we offer a serene and supportive environment for your Craniosacral Therapy sessions. Our experienced therapists will work with you to create a personalized treatment plan that addresses your specific needs and goals. Each session is conducted in a calm setting, allowing you to relax fully as our therapists gently work with your body's craniosacral system.</p>
                </div>
            </div>
            </SkewedContainer>
            
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingCraniosacralTherapyInPhysiotherapy;