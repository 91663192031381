import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Muscular Strains</h1>
                    <h2>Effective Treatment for Muscular Strains: Get Back to Your Best</h2>
                    <p>At Ramp Physio and Fitness, we specialize in treating muscular strains that can occur from overexertion, sports injuries, or even everyday activities. Muscular strains can cause significant discomfort and limit your ability to move freely. Our expert physiotherapists are here to assess the severity of your strain and develop a personalized treatment plan that promotes healing, reduces pain, and restores your muscle function.</p>
                    <p>Suffering from a muscle strain? Don’t let it slow you down. Contact us today to schedule an assessment and start your personalized treatment plan with our experienced physiotherapists.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Identifying the Causes and Symptoms of Muscular Strains</h1>
                        <p>Muscular strains can happen to anyone, whether you’re an athlete or simply lifting something heavy. Common causes include sudden overstretching, repetitive movements, or direct trauma to the muscle. Symptoms often include pain, swelling, and difficulty moving the affected area. At Ramp Physio and Fitness, we help you understand the specific cause of your strain and provide targeted treatments to speed up your recovery and prevent future injuries.</p>
                        <p> Experiencing pain from a muscle strain? Let us help you understand the cause and provide effective treatment. Reach out to us today to schedule a consultation and begin your recovery.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Programs for Muscular Strain Recovery.</h1>
                    <p>Recovering from a muscular strain requires a tailored approach that addresses your unique needs. Our physiotherapists at Ramp Physio and Fitness offer customized treatment programs that include manual therapy, therapeutic exercises, and advice on activity modification to help you heal efficiently. We focus on restoring your strength and flexibility, so you can get back to your regular activities without the risk of re-injury.</p>
                    <p>Ready to recover from your muscle strain? Contact us to create a customized rehabilitation plan that helps you regain your strength and flexibility while preventing future injuries.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Success Stories: Recovering from Muscular Strains with Our Physiotherapy</h1>
                    <p>Our clients have successfully recovered from muscular strains and returned to their active lifestyles thanks to our specialized physiotherapy programs. Whether it’s overcoming sports injuries or daily strains, our clients’ testimonials highlight the positive impact of our treatments. Read their stories to see how Ramp Physio and Fitness can help you recover quickly and effectively from a muscular strain.</p>
                    <p>Inspired by our clients’ recovery stories? Start your journey to full recovery with our expert physiotherapy services. Contact us today to book your appointment and get back to doing what you love.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
