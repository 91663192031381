import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const ManagingStressIncontinenceWithPhysiotherapy = () => {
    return (
        <div>
            <Helmet>
                <title>Managing Stress Incontinence with Physiotherapy - Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Stress Incontinence</h1>
                    <h2>Explore how physiotherapy at Ramp Physio and Fitness can provide effective management for stress incontinence, restoring confidence and quality of life.</h2>
                    <p>Stress incontinence, the involuntary leakage of urine during physical activities such as coughing, sneezing, or exercise, can be both embarrassing and inconvenient. Fortunately, physiotherapy offers a non-invasive way to strengthen the pelvic floor muscles and regain control. This article will discuss the role of physiotherapy in managing stress incontinence and how our services at Ramp Physio and Fitness can help.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Managing Stress Incontinence" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Stress Incontinence</h1>
                        <p>Stress urinary incontinence (SUI) is a common condition that affects individuals of all ages, particularly women. It's caused by a weakness in the pelvic floor muscles which support the bladder and urethra. Factors like childbirth, menopause, and certain surgeries can exacerbate the issue. Physiotherapy can be a critical component in improving pelvic floor function and reducing the symptoms of stress incontinence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Pelvic Floor Physiotherapy</h1>
                    <p>Our physiotherapists at Ramp Physio and Fitness specialize in pelvic floor rehabilitation. Through a series of tailored exercises and techniques, we can help strengthen the muscles that control urination. A strong pelvic floor is essential for preventing leaks and improving bladder control.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Each individual's experience with stress incontinence is different, which is why we create personalized treatment plans. After a thorough assessment, we'll develop a program that caters to your specific needs, including exercises, lifestyle advice, and behavioral techniques to improve bladder control.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Educational Approach to Care</h1>
                    <p>Understanding your condition is key to managing it effectively. Our physiotherapists provide education on stress incontinence and how various factors affect it. We empower you with knowledge so you can take proactive steps towards managing your symptoms and improving your quality of life.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Advanced Techniques and Tools</h1>
                    <p>We utilize advanced techniques such as biofeedback and electrical stimulation to enhance pelvic floor muscle training. These tools can help you gain better awareness and control of your pelvic floor muscles, contributing to more effective management of stress incontinence.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <h1>Comprehensive Support and Follow-Up</h1>
                    <p>At Ramp Physio and Fitness, we're committed to providing ongoing support. We offer follow-up appointments to track your progress and make any necessary adjustments to your treatment plan. Our goal is to ensure you achieve the best possible outcomes and regain control over your life.</p>
                    <br></br>
                    <p>
                        If you're struggling with stress incontinence, remember that you're not alone, and help is available. Our team at Ramp Physio and Fitness is dedicated to providing the highest quality care to help you manage your symptoms and improve your well-being. Contact us today to learn more about our physiotherapy services and how we can assist you in overcoming stress incontinence.
                    </p>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default ManagingStressIncontinenceWithPhysiotherapy;