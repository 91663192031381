import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForSacroiliacJointDysfunction = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Effective Physiotherapy for SI Joint Dysfunction</h1>
                    <h2>Unlock relief and mobility with our targeted physiotherapy treatment for Sacroiliac Joint Dysfunction at Ramp Physio and Fitness.</h2>
                    <p>Sacroiliac Joint Dysfunction is a common source of lower back and pelvic pain. At Ramp Physio and Fitness, we understand the impact this condition can have on your daily life. Our expert physiotherapists specialize in diagnosing and treating SI Joint Dysfunction, utilizing evidence-based practices to alleviate pain and restore function. In this comprehensive guide, we'll explore the causes, symptoms, and effective treatment strategies for SI Joint Dysfunction, including exercises tailored to your unique needs.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding SI Joint Dysfunction</h1>
                        <p>The Sacroiliac (SI) joint is a critical linkage system between your lower spine and pelvis. Dysfunction in this joint can lead to significant discomfort and limited mobility. Our physiotherapists at Ramp Physio and Fitness are trained to identify the root causes of SI Joint Dysfunction and develop personalized treatment plans to address both pain and underlying issues, ensuring a holistic approach to your recovery.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Treatment Plans</h1>
                    <p>Every individual's experience with SI Joint Dysfunction is unique, which is why our treatment plans are tailored to each patient's specific condition and lifestyle. We combine hands-on therapy, such as manual manipulation and soft tissue massage, with supervised exercises to improve joint mobility, strengthen the surrounding muscles, and promote long-term health of the SI joint.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Therapy for SI Joint Pain</h1>
                    <p>Exercise therapy is a cornerstone of our treatment approach for SI Joint Dysfunction. We guide patients through targeted exercises designed to stabilize the SI joint, enhance core strength, and increase flexibility. Here are 10 examples of exercises that may be included in your regimen:</p>
                    <ul>
                        <li>Bridging for gluteal activation</li>
                        <li>Pelvic tilts to improve lumbar stability</li>
                        <li>Core strengthening planks</li>
                        <li>Supine leg raises to engage hip flexors</li>
                        <li>Lower back stretches</li>
                        <li>Isometric stanility exercises targetting pelvic balance</li>
                        <li>Resistance band exercises for hip abduction</li>
                        <li>Wall squats for lower body strength</li>
                        <li>Walking lunges for dynamic stability</li>
                        <li>Bird-dog exercises for core and lumbar coordination</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Techniques for Lasting Relief</h1>
                    <p>At Ramp Physio and Fitness, we go beyond traditional exercises and incorporate advanced techniques such as dry needling, electrotherapy, and real-time ultrasound to facilitate deeper healing and pain relief for SI Joint Dysfunction. These modalities complement our hands-on treatment, providing an integrated approach to managing your condition.</p>
                    <br></br>
                    <p>
                        Our commitment to your well-being is reflected in our dedication to staying abreast of the latest research and techniques in physiotherapy. We invite you to join us on your journey to recovery and experience the comprehensive care that makes a real difference in overcoming Sacroiliac Joint Dysfunction.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForSacroiliacJointDysfunction;