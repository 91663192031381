import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Navigating ACL Injury Physiotherapy: A Comprehensive Guide</h1>
                    <h2>This article provides an in-depth look into ACL injury physiotherapy, detailing the importance of personalized treatment plans and evidence-based rehabilitation strategies at Ramp Physio and Fitness.</h2>
                    <p>Anterior Cruciate Ligament (ACL) injuries can be a significant setback for athletes and active individuals alike. At Ramp Physio and Fitness, we understand the complexities of ACL rehabilitation and the importance of a tailored approach to physiotherapy. Our commitment is to guide you through a recovery journey that not only addresses the physical aspects of healing but also supports your overall well-being and fitness goals. In the following sections, we'll explore the critical components of ACL injury physiotherapy and how our expert team can help you regain strength, mobility, and confidence.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Anatomy of ACL Recovery</h1>
                        <p>Understanding the intricacies of the knee joint and the role of the ACL is fundamental in appreciating the challenges and triumphs of recovery. The ACL is a key ligament that provides stability to the knee by preventing excessive forward movement of the tibia in relation to the femur. When an injury occurs, it can range from a partial tear to a complete rupture, each demanding a specific physiotherapy approach.</p>

                        <p>At Ramp Physio and Fitness, the journey begins with a comprehensive assessment that takes into account the extent of your injury, your physical condition, and your personal recovery goals. The initial phase of physiotherapy often focuses on reducing pain and swelling, regaining knee joint range of motion, and preventing muscle atrophy. This foundational stage sets the groundwork for more advanced rehabilitation techniques.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Rehabilitation Strategies</h1>
                    <p>As no two ACL injuries are the same, our physiotherapy programs are highly personalized. Evidence-based treatment plans are crafted by our skilled physiotherapists to address the unique needs of each individual. Central to our approach is a balance between manual therapy, which may include soft tissue work and joint mobilization, and therapeutic exercises designed to strengthen the muscles supporting the knee.</p>

                    <p>Progressive loading is a pivotal aspect of ACL rehabilitation. By gradually increasing the stress on the ligament and surrounding structures, we aim to rebuild your knee's resilience. Functional exercises and neuromuscular training are incorporated to enhance proprioception, which is critical for preventing future injuries. Our physiotherapists continually assess and adjust your program, ensuring optimal progression without overloading the healing tissue.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Chronic Pain and Injury Management</h1>
                    <p>For some, the path to recovery is complicated by chronic pain, which can be both physically and psychologically challenging. At Ramp Physio and Fitness, we employ a holistic approach to manage long-standing discomfort, integrating pain education, cognitive-behavioral strategies, and lifestyle modifications alongside traditional physiotherapy techniques.</p>

                    <p>Our team is adept at identifying factors that may contribute to persistent pain, such as biomechanical imbalances or compensatory movement patterns that have developed post-injury. By addressing these underlying issues, we work to alleviate chronic symptoms and facilitate a return to pain-free activity.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Group Training and Ongoing Support</h1>
                        <p>Rehabilitation doesn't end with the restoration of function. To prevent re-injury and support long-term fitness, Ramp Physio and Fitness offers group training classes tailored to individuals who have experienced an ACL injury. These classes provide a supportive environment where you can continue to build strength, endurance, and agility under the watchful eye of our experienced trainers.</p>
                        <p>Moreover, our commitment to your health extends to providing ongoing education and resources. We believe that equipping you with a deep understanding of injury prevention and self-management techniques is key to maintaining your active lifestyle. Our physiotherapists are dedicated to being your partners in wellness, offering guidance and encouragement every step of the way.</p>

                        <br></br>
                        <p>
                            In conclusion, ACL injury physiotherapy at Ramp Physio and Fitness is a comprehensive, patient-centered journey. Our expert team is passionate about delivering exceptional care, utilizing the latest research and rehabilitation methods to help you overcome your injury and achieve your fitness ambitions. Whether you're an elite athlete or someone who simply loves to move, we're here to support your path to full recovery and beyond.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
