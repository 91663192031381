import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsTailoredForSeniors = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts Tailored for Seniors | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert gym workouts and routines tailored for seniors to promote health and fitness at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Senior Strength and Vitality</h1>
                    <h2>Explore gym workouts tailored for seniors to enhance strength, flexibility, and overall well-being at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that staying active is crucial at any age, and it's especially important for seniors. Our expertly designed gym workouts are tailored to meet the unique needs of older adults, focusing on improving strength, balance, flexibility, and endurance. In this comprehensive guide, we'll share insights on how seniors can safely engage in physical activity to maintain their health and vitality.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Senior Fitness" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Adapting Fitness for Longevity</h1>
                        <p>Adapting fitness routines to accommodate the changing needs of our bodies as we age is essential. At Ramp Physio and Fitness, we specialize in creating gym workouts that are tailored for seniors, emphasizing low-impact exercises, strength training, and stretching routines. These workouts are designed to reduce the risk of injury, manage chronic conditions, and improve the quality of life for older adults.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Strength Training Benefits</h1>
                    <p>Strength training is a cornerstone of fitness for seniors, offering numerous benefits such as increased muscle mass, improved bone density, and better joint health. Our tailored strength training programs take into consideration the individual capabilities and goals of our senior members, ensuring a safe and effective workout experience.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Balance and Coordination</h1>
                    <p>Maintaining balance and coordination is crucial for preventing falls and injuries. Our senior-focused gym workouts include exercises that enhance proprioception and stability, helping to keep you confident and agile in your daily activities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Cardiovascular Health Focus</h1>
                        <p>Cardiovascular health is a significant concern for many seniors. Our gym provides a range of cardio workouts that are both enjoyable and tailored to the fitness levels of older adults. From walking on the treadmill to low-impact aerobics, we offer options to keep your heart healthy and your energy levels up.</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Flexibility and Mobility</h1>
                    <ul>
                        <li>Individually tailored and physiotherapy designed and monitored flexibility and core stability programs</li>
                        <li>Stretching routines to enhance mobility</li>
                        <li>Joint-friendly exercises to reduce stiffness</li>
                        <li>Functional movements for everyday tasks</li>
                        <li>Guidance on proper technique to prevent injury</li>
                        <li>Personalized modifications for individual needs</li>
                        <li>Group classes for social engagement and motivation</li>
                        <li>One-on-one sessions for focused attention</li>
                        <li>Workshops on maintaining an active lifestyle</li>
                        <li>Resources and support for continued practice at home</li>
                    </ul>
                    <p>Improving flexibility and mobility is an integral part of our senior gym workouts. We offer RAMP individually tailored and physiotherapy designed and monitored flexibility and core stability programs, as well as targeted stretching routines, to help you move better and feel great.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Community and Support</h1>
                        <p>At Ramp Physio and Fitness, we believe in the power of community. Our gym is not just a place to work out; it's a space where seniors can connect, share experiences, and support each other on their fitness journeys. Join us and be part of a welcoming and encouraging community.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsTailoredForSeniors;