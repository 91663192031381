import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Womenshealthphysiotherapy = () => {
    return (
        <div>
            <Helmet>
                <title>Women's Health Physiotherapy at Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Women's Health</h1>
                    <h2>Explore specialized prenatal and postnatal care with Women's Health Physiotherapy at Ramp Physio and Fitness, tailored to support women through every stage of motherhood.</h2>
                    <p>At Ramp Physio and Fitness, we understand the unique health challenges and changes women face during and after pregnancy. Our expert Women's Health Physiotherapy services offer comprehensive prenatal and postnatal care designed to empower women to maintain wellness and recover strength. In this article, we'll outline the benefits of physiotherapy during these crucial phases and how our individualized care can help you achieve your health and fitness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Women's Health Physiotherapy" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Prenatal Care and Support</h1>
                        <p>Our prenatal services focus on preparing your body for the physical demands of pregnancy and childbirth. From pelvic floor strengthening to managing back pain, we offer personalized programs that include:</p>
                        <ul>
                            <li>Exercise routines tailored for pregnancy</li>
                            <li>Pelvic floor muscle training</li>
                            <li>Pain management strategies</li>
                            <li>Posture and alignment correction</li>
                            <li>Education on labor positions</li>
                            <li>Techniques to aid in a smoother delivery</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Postnatal Recovery and Fitness</h1>
                    <p>After childbirth, our postnatal care assists in recovery and returning to fitness. We provide support with:</p>
                    <ul>
                            <li>Restoring core and pelvic floor function</li>
                            <li>Managing any residual pain or discomfort</li>
                            <li>Customized postnatal exercise plans</li>
                            <li>Strategies for safe lifting and carrying</li>
                            <li>Nutritional guidance for recovery and lactation</li>
                            <li>Emotional support and encouragement</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Comprehensive Women's Health</h1>
                    <p>Our Women's Health Physiotherapy extends beyond maternity care, addressing a variety of conditions specific to women, such as:</p>
                    <ul>
                        <li>Incontinence and bladder issues</li>
                        <li>Pelvic organ prolapse</li>
                        <li>Pelvic pain syndromes</li>
                        <li>Sexual dysfunction</li>
                        <li>Breast cancer rehabilitation</li>
                        <li>Osteoporosis and bone health</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Join Our Community</h1>
                    <p>Being part of our women's health community offers support and shared experiences. We provide:</p>
                    <ul>
                        <li>Group classes and workshops</li>
                        <li>Social support networks</li>
                        <li>Access to specialized resources</li>
                        <li>Events focusing on women's health</li>
                        <li>Collaborative care with other health professionals</li>
                        <li>A safe and inclusive environment</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Womenshealthphysiotherapy;