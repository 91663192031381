import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForImprovingCardiovascularHealth = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts for Cardiovascular Health | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the best gym workouts to enhance your cardiovascular health and reduce the risk of heart disease at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Optimize Your Heart Health</h1>
                    <h2>Empower your heart with gym workouts designed for peak cardiovascular health at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we understand that cardiovascular health is the cornerstone of a vibrant and active lifestyle. With a variety of gym workouts tailored to enhance your heart's strength and endurance, we're committed to helping you achieve and maintain optimal cardiovascular fitness. This comprehensive guide will navigate you through the most effective gym workouts to boost your heart health and provide you with the tools to thrive.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Workouts" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Cardio Training Fundamentals</h1>
                        <p>Cardiovascular exercise, commonly referred to as cardio, involves sustained physical activity that raises your heart rate and improves heart and lung function. A sound cardio training regimen can help reduce the risk of heart disease, improve cholesterol levels, and enhance overall well-being. At our facility, we offer a diverse range of cardio workouts that cater to all fitness levels and preferences.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Dynamic Cardio Workouts</h1>
                    <p>Engaging in a variety of cardio workouts is key to preventing monotony and promoting a well-rounded fitness routine. Here are 10 dynamic exercises that are effective in boosting cardiovascular health:</p>
                    <ul>
                        <li>Treadmill interval training</li>
                        <li>Stationary cycling</li>
                        <li>Rowing machine workouts</li>
                        <li>Elliptical cross-training</li>
                        <li>Group fitness classes</li>
                        <li>High-Intensity Interval Training (HIIT)</li>
                        <li>Stair climbing</li>
                        <li>Aqua aerobics</li>
                        <li>Outdoor circuit training</li>
                        <li>Boxing and kickboxing sessions</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Heart-Rate Monitoring</h1>
                    <p>Monitoring your heart rate during exercise allows you to gauge the intensity of your workout and ensure that you are training within a safe and effective range. We provide heart-rate monitoring tools and guidance to help you optimize your workouts for maximum cardiovascular benefit.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Structured Fitness Programs</h1>
                    <p>Our structured fitness programs are designed to progressively build your cardiovascular strength and endurance. With personalized coaching and a supportive community, you'll be motivated to push past your limits and achieve your heart health goals.</p>
                    <br></br>
                    <p>
                        Embrace the journey to a healthier heart with Ramp Physio and Fitness. Our gym workouts are more than just routines; they are stepping stones to a life filled with energy and vitality. Join us today and feel the power of a strengthened heart and an invigorated spirit.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForImprovingCardiovascularHealth;