import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TrainingTipsForMarathonPreparation = () => {
    return (
        <div>
            <Helmet>
                <title>Marathon Preparation - Training Tips | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert advice on marathon preparation with training tips for beginners to advanced runners at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Master Marathon Preparation</h1>
                    <h2>Unlock your potential with our comprehensive marathon training guide, tailored to help you from start to finish line.</h2>
                    <p>Embarking on the journey to complete a marathon is a formidable challenge that requires dedication, planning, and the right approach to training. At Ramp Physio and Fitness, we provide you with expert guidance to prepare for your marathon, whether you're a first-timer or an experienced runner seeking to improve your performance. In this article, we'll explore essential training tips to help you cross the finish line with confidence.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Our Training Program
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Marathon Preparation" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Building a Solid Foundation</h1>
                        <p>Starting your marathon training with a solid foundation is crucial. This means gradually increasing your mileage, focusing on consistency, and allowing your body to adapt to the demands of long-distance running. Here are key points to consider:</p>
                        <ul>
                            <li>Begin with shorter runs and progressively increase your distance.</li>
                            <li>Incorporate rest days to prevent overtraining and injuries.</li>
                            <li>Develop a balanced training plan that includes a mix of running, strength training, and flexibility exercises.</li>
                            <li>Listen to your body and adjust your training as needed.</li>
                            <li>Set realistic goals and milestones to keep you motivated.</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" /> {/* Placeholder image */}
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" /> {/* Placeholder image */}
                </div>
                <div className="service-intro-content2">
                    <h1>Selecting the Right Gear</h1>
                    <p>Choosing the right gear is a non-negotiable aspect of marathon training. Proper footwear can make or break your running experience. Here's what to keep in mind:</p>
                        <ul>
                            <li>Invest in a quality pair of running shoes that fit well and support your stride.</li>
                            <li>Consider visiting a specialty running store for a professional fitting.</li>
                            <li>Replace your running shoes every 300-500 miles to ensure adequate support.</li>
                            <li>Opt for moisture-wicking fabrics to keep you dry and comfortable.</li>
                            <li>Test your marathon day outfit on long runs to ensure it's chafe-free and comfortable.</li>
                        </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness - Your Marathon Training Partner
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Get Personalized Training
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Nutrition and Hydration Strategies</h1>
                    <p>Fueling your body correctly is essential for successful marathon training. A balanced diet and proper hydration can enhance your performance and recovery. Here are some tips:</p>
                        <ul>
                            <li>Consume a diet rich in complex carbohydrates, lean proteins, and healthy fats.</li>
                            <li>Stay hydrated throughout the day, not just during your runs.</li>
                            <li>Practice your race day nutrition strategy during longer training runs.</li>
                            <li>Experiment with different types of energy gels or chews to find what works for you.</li>
                            <li>Replenish electrolytes lost through sweat with sports drinks or electrolyte tablets.</li>
                        </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" /> {/* Placeholder image */}
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" /> {/* Placeholder image */}
                </div>
                <div className="service-intro-content2">
                    <h1>Recovery and Injury Prevention</h1>
                    <p>Recovery is as important as the training itself. Implementing effective recovery strategies will help prevent injuries and improve your overall marathon experience. Consider the following:</p>
                    <ul>
                        <li>Incorporate active recovery days with low-impact activities like cycling or swimming.</li>
                        <li>Use foam rolling and stretching to improve flexibility and reduce muscle tightness.</li>
                        <li>Get plenty of sleep to allow your body to repair and build strength.</li>
                        <li>Consider massage or physiotherapy to address any aches or pains promptly.</li>
                        <li>Listen to your body and take additional rest if you're feeling unusually fatigued or sore.</li>
                    </ul>
                    <br></br>
                    <p>
                        Preparing for a marathon is a complex process that requires careful planning and attention to detail. By following these training tips and partnering with Ramp Physio and Fitness, you'll be well on your way to a successful and enjoyable marathon experience. Remember, consistency is key, and with the right support, you can achieve your marathon goals. Let's get you started on this incredible journey!
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TrainingTipsForMarathonPreparation;