import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForThoracicOutletSyndrome = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Thoracic Outlet Syndrome - Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy for managing Thoracic Outlet Syndrome at Ramp Physio and Fitness in Homebush, NSW. Achieve relief and regain mobility with our tailored treatment plans." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Manage Thoracic Outlet Syndrome Effectively</h1>
                    <h2>Experience specialized physiotherapy for Thoracic Outlet Syndrome at Ramp Physio and Fitness, where personalized care meets clinical expertise to help you return to optimal function.</h2>
                    <p>Thoracic Outlet Syndrome (TOS) can be a complex and challenging condition, causing pain, numbness, and weakness in the neck, shoulder, and arm. At Ramp Physio and Fitness, our physiotherapists are well-versed in the latest evidence-based practices to provide effective management of TOS. Understanding the intricacies of this condition is essential for successful treatment, and our team is dedicated to guiding you through each step of your recovery journey. In this comprehensive guide, we'll explore the benefits of physiotherapy for Thoracic Outlet Syndrome and how our tailored approach can make a significant difference in your health and well-being.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding TOS and Its Impact</h1>
                        <p>Thoracic Outlet Syndrome arises when the blood vessels or nerves in the thoracic outlet — the space between your collarbone and first rib — become compressed. This can lead to a variety of symptoms, from discomfort to significant functional impairment. Recognizing the signs and seeking prompt treatment is crucial for managing TOS effectively. Our physiotherapy services are designed to address the root cause of compression, employing strategies that can help alleviate symptoms and restore mobility.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized TOS Treatment Plans</h1>
                    <p>Each case of Thoracic Outlet Syndrome is unique, and so are our treatment plans. At Ramp Physio and Fitness, we conduct thorough assessments to tailor a program that addresses your specific needs. Our physiotherapists utilize a range of techniques, from manual therapy to strengthening exercises, all aimed at reducing symptoms and preventing recurrence. Here are 10 key components of our TOS physiotherapy approach:</p>
                    <ul>
                        <li>Comprehensive evaluation to determine the type of TOS</li>
                        <li>Manual therapy to relieve nerve and vessel compression</li>
                        <li>Strengthening exercises for the neck and shoulder muscles</li>
                        <li>Posture correction and ergonomic advice</li>
                        <li>Stretching routines to improve flexibility</li>
                        <li>Neuromuscular re-education to optimize movement patterns</li>
                        <li>Pain management techniques</li>
                        <li>Education on activity modification to reduce symptoms</li>
                        <li>Strategies for managing stress that may exacerbate TOS</li>
                        <li>Guidance on long-term management to prevent recurrence</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>The Role of Exercise in TOS Recovery</h1>
                    <p>Exercise is a cornerstone of TOS management, with specific movements designed to strengthen and stretch the muscles around the thoracic outlet. Our physiotherapists guide you through targeted exercises that not only alleviate current symptoms but also reinforce your body's defenses against future issues. By integrating these exercises into your daily routine, you can maintain the gains made during therapy and continue to improve your condition.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Physiotherapy into Your Life</h1>
                    <p>At Ramp Physio and Fitness, we believe that physiotherapy should extend beyond the clinic. We empower you with knowledge and self-management techniques to integrate into your daily activities. Our holistic approach ensures that you not only recover from TOS but also enhance your overall health and prevent future injuries. Let us help you take control of your condition and lead a more active, pain-free life.</p>
                    <br></br>
                    <p>
                        In conclusion, Thoracic Outlet Syndrome can be a debilitating condition, but with the right approach, it's manageable. Our expert team at Ramp Physio and Fitness is committed to providing you with the highest standard of care, tailored to your individual needs. Through a combination of hands-on treatment, exercise, and education, we aim to help you overcome TOS and enjoy a healthier, more active lifestyle. Reach out to us to begin your journey to recovery and discover how physiotherapy can transform your life.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForThoracicOutletSyndrome;