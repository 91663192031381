import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforlateralepicondylitistenniselbow = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Overcoming Tennis Elbow with Expert Physiotherapy</h1>
                    <h2>Unlock the potential of physiotherapy for lateral epicondylitis (tennis elbow) and restore your arm's strength and functionality with Ramp Physio and Fitness.</h2>
                    <p>Lateral epicondylitis, commonly known as tennis elbow, is a condition that can hinder your daily activities and athletic performance. At Ramp Physio and Fitness, we specialize in providing expert physiotherapy services to address this painful condition. Our evidence-based approach combines manual therapy, exercises, and personalized treatment plans to help you overcome tennis elbow and get back to your favorite activities without discomfort. In this comprehensive guide, we will explore the benefits of physiotherapy for lateral epicondylitis and how our team can support your recovery journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Tennis Elbow</h1>
                        <p>Lateral epicondylitis, or tennis elbow, is a condition characterized by pain and tenderness on the outside of the elbow. It is typically caused by overuse of the forearm muscles and tendons, leading to inflammation and micro-tears. While commonly associated with racquet sports, it can affect anyone who engages in repetitive arm and wrist activities. Understanding the underlying causes and symptoms is the first step toward effective treatment, and our physiotherapists at Ramp Physio and Fitness are here to guide you through this process.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Treatment Modalities</h1>
                    <p>Physiotherapy for tennis elbow involves various treatment modalities tailored to each individual's condition. Our approach may include manual therapy to alleviate muscle tightness, therapeutic exercises to strengthen the affected muscles, and modalities like ultrasound or shockwave therapy to promote healing. Additionally, we focus on educating our clients about ergonomics and activity modification to prevent the recurrence of tennis elbow.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Prescription for Recovery</h1>
                    <p>Exercise is a cornerstone of physiotherapy for tennis elbow. Our physiotherapists prescribe specific exercises to stretch and strengthen the forearm muscles, improve grip strength, and enhance overall arm function. These exercises are designed to be performed both in our clinic under supervision and at home as part of a comprehensive home exercise program.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Care and Rehabilitation</h1>
                    <p>At Ramp Physio and Fitness, we understand that every case of tennis elbow is unique, which is why we offer personalized care and rehabilitation programs. Our team works closely with you to identify your specific needs and goals, ensuring that your treatment plan is tailored to facilitate a successful recovery and return to your daily activities.</p>
                    <br></br>
                    <p>
                        In conclusion, tennis elbow can be a debilitating condition, but with the right physiotherapy approach, you can achieve a full recovery and prevent future episodes. Our team at Ramp Physio and Fitness is dedicated to providing the highest standard of care to help you overcome lateral epicondylitis and enjoy a pain-free life. Don't let tennis elbow hold you back – contact us today to start your journey to recovery.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforlateralepicondylitistenniselbow;