import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const UnderstandingAndUtilizingActiveRecoveryDays = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Master Active Recovery Days</h1>
                    <h2>Unlock the potential of active recovery to boost performance and accelerate healing with the expert guidance of Ramp Physio and Fitness.</h2>
                    <p>Understanding and utilizing active recovery days are crucial components in any fitness regimen. At Ramp Physio and Fitness, we believe that active recovery is not just a buzzword; it's a strategic approach to enhance overall fitness and wellbeing. This comprehensive guide will explore the science of active recovery, its benefits, and how to implement it effectively into your routine.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Active Recovery" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science of Active Recovery</h1>
                        <p>Active recovery involves engaging in low-intensity exercise after periods of intense physical activity. This method aids in reducing muscle lactate levels, improving circulation, and facilitating the repair and growth of tissues. By incorporating active recovery into your routine, you can enhance your body's ability to rebound from strenuous workouts and reduce the risk of injury.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Benefits of Active Recovery</h1>
                    <p>Active recovery days come with a myriad of benefits. They help maintain a habit of regular physical activity, promote mental well-being by reducing stress, and aid in muscle recovery. Additionally, these days can improve flexibility, help manage soreness, and maintain cardiovascular fitness. Here are 10 examples of active recovery activities:</p>
                    <ul>
                        <li>Light jogging or brisk walking</li>
                        <li>Gentle cycling</li>
                        <li>Swimming</li>
                        <li>Core and flexibility training</li>
                        <li>Dynamic stretching</li>
                        <li>Foam rolling</li>
                        <li>Recreational sports</li>
                        <li>Resistance band exercises</li>
                        <li>Bodyweight movements</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book a Session
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Planning Your Active Recovery</h1>
                    <p>Creating a personalized active recovery plan is essential. It should consider your fitness level, workout intensity, and individual goals. At Ramp Physio and Fitness, we guide you through crafting a recovery day that complements your regular training regimen and supports your body's natural healing processes.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Active Recovery Techniques</h1>
                    <p>At Ramp Physio and Fitness, we offer a variety of techniques and services to aid in active recovery. These include guided stretching sessions, mobility workshops, and access to recovery tools such as foam rollers and resistance bands. Our team of experts is dedicated to helping you optimize your recovery for better performance and health.</p>
                    <br></br>
                    <p>
                        In conclusion, understanding and utilizing active recovery days are integral to a balanced fitness strategy. With the right guidance and approach, these days can significantly contribute to your overall health and athletic performance. Join us at Ramp Physio and Fitness to experience the transformative power of active recovery and take your fitness journey to new heights.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default UnderstandingAndUtilizingActiveRecoveryDays;