import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforchronicobstructivepulmonarydiseasecopd = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for COPD | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Comprehensive COPD Care</h1>
                    <h2>Experience a breath of fresh air with evidence-based physiotherapy for Chronic Obstructive Pulmonary Disease (COPD) at Ramp Physio and Fitness, your partner in managing and improving lung function.</h2>
                    <p>Chronic Obstructive Pulmonary Disease (COPD) is a progressive lung condition that affects millions worldwide. At Ramp Physio and Fitness, we understand the complexities of COPD and the impact it has on your quality of life. Our tailored physiotherapy programs are designed to help you breathe easier, enhance your lung capacity, and regain control over your daily activities. In this comprehensive guide, we'll explore the benefits of physiotherapy for COPD and how our specialized approach can make a significant difference in your health journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Breathe Better with Physio</h1>
                        <p>Physiotherapy is a cornerstone of COPD management, focusing on improving respiratory function and enhancing overall well-being. Our programs include a variety of techniques such as:
                            <ul>
                                <li>Breathing exercises</li>
                                <li>Airway clearance techniques</li>
                                <li>Strength and endurance training</li>
                                <li>Education on self-management</li>
                                <li>Energy conservation methods</li>
                                <li>Relaxation techniques</li>
                            </ul>
                            These elements are combined to create a personalized plan that addresses your unique needs and challenges with COPD.
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Enhancing Lung Health</h1>
                    <p>Pulmonary rehabilitation is a key aspect of our COPD treatment protocol. This multi-disciplinary approach combines:
                        <ul>
                            <li>Exercise training</li>
                            <li>Nutritional advice</li>
                            <li>Psychological support</li>
                            <li>Educational sessions</li>
                        </ul>
                        Our goal is to empower you with the knowledge and tools to manage your symptoms effectively, improving your capacity for exercise and daily activities.
                    </p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Empowering Self-Management</h1>
                    <p>Living with COPD requires a proactive approach to health. Our physiotherapy services not only provide immediate relief but also educate and equip you with strategies for long-term management. These include:
                        <ul>
                            <li>Understanding medication usage</li>
                            <li>Recognizing early signs of exacerbation</li>
                            <li>Developing an action plan for flare-ups</li>
                            <li>Smoking cessation support</li>
                            <li>Stress management strategies</li>
                            <li>Advice on environmental modifications</li>
                        </ul>
                        With our support, you'll gain the confidence to take charge of your COPD and maintain an active, fulfilling lifestyle.
                    </p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Active Lifestyle Adaptations</h1>
                    <p>Adapting your lifestyle to accommodate COPD is essential for maintaining independence and mobility. Our team at Ramp Physio and Fitness provides guidance on:
                        <ul>
                            <li>Exercise modifications</li>
                            <li>Assistive breathing devices</li>
                            <li>Home exercises for lung health</li>
                            <li>Nutritional support for respiratory health</li>
                            <li>Community resources and support groups</li>
                            <li>Techniques to cope with breathlessness</li>
                        </ul>
                        Together, we'll help you find the right balance between activity and rest, ensuring you can enjoy life to its fullest.
                    </p>
                    <br></br>
                    <p>
                        In summary, physiotherapy for COPD at Ramp Physio and Fitness is more than just treatment; it's a comprehensive program designed to improve your lung health and enhance your quality of life. Our evidence-based practices, personalized care plans, and dedicated team are committed to helping you breathe easier and live better. Take the first step towards better respiratory health by booking your appointment today.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforchronicobstructivepulmonarydiseasecopd;