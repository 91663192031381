import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForHeadachesAndMigraines = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviating Pain: Physiotherapy for Headaches</h1>
                    <h2>Explore the potential of physiotherapy to treat and prevent headaches and migraines, offering natural and effective relief for chronic sufferers.</h2>
                    <p>Headaches and migraines can be debilitating, affecting your daily life and well-being. At Ramp Physio and Fitness, our expert physiotherapists specialize in addressing the root causes of head pain through targeted treatments and preventative strategies. In this comprehensive guide, we'll explore the various physiotherapy techniques that can help relieve and manage headaches and migraines, ensuring you can lead a pain-free and active life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Headache Triggers</h1>
                        <p>Headaches can stem from various triggers, including muscle tension, poor posture, stress, and more. By identifying these factors, our physiotherapists can tailor a treatment plan that not only alleviates the current pain but also reduces the likelihood of future episodes. Our approach includes a thorough assessment to pinpoint the specific type of headache you're experiencing, be it tension-type, cervicogenic, or migraine-related.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Manual Therapy and Head Pain</h1>
                    <p>Manual therapy is a cornerstone of physiotherapy for headaches. Techniques such as spinal mobilization, manipulation, and soft tissue work can provide immediate relief from pain and stiffness. Our physiotherapists are skilled in these hands-on methods, which are proven to be effective in reducing headache frequency and intensity, particularly for cervicogenic headaches that originate from neck issues.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Therapy for Long-Term Relief</h1>
                    <p>Exercise therapy plays a crucial role in the long-term management of headaches and migraines. Strengthening and stretching exercises can improve posture, reduce muscle imbalances, and enhance neck stability. Our personalized exercise programs are designed to be easily integrated into your daily routine, helping you to maintain the benefits of physiotherapy and prevent the recurrence of head pain.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Education and Prevention Strategies</h1>
                    <p>Educating our clients about headache and migraine prevention is fundamental to our treatment approach. We provide insights into lifestyle modifications, ergonomic advice, and stress management techniques that can drastically reduce the impact of headaches on your life. By arming you with knowledge, we empower you to take control of your head pain and minimize its interference with your daily activities.</p>
                    <br></br>
                    <p>
                        In conclusion, physiotherapy offers a multifaceted approach to treating and preventing headaches and migraines. At Ramp Physio and Fitness, we are dedicated to providing you with the highest quality care and support to help you overcome the challenges of chronic head pain. If you're ready to take the first step towards a life free from headaches, book an appointment with us today and experience the transformative power of physiotherapy.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForHeadachesAndMigraines;