import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const RampFitnessPersonalTraining = () => {
    return (
        <div>
            <Helmet>
                <title>Personal Training Without the Price Tag | Ramp Physio and Fitness</title>
                <meta name="description" content="Ramp Physio and Fitness offers affordable, high-quality personal training with flexible scheduling and expert support. Join us for customized fitness programs that deliver exceptional results at a fraction of the cost!" />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Personal Training Without the Price Tag</h1>
                      <p>Discover a new era of personal training at Ramp Physio and Fitness, where premium, personalized coaching comes without the premium price. Our approach is designed to break down financial barriers to fitness, offering high-quality, results-oriented personal training that fits any budget. We believe everyone deserves access to expert guidance, so our model provides customized workout programs, multiple weekly training sessions, and ongoing support in a friendly, motivating environment—all at a fraction of traditional personal training costs. Unlike conventional gyms where quality training can mean high expenses, Ramp focuses on delivering maximum value for every member, creating a place where personal growth doesn’t come with a hefty price tag. With us, you’re not just getting a workout; you’re joining a community dedicated to helping you reach and maintain your fitness goals, whatever they may be. Embrace the Ramp difference and let us show you how effective, affordable, and sustainable personal training can be, putting the body and lifestyle you’ve always wanted well within reach.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Ramp PT" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Experience the RAMP Difference</h1>
                      <p>Transform your fitness journey with Ramp Physio and Fitness, a gym designed to deliver visible results with maximum efficiency. Our personal trainers go beyond typical training, integrating advanced equipment, progress tracking, and a custom Ramp app that keeps you on top of your goals. Every workout and every milestone counts, and our trainers are there to motivate you to achieve your personal best. With our flexible scheduling, supportive community, and cutting-edge tools, you’ll experience a difference that keeps you committed and continuously progressing.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Flexible and Trackable Fitness</h1>
                    <p>At Ramp Physio and Fitness, we believe that progress should be easy to see and convenient to achieve. Our custom Ramp app is a comprehensive tool that lets you track every aspect of your fitness journey, from daily tasks to personal bests. Our sessions are designed to fit your schedule and your life, allowing you to book flexible times that suit you best. Whether you’re a morning go-getter or prefer evening workouts, our trainers and app will guide you every step of the way, ensuring every session aligns with your unique path to success.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Affordable Excellence, Anytime</h1>
                    <p>Ramp Fitness in Homebush brings you the opportunity to work out when it suits you best, with doors open from 5 am to 9 pm, seven days a week, every week of the year. For a flat subscription fee, you gain access to three one-hour personal training sessions each week, a gym membership, and even our Evolt 360 body scanner – all included. Say goodbye to costly, session-based fees and embrace a fitness experience that gives you more for less.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Join Ramp Physio and Fitness Today!</h1>
                    <p>Ready to take your fitness to the next level? Don’t wait to start your journey with Ramp Physio and Fitness! Our team is here to support you every step of the way, with affordable personal training, flexible schedules, and unmatched value. Join us today and discover how our unique approach can transform your health, strength, and confidence.</p>
                    <br></br>
                    <p>Take the first step toward your best self – contact us now to schedule your first session and experience the Ramp difference firsthand!</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default RampFitnessPersonalTraining;