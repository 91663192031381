import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>Car Accident Injuries</h1>
                    <h2>Recover Safely from Car Accident Injuries with Expert Physiotherapy
                     and CTP Support</h2>
                    <p>At Ramp Physio and Fitness, we are highly experienced in helping individuals recover from car accident injuries with personalized physiotherapy treatments. Whether you've experienced whiplash, soft tissue damage, or other musculoskeletal injuries, our experienced team is here to guide you through a safe and effective recovery process. We also provide support in navigating CTP (Compulsory Third Party) insurance claims to ensure you receive the compensation and care you deserve. Our goal is to alleviate pain, restore function, and help you regain your quality of life.</p>
                    <p>Suffered a car accident injury? Don’t wait to start your recovery. Contact us today to book a consultation and begin your personalized physiotherapy treatment with CTP support.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Common Car Accident Injuries and Symptoms</h1>
                        <p>Car accidents can lead to a variety of injuries, including whiplash, back pain, shoulder injuries, and more. Symptoms may not always appear immediately, making it crucial to seek early treatment. At Ramp Physio and Fitness, we help you identify and address these common injuries with targeted physiotherapy, and provide assistance with CTP insurance claims to ensure you get the necessary support for a full recovery and avoid long-term complications.</p>
                        <p>Experiencing pain after a car accident? Early treatment is key to a full recovery. Reach out to us today to schedule an assessment and start your healing journey with comprehensive CTP supports.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Customized Rehabilitation Programs for Full Recovery After a Car Accident</h1>
                    <p>Recovery from a car accident requires a tailored approach that addresses your specific injuries and needs. Our physiotherapists at Ramp Physio and Fitness design personalized rehabilitation programs that include manual therapy, exercise, pain management strategies, and ergonomic advice. We work closely with you to guide you through the process of claiming CTP (Compulsory Third Party) insurance to cover your treatment costs and to ensure a smooth and complete recovery, helping you get back to your normal activities as soon as possible.</p>
                    <p>Ready to get back on track after a car accident? Contact us to create a customized rehabilitation plan that supports your recovery and helps you regain your strength and mobility, with CTP insurance assistance.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Overcoming Car Accident Injuries with Our Physiotherapy</h1>
                    <p>Our clients' stories showcase the effectiveness of our physiotherapy treatments for car accident injuries. From regaining mobility to overcoming chronic pain, our clients have achieved remarkable recoveries with our expert care. Read their testimonials and discover how Ramp Physio and Fitness can help you recover fully from your car accident injuries.</p>
                    <p>Be inspired by our clients' recovery journeys! Start your path to full recovery after a car accident with our expert physiotherapy services. Contact us today to book your appointment.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
