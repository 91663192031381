import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Pediatricphysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Pediatric Physiotherapy: Nurturing Young Lives</h1>
                    <h2>Explore how Pediatric Physiotherapy at Ramp Physio and Fitness supports children's development, fostering physical and cognitive growth through expert, compassionate care.</h2>
                    <p>Pediatric Physiotherapy plays a vital role in the healthy development of children. From infants to adolescents, our specialized therapists at Ramp Physio and Fitness provide tailored interventions that address a wide range of developmental challenges and physical disabilities. Understanding the unique needs of each child, we create engaging and effective treatment plans to promote mobility, enhance motor skills, and support overall well-being. In this comprehensive guide, we'll explore the transformative impact of Pediatric Physiotherapy on children's growth and development.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Empowering Developmental Milestones</h1>
                        <p>Pediatric Physiotherapy is essential for children who face challenges reaching developmental milestones. Our therapists specialize in techniques that assist with motor skills, balance, and coordination. By fostering a supportive environment, we empower children to achieve their individual milestones, whether it's taking their first steps, improving posture, or enhancing strength and flexibility. Here are some key areas we focus on:</p>
                        <ul>
                            <li>Gross motor skill development</li>
                            <li>Neuromuscular function enhancement</li>
                            <li>Balance and coordination training</li>
                            <li>Postural control and alignment</li>
                            <li>Strength and endurance building</li>
                            <li>Flexibility and range of motion exercises</li>
                            <li>Functional mobility and independence</li>
                            <li>Play-based therapy for engagement</li>
                            <li>Sensory integration therapy</li>
                            <li>Customized home exercise programs</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Family-Centered Care Approach</h1>
                    <p>At Ramp Physio and Fitness, we believe that family involvement is critical to the success of Pediatric Physiotherapy. Our family-centered care approach ensures that parents and caregivers are integral parts of the therapy process. By providing education and support, we help families understand their child's condition, participate in treatment, and reinforce therapeutic activities at home. This collaborative effort maximizes the child's progress and fosters a nurturing environment for growth.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Addressing a Spectrum of Needs</h1>
                    <p>Pediatric Physiotherapy addresses a wide spectrum of conditions and needs. Our experienced therapists are equipped to manage various pediatric concerns, including developmental delays, cerebral palsy, muscular dystrophy, and post-surgical rehabilitation. We also support children with genetic disorders, orthopedic conditions, and neurological impairments. Our comprehensive approach encompasses:</p>
                    <ul>
                        <li>Initial assessments and evaluations</li>
                        <li>Individualized treatment planning</li>
                        <li>Therapeutic exercise and manual therapy</li>
                        <li>Assistive device training and recommendations</li>
                        <li>Hydrotherapy and aquatic exercises</li>
                        <li>Neurodevelopmental treatment (NDT)</li>
                        <li>Constraint-induced movement therapy</li>
                        <li>Early intervention services</li>
                        <li>School-based physiotherapy support</li>
                        <li>Collaboration with multidisciplinary teams</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Cultivating Joy in Movement</h1>
                    <p>Our Pediatric Physiotherapists at Ramp Physio and Fitness are experts in making therapy enjoyable. By incorporating play into our sessions, we engage children in their treatment, making each exercise feel less like work and more like fun. This playful approach not only motivates children but also helps them associate positive feelings with movement, encouraging a lifelong appreciation for physical activity. We create a warm and inviting atmosphere where children can thrive and reach their full potential.</p>
                    <br></br>
                    <p>
                        In conclusion, Pediatric Physiotherapy at Ramp Physio and Fitness is a cornerstone of our commitment to helping children grow and develop to their fullest. With our expert team, state-of-the-art facilities, and compassionate approach, we stand ready to support your child's journey toward health and happiness. Discover the difference that specialized care can make and join the many families who trust us with their children's developmental needs.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Pediatricphysiotherapy;