import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NDIS = () => {
    return (
        <div>
            {/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Worker's Compensation</h1>
                    <h2>Expert Physiotherapy for Workers Compensation Cases</h2>
                    <p>
                        Workplace injuries can happen unexpectedly, whether in an office setting where repetitive strain may lead to chronic pain or in a physically demanding job where one wrong movement could result in a severe injury. At Ramp Physio and Fitness in Homebush, we have extensive experience supporting individuals who have been injured at work under the WorkCover system in New South Wales. Our primary objective is to empower workers with effective, evidence-based physiotherapy treatments so that they can recover function, alleviate pain, and restore mobility in the shortest, safest time possible.
                        <br/><br/>
                        When a workplace injury occurs, it’s not just about treating physical symptoms—there are also psychological, emotional, and economic factors to consider. You may be dealing with the stress of missing days or weeks of work, loss of income, or feeling uncertain about your future prospects. Many people find themselves overwhelmed by the logistics of filing a claim, obtaining the necessary medical certificates, and keeping track of the progress of their WorkCover case. This is where we step in to assist. Our staff is well-versed in the intricacies of the claims process and can guide you on how to seek approval for physiotherapy sessions, communicate effectively with case managers, and ensure that the treatment you need is fully covered as much as possible under the regulations.
                        <br/><br/>
                        Each of our physiotherapists has the clinical expertise to conduct a comprehensive assessment, starting with a thorough evaluation of your injury, medical history, and work-related tasks that may have caused or contributed to the injury. We then develop an individualized treatment plan, targeting specific goals such as pain relief, improved range of motion, stronger muscular support, and better ergonomic awareness in your daily activities. The therapeutic interventions may include manual therapy to reduce inflammation and tension, exercise-based rehabilitation to strengthen and stabilize weakened areas, and education sessions that help you avoid aggravating factors in the workplace.
                        <br/><br/>
                        At Ramp Physio and Fitness, we put a strong emphasis on early intervention, because initiating physiotherapy sooner often means a faster, more complete recovery. We believe in open communication with all stakeholders in your WorkCover journey—your insurer, your employer, and other healthcare providers—to ensure a seamless process. By working collaboratively, we strive to clear any barriers that may delay your healing or interrupt your return-to-work timeline.
                        <br/><br/>
                        Our state-of-the-art facility conveniently located with free onsite parking in Homebush boasts equipment and resources that enable a smooth transition from the initial acute injury management phase all the way to functional retraining, where you learn how to safely perform job tasks without risking re-injury. We approach each case with empathy and professionalism, recognizing that every person’s physical abilities, job requirements, and personal circumstances are different. If you have any reservations or doubts, our friendly team is here to address your questions and help you feel confident about the path forward.
                        <br/><br/>
                        In New South Wales, the WorkCover system is designed to support injured workers, but sometimes navigating the paperwork and approvals can be tricky. Our administrative support helps clarify the steps you need to take to secure funding for your physiotherapy sessions. We maintain transparent communication with your case manager, providing regular reports on your progress, setting milestones for recovery, and updating them on any modifications to your treatment plan. Whether you need help understanding your claim's next steps, clarifying what is covered, or scheduling appointments that fit your busy routine, you can count on us to provide hands-on assistance.
                        <br/><br/>
                        By focusing on a holistic and consistent approach, our goal is not just to treat your immediate pain but to equip you with long-term strategies. That may involve ergonomic assessments at your workplace, recommending changes to reduce strain, or teaching you simple stretches and exercises you can integrate into your workday. We also look at lifestyle factors—nutrition, stress management, and sleep hygiene—to promote a more comprehensive and sustainable recovery. Above all, we want you to feel empowered: you are at the center of your healing journey, and we’re here to facilitate the process with professional expertise and unwavering support.
                        <br/><br/>
                        If you’ve suffered a workplace injury, don’t delay seeking professional help. The right physiotherapy care can often be the difference between a quick, robust recovery and a drawn-out process that leaves you uncertain. By addressing your condition early, you can take proactive measures to avoid complications or chronic pain. We invite you to contact us at Ramp Physio and Fitness to schedule a consultation, discuss your unique concerns, and start building your personalized recovery program. Let us show you how we can help you not only get back to work but also regain confidence in your body’s capabilities.
                    </p>
                    <p>
                        Suffering from a workplace injury? Contact us today to schedule a consultation and start your personalized recovery plan with our experienced physiotherapists. The dedicated team at Ramp Physio and Fitness is here to walk you through each stage—helping you regain mobility, manage pain, and reestablish your ability to perform regular job tasks. From your very first appointment, you’ll notice our commitment to tailoring solutions that fit your situation and align with WorkCover policies in New South Wales.
                    </p>
                    <a
                        href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>

            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Navigating WorkCover: Your Rights and Options</h1>
                        <p>
                            WorkCover in New South Wales is designed to protect workers who have sustained injuries or illnesses in the course of their employment. Despite this safety net, many individuals feel overwhelmed when they first encounter the system. Terminology like “Work Capacity Certificate,” “Medical Expenses,” and “Return-to-Work Programs” can be confusing. At Ramp Physio and Fitness, we take the guesswork out of the process by simplifying the language, outlining each step, and ensuring that you understand your rights from day one. From lodging a claim to obtaining the appropriate referrals for physiotherapy and ongoing rehabilitation, our goal is to demystify the system and put you on the path to optimal recovery.
                            <br/><br/>
                            One key right you have under WorkCover is the right to choose your own healthcare provider. That means you can select your physiotherapy clinic—and by choosing Ramp Physio and Fitness, you’re entrusting your recovery to a team that truly values quality care. Our physiotherapists are skilled in both acute and chronic injury management, ensuring that your specific condition is addressed in the most effective manner possible. We’re also diligent about documentation; we keep detailed records of your progress, which can be submitted to insurers and relevant authorities to support your claim.
                            <br/><br/>
                            Another critical aspect is the Return-to-Work Program. Many workplaces in New South Wales have a structured approach to helping injured workers gradually re-enter their job roles. We coordinate with these programs by tailoring our physiotherapy treatment to align with each step of your return-to-work milestones. For instance, if your role requires lifting or repetitive movements, we incorporate functional exercises that mimic real-world tasks, ensuring that you’re not only healing but also building resilience against future injuries. The concept of “graded return to work” is central to WorkCover guidelines, and we support this approach by balancing your physical therapy sessions with rest intervals and incremental workload adjustments.
                            <br/><br/>
                            At times, disputes or uncertainties may arise regarding the extent of your injuries or the scope of treatments recommended. In such cases, you might need an independent medical examination or additional evidence to justify physiotherapy as a necessary part of your recovery. Our team can provide professional opinions and detailed assessments that not only outline your current capabilities but also project your potential for improvement with continued therapy. This evidence-based approach often helps expedite claim approvals and ensures that you receive the treatments you need without delay.
                            <br/><br/>
                            Being proactive in understanding your rights is crucial. While insurers and employers generally aim to support your recovery, sometimes confusion, administrative delays, or lack of information can slow the process. By staying informed—knowing which documents to submit, adhering to timelines, and being consistent with your physiotherapy sessions—you can help maintain a smooth, uninterrupted treatment journey. We encourage you to openly communicate with both your case manager and our clinic about any concerns you might have. The more we know about your situation, the better we can tailor the treatment and advocate on your behalf.
                            <br/><br/>
                            Many workers worry about potential repercussions of filing a claim, fearing it might affect their job security or relationships at work. It’s important to understand that workers compensation in NSW is there to protect you, and retaliatory actions by employers are not acceptable. In most cases, employers appreciate a transparent process and are keen to see you recover well and return to full duties. To this end, it’s often beneficial to keep your employer updated on your treatment plan and progress reports, which can help in adjusting workloads or responsibilities temporarily while you heal.
                            <br/><br/>
                            Navigating WorkCover successfully requires a team effort. You have the right to consult with various healthcare professionals—physiotherapists, occupational therapists, psychologists, and more—if the need arises. You also have the right to request a second opinion if you’re not comfortable with a particular recommendation. Ultimately, the system exists to ensure you’re able to recover from your workplace injury without facing additional financial strain. Ramp Physio and Fitness stands as your ally in this endeavor, offering comprehensive physiotherapy services, in-depth support, and a compassionate environment so you can focus on one thing: getting better.
                            <br/><br/>
                            Unsure about your workers compensation rights? Let us help you navigate the process. Reach out to us for a consultation and learn how we can support your recovery. By shedding light on your entitlements and guiding you through the system, we make sure you get the most out of your physiotherapy treatments. Whether it’s clarifying technical jargon, coordinating with insurers, or offering moral support during this challenging time, we are committed to seeing you successfully move beyond your injury.
                        </p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>

                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Customized Rehabilitation Programs for a Safe Return to Work</h1>
                        <p>
                            Each workplace injury is as unique as the person experiencing it, which is why a one-size-fits-all approach to rehabilitation rarely works. At Ramp Physio and Fitness, we begin by conducting a thorough assessment that examines your medical history, the type of injury you’ve sustained, and the physical demands of your job. For example, if you work in a warehouse setting that requires lifting heavy objects, your rehabilitation plan might emphasize spinal stability, proper lifting techniques, and strengthening key muscle groups in your back and core. Alternatively, if you spend long hours at a computer, we might focus on addressing posture-related stress, ergonomic desk adjustments, and targeted exercises to relieve tension in the neck, shoulders, and upper back.
                            <br/><br/>
                            Our physiotherapists work in close collaboration with you to set measurable and realistic goals. These could range from walking unassisted to resuming full-duty tasks at your workplace. We often incorporate a range of therapeutic modalities to speed up the recovery process. For instance, manual therapy might be used early on to reduce pain and inflammation, while subsequent sessions could incorporate hydrotherapy for low-impact exercises that build strength without putting undue pressure on injured tissues. Strength and conditioning programs might include resistance band work, free weights, or specialized gym equipment—always supervised to ensure your safety and proper form.
                            <br/><br/>
                            But rehabilitation isn’t just about exercises. We also integrate education on body mechanics and injury prevention. It’s one thing to recover from a current injury, but quite another to avoid re-injury and maintain a healthy body over the long term. This educational component is where we teach you proper lifting techniques, how to recognize early signs of overexertion, and ways to modify your workstation to support better posture. We delve into topics like the role of rest, sleep hygiene, and even nutritional considerations that can facilitate tissue healing and overall well-being.
                            <br/><br/>
                            The collaboration doesn’t end there. We regularly communicate with your case manager or insurance representative to provide updates on your progress. This ensures that everyone is on the same page regarding your rehabilitation milestones, any modifications needed, and the timeline for your anticipated return to work. If your work environment or duties need temporary adjustments—such as reduced hours or restricted movements—our physiotherapists can help outline these requirements, providing documented recommendations that your employer can use to support a safe and gradual transition back into your role.
                            <br/><br/>
                            Another factor that sets our rehabilitation approach apart is our commitment to personalized care. We understand that injuries can take a toll on mental health as well as physical health. Chronic pain or uncertainty about when—or if—you’ll return to your previous capabilities can sometimes lead to anxiety, stress, and even depression. Our team is here to provide empathy and support, encouraging open communication about how you’re feeling and adjusting treatment strategies if pain or stress is hindering your recovery. We strive to create an environment where you feel comfortable discussing all facets of your healing process.
                            <br/><br/>
                            Returning to work is more than just a physical milestone; it’s also an emotional and psychological one. In many cases, your sense of identity, financial stability, and daily routine revolve around your ability to perform your job. Therefore, a successful rehabilitation plan must address not just the injured tissues but also the psychosocial dimensions of recovery. This holistic approach often includes goal-setting discussions that incorporate your personal motivations and the real-world demands of your work. We might conduct scenario-based exercises or simulations that mimic common tasks in your job, helping you build confidence and functional strength simultaneously.
                            <br/><br/>
                            In short, a customized rehabilitation program is the backbone of effective workplace injury management. By tailoring treatment to your specific job requirements, physical condition, and personal goals, we aim to optimize both your short-term relief and your long-term resilience. When you’re ready to take the next step in your recovery journey, contact Ramp Physio and Fitness. We’ll create a personalized rehabilitation plan that aligns with your WorkCover guidelines, keeps you on track for a timely return to work, and sets you up for continued success well into the future.
                            <br/><br/>
                            Ready to start your rehabilitation journey? Contact us to create a personalized recovery plan that fits your needs and helps you get back to work safely.
                        </p>
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a
                        href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
<h1>Success Stories: Recovering from Workplace Injuries with Our Help</h1>
<p>
    Over the years, we at Ramp Physio and Fitness have witnessed countless inspiring success stories of workers—from all across New South Wales, including the suburbs of Homebush, Homebush West, Flemington, and North Strathfield—who have overcome daunting challenges to return to their jobs stronger than ever. From office workers who suffered from debilitating carpal tunnel syndrome to construction laborers with acute back injuries, each journey is a testament to the human body’s remarkable resilience and the power of specialized physiotherapy.
    <br/><br/>
    Take, for instance, the case of a warehouse employee from the Homebush West area who came to us with a severe rotator cuff injury. Initially, this client struggled to lift even light objects without experiencing sharp, radiating pain in the shoulder. Through a carefully orchestrated plan that included manual therapy, progressive strengthening exercises, and education on proper lifting mechanics, the individual regained full range of motion in just a few months. The psychological impact was equally profound; what started as uncertainty about future employability became a renewed sense of confidence, culminating in the client returning to work with the ability to perform all pre-injury tasks.
    <br/><br/>
    Another powerful example is a young teacher based near Flemington who developed chronic neck and shoulder pain due to prolonged hours at a computer, lesson planning, and marking assignments. Initially dismissed as “stress-related,” the pain escalated until it was affecting her ability to stand for long periods or even carry basic classroom materials. After being referred to Ramp Physio and Fitness, she discovered that her posture, workstation setup, and repetitive strain were contributing factors. Our holistic approach helped her modify her workstation ergonomics, learn posture-correcting exercises, and incorporate restorative techniques such as gentle stretching breaks during the workday. This client not only returned to full teaching duties without pain but also reported improved productivity and a greater sense of well-being.
    <br/><br/>
    The stories go on: an injured driver from Homebush who overcame whiplash and lower back problems, a nurse from North Strathfield who returned to their ward after battling foot and ankle pain, and a landscaping professional who bounced back from a debilitating knee injury to resume active duty. In all these cases, physiotherapy played a pivotal role, targeting specific impairments while preparing the individual for the unique physical demands of their job.
    <br/><br/>
    We recognize that recovery is rarely linear; it can involve setbacks, plateaus, and moments of frustration. However, our success stories often share a common thread: resilience fortified by expert guidance. When clients are empowered with the right tools and education to take an active role in their recovery, extraordinary results become possible. We celebrate these victories because they reaffirm our mission: to provide quality physiotherapy that not only addresses immediate pain but also sets the stage for a sustainable return to a fulfilling work and personal life.
    <br/><br/>
    Reading about others who have walked a similar path can be a source of hope and inspiration. Many of our clients have openly shared their experiences to encourage fellow injured workers. If you’re dealing with an injury and feeling overwhelmed about the future, these success stories can serve as an uplifting reminder that with the right treatment plan, determination, and professional support, a return to normal—or even better—function is well within reach.
    <br/><br/>
    Be inspired by our clients' recovery stories! Whether you're in Homebush, Homebush West, Flemington, North Strathfield, or beyond, you can start your journey to recovery with our expert physiotherapy services. Contact us today to book your appointment and take the first step toward getting back to work.
</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>

            <SkewedContainer top="left" bgColor="white" noMargin>
                <HealthSection />
            </SkewedContainer>

            <TestimonialSection />
        </div>
    );
};

export default NDIS;
