import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Stayingmotivatedsettingandachievingfitnessgoals = () => {
    return (
        <div>
            <Helmet>
                <title>Staying Motivated: Fitness Goals | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover strategies to stay motivated and achieve your fitness goals with expert advice from Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Staying Motivated: Fitness Goals</h1>
                    <h2>Unlock your potential with proven strategies for setting and achieving your fitness goals at Ramp Physio and Fitness.</h2>
                    <p>Achieving your fitness goals is a journey that requires commitment, discipline, and motivation. At Ramp Physio and Fitness, we understand the challenges of staying on track. In this comprehensive guide, we'll share expert advice on setting realistic goals, maintaining motivation, and overcoming obstacles to ensure your success.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Get Started
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Fitness Motivation" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Define Your Fitness Vision</h1>
                        <p>Embarking on a fitness journey starts with a clear vision. What do you want to achieve? Whether it's running a marathon, improving strength, or enhancing overall well-being, defining your end goal is the first step towards success. At Ramp Physio and Fitness, we help you visualize your goals and create a roadmap to reach them.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Commit to Consistent Action</h1>
                    <p>Consistency is key to progress. We encourage you to commit to regular exercise, even when motivation wanes. Our personalized training programs and supportive community at Ramp Physio and Fitness keep you accountable and on the path to achieving your fitness goals.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Get Started
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Track and Celebrate Progress</h1>
                    <p>Monitoring your progress helps maintain motivation and recognize achievements. At Ramp Physio and Fitness, we track your milestones and celebrate your successes, big or small, to keep you inspired and motivated throughout your fitness journey.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Overcome Obstacles with Resilience</h1>
                        <p>Setbacks are a natural part of any journey. We teach resilience and provide strategies to overcome obstacles, ensuring that challenges become stepping stones rather than roadblocks on your path to fitness.</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Embrace a Supportive Environment</h1>
                    <p>The right environment can significantly impact your motivation. Ramp Physio and Fitness offers a supportive and encouraging space where everyone works towards their fitness goals together, fostering a sense of community and belonging.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container6">
                    <div className="service-intro-content6">
                        <h1>Customize Your Fitness Plan</h1>
                        <p>Personalization is crucial for sustained motivation. Our expert physiotherapists and fitness trainers at Ramp Physio and Fitness design customized plans that align with your goals, preferences, and lifestyle, ensuring that your fitness routine is both effective and enjoyable.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container7">
                <div className="service-intro-content7">
                    <h1>Integrate Mindfulness and Recovery</h1>
                    <p>Physical fitness is also about mental well-being. We incorporate mindfulness practices and emphasize recovery to ensure you stay balanced and prevent burnout, helping you stay motivated for the long term.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container8">
                    <div className="service-intro-content8">
                        <h1>Leverage Technology and Innovation</h1>
                        <p>Stay ahead of the curve by leveraging the latest technology and innovative training methods available at Ramp Physio and Fitness. Our state-of-the-art equipment and techniques keep your workouts fresh and engaging.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Stayingmotivatedsettingandachievingfitnessgoals;