import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Thebenefitsofhighproteindietsintraining = () => {
    return (
        <div>
            <Helmet>
                <title>The Benefits of High-Protein Diets in Training | Ramp Physio and Fitness</title>
                <meta name="description" content="Learn how high-protein diets can enhance your training and fitness goals with Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximizing Gains with High-Protein Diets</h1>
                    <h2>Unlock the power of protein to boost your training results and recovery with the expert guidance at Ramp Physio and Fitness.</h2>
                    <p>Understanding the role of nutrition in fitness is key to achieving your health and performance goals. High-protein diets have garnered attention for their potential to enhance training outcomes. At Ramp Physio and Fitness, we're here to explore the science-backed benefits of incorporating more protein into your diet and how it can complement your workout regimen.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Protein: The Building Block of Muscles</h1>
                        <p>Proteins are the building blocks of muscle and are crucial for repair and growth. A high-protein diet supports muscle synthesis, which is vital for anyone engaged in regular training. The amino acids in protein serve as the raw materials for muscle repair, helping you recover faster and build strength more effectively.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Protein for Performance and Endurance</h1>
                    <p>Not only does protein contribute to muscle growth, but it also plays a role in sustaining energy levels during exercise. Adequate protein intake can help maintain endurance, allowing you to perform at your best throughout your entire workout.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Learn More
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Weight Management and Protein</h1>
                    <p>High-protein diets can aid in weight management by promoting satiety and preserving lean muscle mass during weight loss. By feeling fuller for longer, you're less likely to overeat, which can be beneficial for those looking to manage or reduce their body weight.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Optimizing Your Protein Intake</h1>
                    <p>Understanding how much protein you need can be complex. Our team at Ramp Physio and Fitness can help tailor a nutrition plan that aligns with your training intensity and goals, ensuring you're consuming the right amount of protein to support your fitness journey.</p>
                    <br></br>
                    <ul>
                        <li>Personalized nutrition assessments</li>
                        <li>Protein intake tailored to workout intensity</li>
                        <li>Sustainable diet plans for long-term health</li>
                        <li>Support for muscle growth and recovery</li>
                        <li>Guidance on protein-rich foods and supplements</li>
                        <li>Strategies to improve overall dietary habits</li>
                    </ul>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Thebenefitsofhighproteindietsintraining;