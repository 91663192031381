import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PhysiotherapyForPatellarDislocation = () => {
    return (
        <div>
            <Helmet>
                <title>Effective Physiotherapy for Patellar Dislocation | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert physiotherapy for patellar dislocation recovery. Tailored treatments to restore strength & mobility at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert Care for Patellar Dislocation</h1>
                    <h2>Regain knee stability and function with our specialized physiotherapy protocols for patellar dislocation at Ramp Physio and Fitness.</h2>
                    <p>Dealing with a patellar dislocation can be a challenging experience, but with the right care and rehabilitation, you can return to your normal activities with confidence. At Ramp Physio and Fitness, our expert team is equipped with the latest knowledge and techniques to guide you through a successful recovery journey. In this detailed guide, we'll explore the intricacies of physiotherapy treatment for patellar dislocation and how our tailored approach can help you achieve optimal results.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Patellar Dislocation</h1>
                        <p>Patellar dislocation occurs when the kneecap (patella) slips out of its normal position, often resulting in pain and swelling. It's essential to address this condition promptly and effectively to prevent future instability. Our physiotherapists at Ramp Physio and Fitness utilize evidence-based practices to reduce your pain, restore patellar alignment, and strengthen the surrounding musculature to prevent recurrence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Phases of Patellar Dislocation Rehab</h1>
                    <p>Rehabilitation following a patellar dislocation is typically divided into distinct phases, each with specific goals and exercises. These phases include acute management to reduce pain and swelling, followed by progressive strengthening and functional training. Our physiotherapists customize your rehab program to address your unique needs and ensure a safe return to your daily activities or sports.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Individualized Treatment Strategies</h1>
                    <p>Our physiotherapy approach for patellar dislocation is highly individualized, taking into account factors such as the extent of injury, patient goals, and lifestyle. We focus on a combination of manual therapy, specific exercises, and patient education to promote healing and prevent future dislocations.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Advanced Techniques and Equipment</h1>
                        <p>At Ramp Physio and Fitness, we employ advanced techniques and state-of-the-art equipment to facilitate your recovery from patellar dislocation. These may include proprioceptive training, balance exercises, and modalities such as ultrasound or electrical stimulation to enhance tissue healing and pain relief.</p>
                        <br></br>
                        <p>
                            Our commitment to your recovery doesn't end when you leave our clinic. We provide resources and support to help you maintain your knee health and prevent future injuries. Trust Ramp Physio and Fitness to be your partner in overcoming patellar dislocation and achieving long-term knee stability.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyForPatellarDislocation;