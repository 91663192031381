import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForDevelopmentalDelay = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Developmental Delay | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Growth: NDIS Physiotherapy for Developmental Delay</h1>
                    <h2>Discover how Ramp Physio and Fitness provides specialized NDIS physiotherapy services to support children and adults with developmental delays.</h2>
                    <p>Developmental delays can present unique challenges, but with the right support, individuals can achieve remarkable progress. Ramp Physio and Fitness is committed to offering expert NDIS physiotherapy services that cater to the needs of those with developmental delays. Our approach is holistic, evidence-based, and tailored to each individual's capabilities and goals. In this comprehensive guide, we will explore how our physiotherapy services can make a positive impact on the lives of our clients.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="NDIS Physiotherapy for Developmental Delay" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Developmental Delays</h1>
                        <p>Developmental delays can affect a child's physical, cognitive, communication, social, emotional, or behavioral skills. These delays can be due to various reasons, including genetic factors, complications during pregnancy, or environmental influences. Early intervention through physiotherapy is crucial in helping children reach their full potential.</p>

                        <p>At Ramp Physio and Fitness, our NDIS physiotherapy for developmental delay begins with a thorough assessment to identify the specific needs of each client. We create personalized treatment plans that focus on enhancing motor skills, strength, coordination, and overall mobility.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Family-Centered Approach to Care</h1>
                    <p>Our NDIS physiotherapy services adopt a family-centered approach, recognizing the importance of involving family members in the treatment process. We work closely with parents and caregivers to empower them with strategies and exercises that can be integrated into daily routines to support ongoing development.</p>

                    <p>By fostering an environment of collaboration and education, we help families become active participants in the journey toward improved health and independence for their loved ones with developmental delays.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrative Therapeutic Techniques</h1>
                    <p>Our physiotherapists use a variety of integrative therapeutic techniques to address developmental delays. These may include neurodevelopmental therapy, sensory integration therapy, and motor learning strategies. We aim to create a fun and engaging atmosphere where clients can thrive.</p>

                    <p>Key therapeutic techniques include:</p>
                    <ul>
                        <li>Hands-on guidance to facilitate movement</li>
                        <li>Exercises to improve balance and coordination</li>
                        <li>Strength training for muscle development</li>
                        <li>Activities to enhance fine and gross motor skills</li>
                        <li>Play-based interventions to promote learning</li>
                        <li>Use of adaptive equipment for support and independence</li>
                        <li>Gait training and mobility enhancement</li>
                        <li>Sensory processing activities to improve focus and attention</li>
                        <li>Cognitive-behavioral strategies for social engagement</li>
                        <li>Education on home exercise programs for continued progress</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                        <h1>Supporting Independence and Participation</h1>
                        <p>Our goal is to support the independence and participation of clients with developmental delays in all areas of life. We provide training for the use of adaptive equipment and assistive technology that can enhance autonomy at home, school, and in the community.</p>
                        <p>Additionally, we offer guidance on transitioning to adult services and support networks that can facilitate lifelong health and well-being.</p>

                        <br></br>
                        <p>
                            In conclusion, NDIS physiotherapy for developmental delay at Ramp Physio and Fitness is a holistic and client-focused journey. Our dedicated physiotherapists employ innovative and effective strategies to support each individual's growth and development. We are committed to working alongside our clients and their families to achieve meaningful progress and enhance quality of life.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForDevelopmentalDelay;