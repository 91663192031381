import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForFragileXSyndrome = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Fragile X Syndrome | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Fragile X Syndrome</h1>
                    <h2>Empowering Individuals with Tailored Physiotherapy and Fitness Programs</h2>
                    <p>Fragile X Syndrome is a genetic condition that can lead to a range of developmental issues, including physical challenges. At Ramp Physio and Fitness, we specialize in providing comprehensive and compassionate NDIS physiotherapy services to individuals with Fragile X Syndrome. Our approach is designed to enhance mobility, strength, and overall well-being, helping our clients achieve their health and fitness goals within an understanding and supportive environment.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Fragile X Syndrome</h1>
                        <p>Fragile X Syndrome is the most common inherited cause of intellectual disability and autism. It can result in a spectrum of challenges, including physical impairments that benefit from physiotherapy. Our NDIS physiotherapy services cater to the unique needs of those with Fragile X Syndrome, focusing on enhancing physical function and promoting independence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_1284079182.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Massage" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Approaches</h1>
                    <p>Our expert physiotherapists develop personalized treatment plans that address the physical symptoms associated with Fragile X Syndrome. We use a combination of hands-on techniques, therapeutic exercises, and assistive technologies to improve balance, coordination, muscle strength, and joint mobility, fostering greater autonomy and quality of life.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Daily Living Skills</h1>
                    <p>Our NDIS physiotherapy services are not just about addressing the physical aspects of Fragile X Syndrome. We also focus on enhancing daily living skills through targeted exercises that promote functional movements, aiding in activities of daily living and increasing participation in community life.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Gym Floor" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Pin Loaded Equipment" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Supporting Developmental Milestones</h1>
                        <p>We recognize the importance of achieving developmental milestones. Our physiotherapy programs are designed to support developmental progress, focusing on motor skills and cognitive function enhancement to aid in the growth and learning of our clients with Fragile X Syndrome.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForFragileXSyndrome;