import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Trainingwithfreeweightsvsmachines = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Training with Free Weights vs. Machines</h1>
                    <h2>Unlock the Potential of Strength Training at Ramp Physio and Fitness</h2>
                    <p>Strength training is a cornerstone of fitness, and choosing the right equipment can make all the difference. At Ramp Physio and Fitness in Homebush, NSW, we understand the unique benefits and challenges of training with free weights and machines. In this comprehensive guide, we'll explore the advantages of each method to help you make an informed decision about your training regimen.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Benefits of Free Weight Training</h1>
                        <p>Free weights, such as dumbbells and barbells, are renowned for their versatility and the ability to mimic natural movement patterns. They engage more stabilizing muscles and can lead to greater functional strength. Here are key points to consider:</p>
                        <ul>
                            <li>Enhanced muscle activation</li>
                            <li>Improved balance and coordination</li>
                            <li>Greater exercise variety</li>
                            <li>Functional strength development</li>
                            <li>Accessibility for all fitness levels</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advantages of Machine Training</h1>
                    <p>Weight machines offer a controlled environment, ideal for beginners and those with specific rehabilitation needs. They ensure proper form, isolate specific muscle groups, and can be particularly beneficial for progressive overload. Consider the following:</p>
                    <ul>
                        <li>Guided motion for safety</li>
                        <li>Targeted muscle training</li>
                        <li>Easy to use for beginners</li>
                        <li>Reduced need for spotter</li>
                        <li>Consistent resistance</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Combining Free Weights and Machines</h1>
                    <p>Integrating both free weights and machines into your training can provide a comprehensive workout experience. This approach allows you to take advantage of the benefits each has to offer. Here are some strategies for combining them effectively:</p>
                    <ul>
                        <li>Start with machines for warm-up</li>
                        <li>Use free weights for compound movements</li>
                        <li>Employ machines for isolation exercises</li>
                        <li>Mix modalities for varied stimulus</li>
                        <li>Balance workouts to prevent overuse injuries</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Training Programs</h1>
                    <p>Our experienced trainers at Ramp Physio and Fitness personalize your training program to match your goals, whether you're looking to build strength, lose weight, or enhance athletic performance. We consider factors like training history, injury prevention, and personal preferences to create a balanced and effective plan.</p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Trainingwithfreeweightsvsmachines;