import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforflatfeetpesplanus = () => {
    return (
        <div>
            <Helmet>
                <title>Physiotherapy for Flat Feet (Pes Planus) | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Effective Relief for Flat Feet</h1>
                    <h2>Explore our specialized physiotherapy solutions for flat feet (Pes Planus) at Ramp Physio and Fitness and take the first step towards improved mobility and comfort.</h2>
                    <p>Flat feet, also known as Pes Planus, can lead to various physical discomforts and challenges. At Ramp Physio and Fitness, we understand the complexities of flat feet and offer tailored physiotherapy programs to address this condition. Our approach is rooted in evidence-based practices and personalized care to help alleviate pain, enhance foot function, and improve your overall quality of life. In this comprehensive guide, we'll explore the causes, symptoms, and physiotherapy management for flat feet, providing you with valuable insights and practical solutions.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Flat Feet Physiotherapy" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Flat Feet</h1>
                        <p>Flat feet occur when the arches of the feet collapse, leading to the entire sole of the foot coming into complete or near-complete contact with the ground. This condition can be congenital or acquired due to factors like obesity, injury, or wear and tear over time. Symptoms often include foot pain, particularly in the heel or arch area, swelling along the inside of the ankle, and difficulty performing certain activities. Our physiotherapists at Ramp Physio and Fitness are well-versed in diagnosing and managing flat feet through a range of therapeutic interventions.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Management</h1>
                    <p>Management of flat feet includes a combination of exercises, orthotics, and lifestyle modifications. Our physiotherapists tailor each program to the individual's needs, focusing on strengthening and stretching exercises that can help to rebuild the arch muscles. Orthotics may also be recommended to provide additional support and distribute pressure more evenly across the foot. In cases where conservative management is not effective, we work closely with medical professionals to determine if surgical intervention may be necessary.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Customized Exercise Programs</h1>
                    <p>At Ramp Physio and Fitness, we design customized exercise programs that target the specific needs of individuals with flat feet. These exercises aim to strengthen the muscles around the arches, improve balance, and enhance foot mechanics. Some of the exercises we may include are heel raises, toe exercises, and arch lifts. Our physiotherapists provide guidance on proper technique and progression to ensure safe and effective practice.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Orthotics and Support</h1>
                    <p>Orthotics play a crucial role in the management of flat feet by providing support and reducing strain on the arches. We offer custom-fitted orthotic solutions that cater to your unique foot structure and functional needs. These devices can be inserted into your footwear to improve alignment, relieve pressure points, and enhance comfort during daily activities. Our team will help you select the appropriate orthotics and ensure they are properly fitted for optimal benefit.</p>
                    <br></br>
                    <p>
                        In conclusion, physiotherapy for flat feet is a vital service we offer at Ramp Physio and Fitness. Our dedicated team is committed to providing you with the highest quality care and support throughout your journey to better foot health. Whether you're experiencing discomfort, seeking to improve your athletic performance, or aiming to prevent future issues, we are here to help. Contact us today to learn more and book your appointment.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforflatfeetpesplanus;