import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const EffectiveGymRoutinesForBeginners = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Effective Gym Routines for Beginners</h1>
                    <h2>Embark on a Fitness Journey with Confidence at Ramp Physio and Fitness</h2>
                    <p>Starting a fitness journey can be daunting, but with the right guidance and effective gym routines, beginners can set themselves up for success. At Ramp Physio and Fitness, our goal is to help you navigate the gym landscape with tailored workouts that build a solid foundation for your health and fitness aspirations. In this comprehensive guide, we'll walk you through gym routines that are perfect for beginners, ensuring you start on the right foot.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Effective Gym Routines for Beginners" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Building a Workout Foundation</h1>
                        <p>For those new to the gym, it's essential to build a workout foundation that promotes balance, strength, and endurance. Our beginner routines start with fundamental movements that work multiple muscle groups, ensuring a balanced approach to fitness. We emphasize proper form and technique to prevent injuries and maximize the effectiveness of each exercise. Here are key components of our foundational routine:</p>
                        <ul>
                            <li>Full-body workouts for overall strength</li>
                            <li>Core strengthening exercises for stability</li>
                            <li>Cardio routines for endurance and heart health</li>
                            <li>Flexibility exercises to improve motion range</li>
                            <li>Balance training to enhance coordination</li>
                            <li>Progressive overload principles for continuous improvement</li>
                        </ul>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Creating a Balanced Routine</h1>
                    <p>A balanced routine is crucial for beginners to develop strength evenly and prevent muscle imbalances. At Ramp Physio and Fitness, we create routines that target all the major muscle groups, alternating between different body parts to allow for recovery. Our beginner's routine includes:</p>
                    <ul>
                        <li>Push exercises (e.g., chest press, shoulder press)</li>
                        <li>Pull exercises (e.g., rows, pull-ups)</li>
                        <li>Leg exercises (e.g., squats, lunges)</li>
                        <li>Core exercises (e.g., planks, crunches)</li>
                        <li>Cardiovascular exercises (e.g., cycling, treadmill)</li>
                        <li>Stretching and cool-down routines</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Achieve Your Fitness Goals with Us
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Start Today
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Understanding Gym Equipment</h1>
                    <p>Navigating gym equipment can be overwhelming for beginners. Our team at Ramp Physio and Fitness provides comprehensive guidance on how to use each piece of equipment safely and effectively. We'll introduce you to:</p>
                    <ul>
                        <li>Resistance machines for targeted muscle training</li>
                        <li>Free weights for functional strength building</li>
                        <li>Cardio machines for endurance and calorie burning</li>
                        <li>Stretching areas for flexibility and recovery</li>
                        <li>Functional training equipment for core and balance</li>
                        <li>Accessories like bands and balls for variety in workouts</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personal Training and Support</h1>
                    <p>Personal training is a fantastic way for beginners to get accustomed to the gym environment. Our certified trainers provide one-on-one support, ensuring you perform exercises correctly and efficiently. With personal training, you'll benefit from:</p>
                    <ul>
                        <li>Customized workout plans based on your goals</li>
                        <li>Technique correction and form adjustments</li>
                        <li>Motivation and accountability</li>
                        <li>Nutritional advice and lifestyle tips</li>
                        <li>Ongoing assessments to track progress</li>
                        <li>Support in establishing a sustainable fitness routine</li>
                    </ul>
                    <br></br>
                    <p>
                        Starting your gym journey at Ramp Physio and Fitness means you're not alone. We're here to support you every step of the way, from your first workout to reaching and setting new fitness milestones. With our expert guidance and effective gym routines for beginners, you're on the path to a healthier, fitter you. Let's make fitness a part of your lifestyle together.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default EffectiveGymRoutinesForBeginners;