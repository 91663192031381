import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import gaitBalanceLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForGaitAndBalance = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Movement: Gait and Balance Optimization</h1>
                    <h2>Discover the power of tailored physiotherapy treatment and exercises to enhance your gait and balance at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we are dedicated to helping our clients achieve optimal movement through targeted gait and balance exercises. Our approach to physiotherapy treatment is rooted in evidence-based practice, ensuring that every exercise and intervention we recommend is designed to meet your unique needs. In this comprehensive guide, we'll delve into the importance of gait and balance, the types of treatments available, and how our team of experts can support you in achieving greater mobility and stability.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Gait Mechanics</h1>
                        <p>Gait refers to the manner in which we walk, which is a complex process involving coordination and balance. An efficient gait is crucial for maintaining mobility and independence. Disturbances in gait can lead to a higher risk of falls and other injuries.</p>

                        <p>At Ramp Physio and Fitness, we start with a comprehensive gait analysis to identify any abnormalities or imbalances. Our physiotherapists are skilled in detecting subtle deviations in your walking pattern that could be contributing to mobility issues or discomfort.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Restoring Balance and Stability</h1>
                    <p>Balance is the ability to maintain the body's center of mass over its base of support. It is essential for performing daily activities safely and effectively. Our physiotherapy treatments aim to improve both static and dynamic balance through targeted exercises.</p>

                    <p>Static balance exercises focus on maintaining a steady position while standing or sitting, whereas dynamic balance exercises involve maintaining stability while the body is in motion. Our team creates a blend of both to challenge and improve your balance system.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={gaitBalanceLogo} alt="Gait and Balance Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personalized Gait Training Regimen</h1>
                    <p>Gait training is a type of physical therapy that helps you learn to walk again or improve your walking following an injury or due to a disability. Our physiotherapists design personalized gait training regimens that cater to your specific condition, ensuring optimal outcomes.</p>

                    <p>Through a series of exercises and the use of specialized equipment, we help you regain strength, coordination, and confidence in your walking abilities. Our aim is to enhance your functional independence and overall quality of life.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Techniques for Improved Mobility</h1>
                    <p>Our physiotherapists employ advanced techniques, such as proprioceptive neuromuscular facilitation (PNF) and motor learning strategies, to further enhance gait and balance. These methods are effective in retraining the nervous system and improving the communication between your muscles and brain.</p>
                    <p>By incorporating these advanced techniques into your treatment plan, we aim to accelerate your progress and help you achieve greater mobility and independence.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForGaitAndBalance;