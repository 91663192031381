import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const GymWorkoutsForStressRelief = () => {
    return (
        <div>
            <Helmet>
                <title>Gym Workouts for Stress Relief | Ramp Physio and Fitness</title>
                <meta name="description" content="Discover effective gym workouts for stress relief and how they can improve your mental and physical well-being at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unwind and Energize</h1>
                    <h2>Explore our specialized gym workouts designed to alleviate stress and boost your mood, exclusively at Ramp Physio and Fitness.</h2>
                    <p>Stress is an inevitable part of life, but it doesn't have to control you. At Ramp Physio and Fitness, we understand the power of physical activity in combating stress. Our gym workouts are strategically designed to not only enhance your physical strength but also to provide a mental escape. This article dives into the best gym workouts for stress relief and how they can be a transformative part of your daily routine.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Gym Workouts for Stress Relief" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Stress-Busting Power</h1>
                        <p>Physical exercise is a scientifically proven stress reliever. It increases endorphin production—your brain's feel-good neurotransmitters—and acts as a natural antidote to stress. Whether it's a high-intensity interval training session or a flexibility class, our gym provides a diverse range of workouts to help you find your stress relief sweet spot.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Hit Away Your Stress</h1>
                    <p>Boxing is not just for building muscle; it's an excellent way to release tension. The rhythmic motion of punching a bag allows for a cathartic release, making it a popular choice among our members for stress relief. Discover how incorporating boxing into your workout regime can lead to significant improvements in your stress levels.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strength Training Serenity</h1>
                    <p>Strength training is an excellent way to manage stress, as it requires focus and concentration, diverting your mind from daily worries. The act of lifting weights can be incredibly grounding, providing a sense of accomplishment and improved self-esteem. Learn about the stress-relieving benefits of resistance exercises and how they can help you achieve a calmer state of mind.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Cardiovascular Clarity</h1>
                    <p>Cardio workouts like running, cycling, or swimming are not only great for your heart health but also for clearing your mind. The repetitive motion allows for a meditative experience, helping to reduce anxiety and stress. At Ramp Physio and Fitness, we guide you through tailored cardio sessions that leave you feeling refreshed and de-stressed.</p>
                    <br></br>
                    <ul>
                        <li>Running for a Clear Mind</li>
                        <li>Cycling to Reduce Anxiety</li>
                        <li>Swimming for Total Relaxation</li>
                        <li>Rowing for Rhythmic Stress Relief</li>
                        <li>Elliptical for Low-Impact Unwinding</li>
                    </ul>
                    <p>
                        Incorporating these workouts into your routine can transform your approach to stress management, helping you lead a healthier and more balanced life. Join us at Ramp Physio and Fitness to embark on your journey to stress relief through our expertly crafted gym workouts.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default GymWorkoutsForStressRelief;