import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import massageLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForRemedialMassage = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Maximizing Recovery: Physiotherapy and Remedial Massage</h1>
                    <h2>Explore the synergy between physiotherapy treatment and remedial massage exercises for optimal recovery at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, located in Homebush, NSW, we specialize in combining physiotherapy treatment and remedial massage exercises to offer a holistic approach to healing and rehabilitation. Our expert team of physiotherapists and massage therapists work in tandem to create personalized treatment plans that address your specific needs, accelerate recovery, and promote overall wellness. In this comprehensive guide, we delve into how these two modalities can work together to enhance your health and fitness journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Remedial Massage</h1>
                        <p>Remedial massage is a targeted form of massage therapy that aims to treat muscles that are damaged, knotted, tense, or immobile. It is used to locate and repair damaged areas of the body and speed up the body's own healing processes. The pressure applied during a remedial massage can be both gentle and deep, depending on the issue at hand.</p>

                        <p>Key benefits of remedial massage include the stimulation of blood supply to remove toxins in the muscles, calming of the peripheral nervous system to ease pain and discomfort, and the toning and relaxing of muscles to improve joint mobility. Combining these techniques with physiotherapy exercises can lead to a more comprehensive treatment plan.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Massage" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/dumbbells.webp" alt="Physiotherapy" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrating Physiotherapy</h1>
                    <p>Physiotherapy is a healthcare profession dedicated to restoring movement and function when someone is affected by injury, illness, or disability. It adopts a science-based approach and takes a 'whole person' perspective to health and well-being, which includes the patient's general lifestyle.</p>

                    <p>At the core of this approach is the patient's involvement in their own care, through education, awareness, empowerment, and participation in their treatment. Physiotherapy treatments may involve therapeutic exercises, manual therapy, and various modalities such as ultrasound or electrical stimulation, all of which can be enhanced by the addition of remedial massage.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={massageLogo} alt="Massage Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Benefits of a Combined Approach</h1>
                    <p>When physiotherapy and remedial massage are used in conjunction, they can offer a multitude of benefits. Here are ten examples:</p>
                    <p>1. Enhanced circulation and lymphatic drainage</p>
                    <p>2. Reduced muscle tension and stiffness</p>
                    <p>3. Improved joint mobility and flexibility</p>
                    <p>4. Faster recovery from injuries</p>
                    <p>5. Decreased pain and inflammation</p>
                    <p>6. Strengthened immune system response</p>
                    <p>7. Improvement in posture and overall body alignment</p>
                    <p>8. Reduction in physical and emotional stress</p>
                    <p>9. Increased body awareness and prevention of future injuries</p>
                    <p>10. Support for chronic pain management</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Gym Floor" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Physio Equipment" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Creating Your Personalized Plan</h1>
                        <p>At Ramp Physio and Fitness, we believe in a personalized approach to treatment. Our physiotherapists and massage therapists collaborate to develop a plan that is tailored to your unique needs. This may involve a combination of hands-on treatment, exercises, and education to help you understand your condition and manage your health.</p>
                        <p>Each treatment plan is designed to not only target the immediate issues but also to equip you with strategies for long-term maintenance and injury prevention. Our aim is to empower you to take an active role in your recovery and to support your health and fitness goals.</p>

                        <br></br>
                        <p>
                            In conclusion, by integrating physiotherapy treatment with remedial massage exercises, Ramp Physio and Fitness offers a comprehensive approach to recovery and wellness. Our dedicated team is committed to providing you with the highest quality of care, helping you to achieve optimal health and get back to doing what you love. Book your appointment today and take the first step towards a healthier you.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForRemedialMassage;