import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TopExercisesForStrengtheningYourCore = () => {
    return (
        <div>
            <Helmet>
                <title>Top Exercises for Strengthening Your Core - Ramp Physio and Fitness</title>
                <meta name="description" content="Discover the best core-strengthening exercises to enhance your fitness routine and improve your health at Ramp Physio and Fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Strengthen Your Foundation</h1>
                    <h2>Unlock the power of a strong core with our top exercises designed for all fitness levels at Ramp Physio and Fitness, Homebush, NSW.</h2>
                    <p>Building a strong core is essential for both athletic performance and everyday activities. At Ramp Physio and Fitness, we understand the importance of core strength and stability, which is why we've curated a list of the top exercises to help you fortify your midsection. In this article, we'll explore effective core-strengthening workouts that cater to a range of abilities and fitness goals.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Core Strengthening" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Core of Fitness Success</h1>
                        <p>A robust core goes beyond just having toned abs; it's the epicenter of your body's strength. Core muscles are involved in nearly every movement you make and play a pivotal role in balance and stability. By incorporating these top exercises into your routine, you can improve posture, reduce the risk of injuries, and enhance overall athletic performance.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Core Exercises for Everyone</h1>
                    <p>Whether you're a fitness novice or a seasoned athlete, our list of core exercises is designed to challenge and strengthen your midsection. From planks to Pilates, each exercise targets different aspects of core strength, ensuring a well-rounded workout.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Top 10 Core-Strengthening Exercises</h1>
                    <ul>
                        <li>Planks for Core Stability</li>
                        <li>Crunch Variations for Abdominal Muscles</li>
                        <li>Dead Bug Exercise for Coordination</li>
                        <li>Leg Raises for Lower Abs</li>
                        <li>Bird Dog for Balance and Strength</li>
                        <li>Bridge for Glutes and Core</li>
                        <li>Medicine Ball Twists for Obliques</li>
                        <li>Pilates Scissors for Core Control</li>
                        <li>Swiss Ball Rollouts for Core Activation</li>
                        <li>Mountain Climbers for Dynamic Core</li>
                    </ul>
                    <p>Each of these exercises can be modified to suit your fitness level and are an integral part of a comprehensive core-strengthening program. Incorporating these movements into your workouts will not only help sculpt your midsection but also improve functional strength for daily activities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Integrate Core Training in Your Routine</h1>
                    <p>At Ramp Physio and Fitness, we encourage you to integrate core exercises into your regular training routine. Not only do they enhance your strength and fitness, but they also complement other forms of exercise and physiotherapy. Our team of experts is ready to guide you through each movement, ensuring proper form and maximum benefit.</p>
                    <br></br>
                    <p>
                        In conclusion, strengthening your core is a crucial aspect of a well-rounded fitness regimen. With our top exercises for core strengthening, you can build a solid foundation that supports all other physical activities. Join us at Ramp Physio and Fitness to start your journey towards a stronger, more resilient core and reach your health and fitness goals with confidence.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TopExercisesForStrengtheningYourCore;