import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/Ramp_cover.webp';
import otherinfoimg from '../assets/RAMP Physio Icon.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const FAQ = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br /><br />
                    <h1>Frequently Asked Questions (FAQ)</h1>
                    <h2>What services does Ramp Physio and Fitness offer?</h2>
                    <p>Ramp Physio and Fitness provides physiotherapy, personal training, and rehabilitation programs. Our integrated approach ensures collaboration between physiotherapists and personal trainers for optimal health and fitness outcomes.</p><br />
                    <h2>Do I need to be a physio patient to use the Ramp gym?</h2>
                    <p>No, having physiotherapy treatment is not a requirement to use the Ramp gym, our physiotherapy patients benefit from our great gym facility, but you can use the gym as a regular user without physiotherapy treatment, it is open to everyone!</p><br />

                    <p>Sign up with your email address to receive news and updates.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">

                        <h1>What is included in the personal training packages?</h1>
                        <p>Our personal training packages include:
                            <ul>
                                <li><strong>Physiotherapy Assessment:</strong> Initial assessment to understand physical condition and needs.</li>
                                <li><strong>Customized Workout Programs:</strong> Personalized workout plans based on the assessment.</li>
                                <li><strong>Ongoing Physiotherapy Support:</strong> Continuous support and adjustments to ensure progress.</li>
                                <li><strong>Holistic Approach:</strong> Focus on overall well-being and physical strength.</li>
                            </ul></p><br /><br />

                        <h1>Do I need a doctor's referral before starting physiotherapy?</h1>
                        <p>No, a doctor's referral is not required for private patients. However, if you are claiming under WorkCover, CTP, Veterans Affairs, or the Medicare Chronic Disease Management Plan, a doctor's referral is necessary.</p><br /><br />

                        <h1>Can I use my private health insurance?</h1>
                        <p>Yes, Ramp Physio and Fitness is registered with most private health insurance plans, allowing you to claim your rebate at the clinic using HICAPS.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/dumbbells.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">

                    <h1>How many physiotherapy sessions will I need?</h1>
                    <p>The number of sessions depends on the individual complaint. Typically, 3-6 sessions over a 3-6 week period are suitable to address most conditions seen in the clinic.</p><br /><br />

                    <h1>What is the cost of physiotherapy at Ramp Physio and Fitness?</h1>
                    <p>For detailed pricing information, please visit our website or contact us directly. If you have private health insurance, you can claim a rebate on the spot using our HICAPS machine.</p><br /><br />

                    <h1>How long does each session take?</h1>
                    <p>Your initial consultation, which includes a comprehensive evaluation and treatment, takes 45 minutes. Subsequent sessions are typically 30 minutes.</p>
                </div>
            </div>

            <div className="service-intro-container4">
                <div className="service-intro-content4">

                    <h1>What can I expect on my first appointment?</h1>
                    <p>A thorough assessment will be undertaken, involving questions about your problem and a full examination of the area in question. The physiotherapist will provide a diagnosis, discuss a treatment plan and prognosis, and usually start treatment during the first session.</p><br /><br />

                    <h1>How many treatments will I need?</h1>
                    <p>This depends on various factors. Following your assessment, the physiotherapist will discuss the anticipated number of treatments. Progress will be reassessed at each visit, and the treatment plan will be adjusted as necessary.</p><br /><br />

                    <h1>What makes Ramp Physio and Fitness unique?</h1>
                    <p>Our unique service model integrates physiotherapy and personal training, ensuring collaborative and personalized care for all clients. We are dedicated to helping you achieve your health and fitness goals through expert guidance and tailored programs.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Workouts.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={otherinfoimg} alt="RAMP" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>


                </div>
            </SkewedContainer>
        </div>

    );
};

export default FAQ;