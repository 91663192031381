import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyImprovingMoodAndEnergyLevels = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Enhancing Well-Being with NDIS Physiotherapy</h1>
                    <h2>Discover how NDIS physiotherapy at Ramp Physio and Fitness can improve mood and energy levels, leading to a healthier, more vibrant life.</h2>
                    <p>Individuals living with disabilities often face unique challenges that can affect their mood and energy levels. At Ramp Physio and Fitness, our NDIS physiotherapy services are designed to empower participants with tailored exercise programs and support to cultivate a positive mindset and boost vitality. Our expert team works closely with NDIS participants to create personalized treatment plans that foster independence, improve daily living, and enhance overall well-being.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Empowerment Through Movement</h1>
                        <p>Physical activity is a powerful tool for enhancing mood and energy. Our NDIS physiotherapy services incorporate exercises that not only address physical health but also contribute to emotional well-being. Regular movement can release endorphins, known as 'feel-good' hormones, which naturally elevate mood and energy levels.</p>

                        <p>Our programs include strength training, cardiovascular exercises, and functional movements that are adapted to meet the specific needs of each participant. By focusing on achievable goals and celebrating progress, we help foster a sense of accomplishment and self-efficacy, which are key to improving mental health.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Support for Enhanced Daily Living</h1>
                    <p>Our NDIS physiotherapy services go beyond exercise; we aim to enhance the daily living of participants. We understand that independence in everyday activities is crucial for self-esteem and mental health. Our physiotherapists work on improving mobility, balance, and coordination, which are essential for performing daily tasks with ease and confidence.</p>

                    <p>By improving functional ability, we help reduce the frustration and stress that can come from physical limitations. Our holistic approach ensures that each individual's lifestyle and preferences are considered, leading to more meaningful and sustainable improvements in mood and energy.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Positive Mental Health Outcomes</h1>
                    <p>NDIS physiotherapy can significantly impact mental health, providing a structured outlet for stress relief and promoting relaxation. Our evidence-based interventions include breathing exercises and mindfulness techniques that complement physical therapy to reduce anxiety and enhance mood.</p>

                    <p>Participants are guided through strategies that promote mental resilience and coping skills, essential for managing the ups and downs of life. By integrating mental health support into our physiotherapy services, we provide a comprehensive approach to wellness.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Energy Boosting Exercise Programs</h1>
                    <p>Our NDIS physiotherapy services are tailored to help participants overcome fatigue and increase energy levels. We design exercise programs that are not only effective but also enjoyable, encouraging regular participation and long-term engagement.</p>
                    <p>By focusing on exercises that increase cardiovascular health, build muscle strength, and enhance endurance, our participants experience improved stamina and vitality, which positively affects all aspects of life.</p>

                    <br></br>
                    <p>
                        In summary, NDIS physiotherapy at Ramp Physio and Fitness is a holistic service that addresses both the physical and mental health needs of participants. Our team is committed to providing compassionate, individualized care that empowers individuals to lead happier, healthier lives. Contact us today to start your journey towards improved mood and energy levels.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyImprovingMoodAndEnergyLevels;