import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Physiotherapyforgolferselbow = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Conquer Golfer's Elbow with Expert Physiotherapy</h1>
                    <h2>Unlock the path to relief and recovery from Golfer's elbow with tailored physiotherapy treatments at Ramp Physio and Fitness, your trusted partner in overcoming medial epicondylitis.</h2>
                    <p>Struggling with Golfer's elbow can be a major hindrance to your active lifestyle and golfing passion. At Ramp Physio and Fitness, our specialized physiotherapy approaches are designed to alleviate pain, restore function, and prevent recurrence. In this comprehensive guide, you will discover the benefits of physiotherapy for Golfer's elbow and how our expert team can help you return to the fairways with confidence and comfort.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Golfer's Elbow</h1>
                        <p>Golfer's elbow, or medial epicondylitis, is caused by overuse of the muscles in the forearm that allow you to grip, rotate your arm, and flex your wrist. Repetitive flexing, gripping, or swinging can cause pulls or tiny tears in the tendons. Our physiotherapy services focus on reducing pain, increasing range of motion, and strengthening the muscles to support recovery and prevent future injuries.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Every case of Golfer's elbow is unique, which is why we create personalized treatment plans tailored to your symptoms and goals. Our plans may include manual therapy, therapeutic exercises, ultrasound, laser therapy, and advice on activity modification to ensure a safe and speedy return to your sport or daily activities.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercises for Recovery</h1>
                    <p>Exercise is a crucial part of recovering from Golfer's elbow. We guide you through specific exercises designed to stretch and strengthen the forearm muscles. These may include wrist flexor stretches, wrist extensor stretches, and grip strengthening exercises. Here are ten examples:</p>
                    <ul>
                        <li>Wrist Flexor Stretch</li>
                        <li>Wrist Extensor Stretch</li>
                        <li>Forearm Pronation/Supination</li>
                        <li>Elbow Flexion/Extension</li>
                        <li>Towel Twist</li>
                        <li>Finger Stretch</li>
                        <li>Ball Squeeze</li>
                        <li>Wrist Curls</li>
                        <li>Reverse Wrist Curls</li>
                        <li>Dynamic Wrist Movements with Resistance</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Therapeutic Techniques</h1>
                    <p>In addition to exercises, we utilize advanced therapeutic techniques like shockwave therapy, dry needling, and taping to facilitate healing and provide pain relief. These methods can enhance tissue repair, reduce muscle tension, and improve circulation to the affected area.</p>
                    <br></br>
                    <p>
                        With a comprehensive approach to treating Golfer's elbow, Ramp Physio and Fitness is dedicated to helping you achieve a full recovery and return to your peak performance. Don't let pain hold you back—our team is ready to support your journey to a pain-free and active lifestyle.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Physiotherapyforgolferselbow;