import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const VestibularRehabilitationForDizzinessAndVertigo = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Balance with Vestibular Rehabilitation</h1>
                    <h2>Explore how Vestibular Rehabilitation at Ramp Physio and Fitness can alleviate symptoms of dizziness and vertigo, aiding in restoring your balance and enhancing your quality of life.</h2>
                    <p>Vestibular disorders can be disorienting and debilitating, affecting every aspect of daily life. At Ramp Physio and Fitness, we understand the complexities of these conditions and offer specialized vestibular rehabilitation to help you overcome the challenges of dizziness and vertigo. Our expert physiotherapists use evidence-based exercises and personalized treatment plans to address your specific needs. In this comprehensive guide, we will explore the principles of vestibular rehabilitation, the exercises involved, and how our approach can lead you back to stability and confidence.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Vestibular Disorders</h1>
                        <p>Vestibular disorders arise when the vestibular system, which includes parts of the inner ear and brain that help control balance and eye movements, is damaged. Symptoms can range from mild dizziness to severe vertigo, balance issues, and other disturbances. Vestibular rehabilitation therapy (VRT) is a specialized form of therapy designed to alleviate both the primary and secondary problems caused by vestibular disorders. It employs a range of exercises that aim to reduce dizziness, improve balance, and enhance overall stability.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Effective Exercises for Symptom Relief</h1>
                    <ul>
                        <li>Gaze stabilization exercises</li>
                        <li>Balance retraining exercises</li>
                        <li>Habituation exercises</li>
                        <li>Walking exercises</li>
                        <li>Posture training</li>
                        <li>Functional retraining</li>
                        <li>Strength and flexibility exercises</li>
                        <li>Neck mobility exercises</li>
                        <li>Coordination and agility drills</li>
                        <li>Education on managing symptoms</li>
                    </ul>
                    <p>Our physiotherapists tailor these exercises to meet your unique symptoms and challenges, ensuring that each session brings you closer to regaining your balance and confidence.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personalized Vestibular Rehabilitation</h1>
                    <p>At Ramp Physio and Fitness, we believe that a personalized approach to vestibular rehabilitation is key to successful outcomes. During your initial assessment, our physiotherapists will conduct a thorough evaluation of your vestibular system to identify specific problem areas. This allows us to create a customized rehabilitation program that is focused on your individual needs and goals. With our support, you can expect to see improvements in your symptoms and a gradual return to your normal activities.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Comprehensive Support and Guidance</h1>
                    <p>Our commitment to your recovery extends beyond the therapy sessions. We provide comprehensive support and guidance to help you manage your symptoms at home and in your daily life. This includes education on vestibular disorders, tips for avoiding symptom triggers, and strategies to cope with the psychological impacts of dizziness and vertigo. Our team is dedicated to empowering you with the knowledge and skills you need to take control of your vestibular health.</p>
                    <br></br>
                    <p>
                        In conclusion, vestibular rehabilitation at Ramp Physio and Fitness is a transformative journey that can lead you from disorientation to stability. Our expert team is ready to guide you through each step of the process, providing personalized exercises, compassionate care, and a supportive environment. Don't let dizziness and vertigo control your life—take the first step towards balance and well-being with us. Book your appointment today and experience the difference that professional vestibular rehabilitation can make.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default VestibularRehabilitationForDizzinessAndVertigo;