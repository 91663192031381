import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const PreAndpostOperativephysiotherapy = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Optimizing Surgical Outcomes</h1>
                    <h2>Pre- and Post-Operative Physiotherapy: Enhancing Recovery at Ramp Physio and Fitness</h2>
                    <p>Undergoing surgery can be a daunting experience, but with Ramp Physio and Fitness, you can face it with confidence. Our specialized pre- and post-operative physiotherapy programs are designed to prepare your body for surgery and facilitate a swift, effective recovery. In this comprehensive guide, we'll explore the critical role physiotherapy plays before and after surgery, and how it can significantly improve your rehabilitation journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Your Consultation
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Pre-Surgery Physiotherapy</h1>
                        <p>Engaging in physiotherapy before your operation—known as prehabilitation—can significantly enhance your surgical outcomes. By strengthening muscles, improving flexibility, and increasing endurance, our physiotherapists tailor a program to your specific needs, ensuring your body is in the best possible condition to undergo surgery and recover effectively.</p>
                        <ul>
                            <li>Reduced post-operative pain</li>
                            <li>Faster recovery times</li>
                            <li>Improved surgical outcomes</li>
                            <li>Better overall physical fitness</li>
                            <li>Enhanced mental preparedness</li>
                            <li>Decreased risk of complications</li>
                            <li>Shortened hospital stays</li>
                            <li>Increased joint mobility</li>
                            <li>Stabilized pre-surgery condition</li>
                            <li>Optimized post-surgery rehabilitation</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Post-Surgery Rehabilitation</h1>
                    <p>After surgery, physiotherapy is crucial for regaining strength, mobility, and function. Our post-operative rehabilitation programs are customized to support your specific surgical procedure and personal health goals. We focus on reducing pain, swelling, and inflammation while promoting healing and restoring function so you can return to your daily activities with ease.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Start Your Recovery
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personalized Care Plans</h1>
                    <p>At Ramp Physio and Fitness, we understand that every individual's needs are different. That's why we create personalized care plans that are as unique as you are. Our experienced physiotherapists work with you to set realistic goals, measure progress, and adjust treatments as necessary to ensure you're on the fastest path to recovery.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Advanced Rehabilitation Techniques</h1>
                    <p>We utilize the latest evidence-based rehabilitation techniques and state-of-the-art equipment to aid in your recovery. Our approach includes manual therapy, exercise prescription, pain management strategies, and education to help you understand your body's healing process and reduce the likelihood of future injuries.</p>
                    <br></br>
                    <p>
                        In conclusion, pre- and post-operative physiotherapy are essential components of a successful recovery. At Ramp Physio and Fitness, we are dedicated to providing the highest quality care to ensure you can achieve your health and fitness goals. Contact us today to learn more about how our expert physiotherapy services can support your pre- and post-surgery needs.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PreAndpostOperativephysiotherapy;