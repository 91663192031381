import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const TrainingProgramsForStrengtheningTheImmuneSystem = () => {
    return (
        <div>
            <Helmet>
                <title>Boost Immunity with Strength Training - Ramp Physio and Fitness</title>
                <meta name="description" content="Discover how strength training programs at Ramp Physio and Fitness can help enhance your immune system for better health and fitness." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Strengthen Your Body and Immunity</h1>
                    <h2>Explore the synergy of strength training and immune health with tailored programs at Ramp Physio and Fitness.</h2>
                    <p>Understanding the link between physical activity and immune function is key to maintaining optimal health. At Ramp Physio and Fitness, we've designed training programs that not only build strength but also support and enhance your immune system. In this comprehensive guide, we'll introduce the ways in which strength training can contribute to a robust immune function, drawing from scientific research and expert insights.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Join Our Programs
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Unlocking Immune Potential</h1>
                        <p>Our training programs are grounded in evidence-based practices that highlight the immune-boosting benefits of regular, moderate resistance training. By engaging in our structured workouts, you can help your body fight off pathogens, reduce inflammation, and improve your overall health. Here are 10 key ways our strength training programs can bolster your immune system:</p>
                        <ul>
                            <li>Enhancing antibody response</li>
                            <li>Increasing the circulation of immune cells</li>
                            <li>Reducing stress and inflammation</li>
                            <li>Improving sleep quality</li>
                            <li>Boosting metabolic health</li>
                            <li>Enhancing gut microbiome diversity</li>
                            <li>Reducing the risk of chronic diseases</li>
                            <li>Supporting weight management</li>
                            <li>Improving mental health</li>
                            <li>Enhancing recovery and resilience</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Science-Backed Training Strategies</h1>
                    <p>Each component of our training programs is backed by scientific research, ensuring that you receive the most effective and safe workout to support your immunity. Our programs incorporate a variety of resistance exercises, cardiovascular activities, and flexibility routines, all of which play a crucial role in maintaining a healthy immune system. We align our strategies with the latest findings from the fields of exercise immunology and sports medicine to deliver a holistic approach to your fitness and health.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Get Started Today
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Customized Immune Support Plans</h1>
                    <p>Recognizing that each individual's health and fitness journey is unique, we offer customized training plans tailored to meet your specific needs and goals. Whether you are new to exercise or an experienced athlete, our team of expert physiotherapists and personal trainers will work with you to create a program that not only strengthens your muscles but also supports your immune health.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Group Sessions for Motivation</h1>
                        <p>Joining a community that shares your health and fitness goals can be a powerful motivator. Our group training sessions not only provide social support but also create an environment where you can thrive and push your limits in a safe and encouraging setting. Group workouts can also enhance accountability and consistency, which are key factors in maintaining a strong immune system through regular exercise.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default TrainingProgramsForStrengtheningTheImmuneSystem;