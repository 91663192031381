import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Preventingsportsinjuries = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Preventing Sports Injuries</h1>
                    <h2>Stay in the Game: Proactive Strategies for Injury Prevention</h2>
                    <p>Sports injuries can be a major setback for athletes and fitness enthusiasts alike. At Ramp Physio and Fitness, our focus is not just on treatment, but also on prevention. In this comprehensive guide, our physiotherapy experts share their top tips for staying injury-free, so you can continue to enjoy your sports and fitness activities safely and effectively.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Injury Mechanics</h1>
                        <p>Before we can prevent injuries, we need to understand how they occur. Sports injuries typically result from overuse, direct impact, or the application of force that is greater than the body part can structurally withstand. Here are some key concepts in injury mechanics:</p>
                        <ul>
                            <li>Overuse injuries occur due to repetitive trauma.</li>
                            <li>Acute injuries result from sudden trauma.</li>
                            <li>Chronic injuries arise from overuse over a long period.</li>
                        </ul>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Warm-Up and Cool-Down</h1>
                    <p>Proper warm-up and cool-down routines are fundamental in preparing the body for exercise and reducing the risk of injury. Here are key points to consider:</p>
                    <ul>
                        <li>Warm-ups should be dynamic, increasing blood flow to muscles.</li>
                        <li>Cool-downs should include static stretching to decrease muscle stiffness.</li>
                        <li>Incorporate sport-specific exercises into your warm-up.</li>
                    </ul>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strengthening and Conditioning</h1>
                    <p>Building strength and conditioning is not just about enhancing performance, but also about preventing injuries. Here's how targeted exercises can help:</p>
                    <ul>
                        <li>Strength training improves muscle and joint stability.</li>
                        <li>Core stability exercises are crucial for balance and injury prevention.</li>
                        <li>Conditioning programs should be tailored to your sport or activity.</li>
                    </ul>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Rest and Recovery</h1>
                    <p>Adequate rest and recovery are just as important as training itself. Here's why:</p>
                    <ul>
                        <li>Rest days prevent overuse injuries.</li>
                        <li>Recovery techniques such as massage and hydrotherapy can aid in muscle repair.</li>
                        <li>Adequate sleep is essential for the body's healing processes.</li>
                    </ul>
                    <br></br>
                    <p>
                        Preventing sports injuries is a multifaceted approach that includes understanding the mechanics of injuries, proper warm-up and cool-down routines, strength and conditioning, and allowing for adequate rest and recovery. At Ramp Physio and Fitness, our expert team is here to guide you through each step, ensuring you stay injury-free and perform at your best. Book your session today and take the first step towards a safer and more effective fitness journey.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Preventingsportsinjuries;