import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './SubpagesLink';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForDepression = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Depression | Ramp Physio and Fitness</title>
                <meta name="description" content="Explore the benefits of NDIS-funded physiotherapy for managing depression at Ramp Physio and Fitness. Our tailored programs promote physical and mental well-being." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowerment Through Movement: NDIS Physiotherapy for Depression</h1>
                    <h2>Discover the transformative power of NDIS physiotherapy for depression, offering a holistic approach to mental health at Ramp Physio and Fitness.</h2>
                    <p>Depression can profoundly affect one's quality of life, impacting both mental and physical health. At Ramp Physio and Fitness, we recognize the therapeutic potential of physiotherapy in the management of depression, especially for those supported by the National Disability Insurance Scheme (NDIS). Our tailored programs are designed to empower individuals by enhancing their physical ability, which in turn can improve mood and overall well-being. This article delves into the intersection of physiotherapy and mental health, highlighting the benefits of our services for those living with depression.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Depression and Physiotherapy</h1>
                        <p>Depression is a multifaceted condition that can manifest through emotional, cognitive, and physical symptoms. The role of physiotherapy in managing depression is to provide structured physical activity tailored to individual needs, which can stimulate the release of endorphins, improve sleep patterns, and foster a sense of achievement.</p>

                        <p>Our team at Ramp Physio and Fitness begins with a comprehensive assessment to understand the unique challenges each individual faces. We then develop an NDIS physiotherapy plan that not only aims to improve physical health but also considers the psychological aspects of depression.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/dumbbells.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Tailored Exercise and Activity Programs</h1>
                    <p>Exercise is a cornerstone of our NDIS physiotherapy programs for depression. Our physiotherapists craft individualized exercise plans that can range from gentle movement to more robust physical activity, depending on each person's capabilities and goals.</p>

                    <p>These personalized programs aim to improve cardiovascular health, increase muscle strength, and promote flexibility. By setting achievable milestones, we help individuals experience the satisfaction of progress which can be a powerful motivator in the journey through depression.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integration of Mind-Body Techniques</h1>
                    <p>Our approach to physiotherapy extends beyond physical exercises. We integrate mind-body techniques that focus on breath control, mindfulness, and relaxation. These strategies can help manage stress and anxiety, which are often associated with depression.</p>

                    <p>By teaching these techniques, we provide tools that individuals can use in their daily lives to cope with challenging emotions and situations, fostering resilience and self-empowerment.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Supportive Environment and Community</h1>
                        <p>At Ramp Physio and Fitness, we believe in the power of community support. Our services provide a welcoming and inclusive environment where individuals can feel supported and understood.</p>
                        <p>We encourage the formation of social connections, which can be especially beneficial for those dealing with depression. The sense of belonging and the supportive network we foster can contribute to improved mental health outcomes.</p>
                        <br></br>
                        <p>In conclusion, NDIS physiotherapy for depression at Ramp Physio and Fitness is a holistic service that addresses both the physical and emotional needs of our clients. Our dedicated team is committed to supporting you through your mental health journey, offering compassionate care and a personalized approach to help you regain control of your life.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForDepression;