import React from 'react';
import './Team.css';
// import NestorImage from '../assets/Nestor.png';
import ramis from '../assets/ramis.webp';
import nestor from '../assets/Nestor.webp';
import koeun from '../assets/Koeun.webp';
// import leo from '../assets/leo.png';
import alexg from '../assets/AlexG.webp';
import kurt from '../assets/Kurt.webp';
import mattp from '../assets/MattP.webp';
import cherlyne from '../assets/Cherlyne2.webp';
import kye from '../assets/Kye.webp';
// import generic_user_female from '../assets/User_Female.webp';
import generic_user_male from '../assets/User_Male.webp';

const Team = () => {
  return (
    <div className="contact-page">
      <div className="team-header">
        <h1>OUR TEAM</h1>
      </div>
      <div className="team-container">
          <div className="team-member">
          <img src={nestor} alt="Dr Nestor Chan" className="team-image" />
          <div className="team-info">
            <h2>DR NESTOR CHAN</h2>
            <p>PHYSIOTHERAPIST</p>
          </div>
        </div>
       {/* <div className="team-member">
          <img src={leo} alt="Leo Sun" className="team-image" />
          <div className="team-info">
            <h2>LEO SUN</h2>
            <p>PHYSIOTHERAPIST</p>
          </div>
        </div>*/}
        <div className="team-member">
          <img src={cherlyne} alt="Cherlyne Wang" className="team-image" />
          <div className="team-info">
            <h2>CHERLYNE WANG</h2>
            <p>PHYSIOTHERAPIST</p>
          </div>
        </div>
          <div className="team-member">
          <img src={kye} alt="Kye" className="team-image" />
          <div className="team-info">
            <h2>KYE JEON</h2>
            <p>PHYSIOTHERAPIST</p>
          </div>
        </div>
        <div className="team-member">
          <img src={ramis} alt="Ramis Amanoel" className="team-image" />
          <div className="team-info">
            <h2>RAMIS AMANOEL</h2>
            <p>PERSONAL TRAINER</p>
          </div>
        </div>
        <div className="team-member">
          <img src={kurt} alt="Kurt Aclan" className="team-image" />
          <div className="team-info">
            <h2>KURT ACLAN</h2>
            <p>PERSONAL TRAINER</p>
          </div>
        </div>
        <div className="team-member">
          <img src={alexg} alt="Alex Garossi" className="team-image" />
          <div className="team-info">
            <h2>ALEX GAROSSI</h2>
            <p>PERSONAL TRAINER</p>
          </div>
        </div>
          <div className="team-member">
          <img src={mattp} alt="Matt Papallo" className="team-image" />
          <div className="team-info">
            <h2>MATT PAPALLO</h2>
            <p>PERSONAL TRAINER</p>
          </div>
        </div>
         <div className="team-member">
          <img src={generic_user_male} alt="Physiotherapist Starting Soon" className="team-image" />
          <div className="team-info">
            <h2>Physiotherapist Starting Soon</h2>
            <p>PHYSIOTHERAPIST</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
